import React from "react";
import moment from "moment";
import { BsClock } from "react-icons/bs";
import "./style.scss";

class Timer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: "00:00:00",
    };
  }

  componentDidMount() {
    this.clearTimer(this.getDeadTime());
  }

  getDeadTime = () => {
    // let deadline = new Date("2022-07-17 15:00:00");
    var hours = new Date(this.props.timeLimit).getHours();
    var minutes = new Date(this.props.timeLimit).getMinutes();
    let deadline = new Date(
      `${moment(this.props.dateLimit).format("YYYY-MM-DD")} ${
        hours.toString().length > 1 ? hours : `0${hours}`
      }:${minutes.toString().length > 1 ? minutes : `0${minutes}`}:00`
    );
    deadline.setSeconds(deadline.getSeconds());
    return deadline;
  };

  getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor(total / 1000 / 60 / 60); //Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  startTimer = (e) => {
    let { total, hours, minutes, seconds } = this.getTimeRemaining(e);
    if (total >= 0) {
      this.setState({
        timer:
          (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds),
      });
    }
  };
  clearTimer = (e) => {
    setInterval(() => {
      this.startTimer(e);
    }, 1000);
  };

  render() {
    const { timer } = this.state;
    if (timer !== "00:00:00") {
      return (
        <span
          style={this.props.style}
          className={`${this.props.className} timer wood`}
        >
          <BsClock className="icon" />
          <p className="time">{timer}</p>
        </span>
      );
    }
    return null;
  }
}

export default Timer;

export const remainingTime = (dateLimit, timeLimit) => {
  // deadline <= todayTime
  // "2022-07-17 15:00:00"
  // if (dateLimit && timeLimit) {
  if (dateLimit && timeLimit) {
    var minutes = new Date(timeLimit).getMinutes(),
      hours = new Date(timeLimit).getHours();
    let deadline = new Date(
        `${moment(dateLimit).format("YYYY-MM-DD")} ${
          hours.toString().length > 1 ? hours : `0${hours}`
        }:${minutes.toString().length > 1 ? minutes : `0${minutes}`}:00`
      ),
      todayTime = new Date();
    if (deadline < todayTime) {
      return false;
    } else {
      return true;
    }
  }
  return false;
};
