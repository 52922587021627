// Grey
export const P2_0 = ({ fill }) => {
  return (
    <svg
      width="201"
      height="223"
      viewBox="0 0 201 223"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M185.16 0.169922L0.159973 174.07L35.24 222.02L200.98 89.8099L185.16 0.169922Z"
        fill="#878A8E"
      />
    </svg>
  );
};

export const P2_1 = ({ fill }) => {
  return (
    <svg
      width="171"
      height="149"
      viewBox="0 0 171 149"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.97998 76.2599C46.49 65.1799 64.72 16.03 106.42 0.949951C129.98 16.65 149.38 35.7699 170.16 55.1599L71.14 148.24L0.97998 76.2599Z"
        fill={fill}
      />
    </svg>
  );
};

// Grey
export const P2_2 = ({ fill }) => {
  return (
    <svg
      width="174"
      height="154"
      viewBox="0 0 174 154"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M102.98 0.26001L173.13 72.25L87.15 153.07L0.359985 34.44C15.96 25.25 31.6 14.59 49.5 10.6C67.22 6.64998 85.12 3.38001 102.98 0.26001Z"
        fill="#878A8E"
      />
    </svg>
  );
};

// Grey
export const P2_3 = ({ fill }) => {
  return (
    <svg
      width="327"
      height="381"
      viewBox="0 0 327 381"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.37 0.429932L326.48 375.11C289.18 375.11 252.7 377.28 215.49 380.02L0.23999 49.4899C15.26 28.1699 29.15 12.9899 52.37 0.429932Z"
        fill="#878A8E"
      />
    </svg>
  );
};

export const P2_4 = ({ fill }) => {
  return (
    <svg
      width="226"
      height="339"
      viewBox="0 0 226 339"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M89.21 121.76L10.24 0.48999C-2.41003 32.83 1.09996 66.64 1.09996 100.89L38.75 172.82L89.21 121.76Z"
        fill={fill}
      />
      <path
        d="M157.55 226.7L88.81 268.46L125.02 337.63C141.45 338.48 158.04 338.59 174.45 337.63C191.07 336.66 209.08 334.01 225.48 331.01L157.55 226.7Z"
        fill={fill}
      />
      <path
        d="M89.21 121.76L38.75 172.81L88.81 268.46L157.55 226.7L89.21 121.76Z"
        fill={fill}
      />
    </svg>
  );
};

export const P2_5 = ({ fill }) => {
  return (
    <svg
      width="163"
      height="238"
      viewBox="0 0 163 238"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.66 54.1401C25.68 38.1701 35.39 20.3601 38.1 0.890137L162.02 237.63H58.55L0.76001 67.5501C5.86001 63.5101 10.04 58.6801 14.66 54.1401Z"
        fill={fill}
      />
    </svg>
  );
};

export const P2_6 = ({ fill }) => {
  return (
    <svg
      width="126"
      height="171"
      viewBox="0 0 126 171"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M67.76 0.550049L125.55 170.63L9.32001 167.03L0.130005 26.8201C24.19 22.5701 51.58 22.16 67.76 0.550049Z"
        fill={fill}
      />
    </svg>
  );
};

export const P2_7 = ({ fill }) => {
  return (
    <svg
      width="119"
      height="143"
      viewBox="0 0 119 143"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M118.32 142.03L109.13 1.82C81.88 -1.94 61.56 0.700017 34.51 5.96002L0.390015 142.03H118.32Z"
        fill={fill}
      />
    </svg>
  );
};

export const P2_8 = ({ fill }) => {
  return (
    <svg
      width="167"
      height="156"
      viewBox="0 0 167 156"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M166.5 18.96L132.38 155.03H0.519989L104.57 0C120.76 22.34 139.78 38.57 166.5 18.96Z"
        fill={fill}
      />
    </svg>
  );
};

export const P2_9 = ({ fill }) => {
  return (
    <svg
      width="297"
      height="214"
      viewBox="0 0 297 214"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M296.57 57.9999L192.52 213.03H0.829956L250.23 0.699951L296.57 57.9999Z"
        fill={fill}
      />
    </svg>
  );
};

export const P2_10 = ({ fill }) => {
  return (
    <svg
      width="332"
      height="274"
      viewBox="0 0 332 274"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M331.23 60.7001L81.83 273.03H0.0999756L5.22997 192.06L289.78 0.890137L331.23 60.7001Z"
        fill={fill}
      />
    </svg>
  );
};

export const P2_11 = ({ fill }) => {
  return (
    <svg
      width="285"
      height="268"
      viewBox="0 0 285 268"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M259.78 0.159912L7.58998 138.39L0.23999 267.06L284.79 75.89C273.59 50.11 265.81 27.5799 259.78 0.159912Z"
        fill={fill}
      />
    </svg>
  );
};

export const P2_12 = ({ fill }) => {
  return (
    <svg
      width="253"
      height="206"
      viewBox="0 0 253 206"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.17996 105L223.8 0.140015C237.75 22.65 247.04 41.2 252.79 67.16L0.589966 205.39L5.17996 105Z"
        fill={fill}
      />
    </svg>
  );
};

export const P2_13 = ({ fill }) => {
  return (
    <svg
      width="219"
      height="169"
      viewBox="0 0 219 169"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.58 57.35L184.99 0.630005C189.43 29.83 201.48 42.03 218.79 64.14L0.179993 169L6.58 57.35Z"
        fill={fill}
      />
    </svg>
  );
};

export const P2_14 = ({ fill }) => {
  return (
    <svg
      width="208"
      height="149"
      viewBox="0 0 208 149"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.12001 0.810059L207.35 26.3C186.35 43.65 181.97 65.7901 178.99 91.6301L0.580017 148.35L8.12001 0.810059Z"
        fill={fill}
      />
    </svg>
  );
};

export const P2_15 = ({ fill }) => {
  return (
    <svg
      width="251"
      height="204"
      viewBox="0 0 251 204"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M76.22 0.119995L250.68 149.45C233.43 167.28 216.68 185.55 199.35 203.29L0.119995 177.8L4.07001 51.44L76.22 0.119995Z"
        fill={fill}
      />
    </svg>
  );
};

export const P2_16 = ({ fill }) => {
  return (
    <svg
      width="240"
      height="256"
      viewBox="0 0 240 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M140.22 0.22998L239.65 222.79C211.25 227.03 196.44 237.17 174.68 255.46L0.219971 106.12L140.22 0.22998Z"
        fill={fill}
      />
    </svg>
  );
};

export const P2_17 = ({ fill }) => {
  return (
    <svg
      width="350"
      height="263"
      viewBox="0 0 350 263"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M349.94 109.98L338.03 118.75L331.14 146.33L211.44 162.62L153.77 262.9C143.28 238.93 124.4 227.85 99.65 222.79L0.220001 0.22998L349.94 109.98Z"
        fill={fill}
      />
    </svg>
  );
};

export const P2_18 = ({ fill }) => {
  return (
    <svg
      width="190"
      height="191"
      viewBox="0 0 190 191"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.640015 190.22C2.88001 165.64 2.92002 141.52 3.77002 116.89L61.43 16.62L181.13 0.329956C174.69 25.29 185.09 54.37 189.24 79.55L0.640015 190.22Z"
        fill={fill}
      />
    </svg>
  );
};

export const P2_19 = ({ fill }) => {
  return (
    <svg
      width="207"
      height="179"
      viewBox="0 0 207 179"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M206.31 65.1L30.1 178.99C15.06 157.51 7.40001 136.25 0.640015 111.23L189.24 0.560059L206.31 65.1Z"
        fill={fill}
      />
    </svg>
  );
};

export const P2_20 = ({ fill }) => {
  return (
    <svg
      width="225"
      height="176"
      viewBox="0 0 225 176"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M215.26 16.79L224.53 67.24L39.79 175.94C28.97 153.13 15.36 133.98 0.0999756 113.99L176.31 0.0999756C181.31 11.65 183.65 21.42 185.96 33.86L192.11 37.2L198.57 36.83C205.8 30.58 209.99 24.81 215.26 16.79Z"
        fill={fill}
      />
    </svg>
  );
};

export const P2_21 = ({ fill }) => {
  return (
    <svg
      width="212"
      height="184"
      viewBox="0 0 212 184"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M211.34 73.27L0.159973 183.26L10.79 108.94L195.53 0.23999L211.34 73.27Z"
        fill={fill}
      />
    </svg>
  );
};

export const P2_22 = ({ fill }) => {
  return (
    <svg
      width="227"
      height="176"
      viewBox="0 0 227 176"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.299988 175.62L12.16 110.26L223.34 0.280029L226.82 13.52C184.09 37.36 154.18 70.7 149.55 121.11L0.299988 175.62Z"
        fill={fill}
      />
    </svg>
  );
};

export const P2_23 = ({ fill }) => {
  return (
    <svg
      width="150"
      height="133"
      viewBox="0 0 150 133"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33002 82.7699C18.2 109.28 31.28 122.74 60.31 132.75C89.9 127.82 122.06 115.82 139.65 89.8999C159.06 61.2999 142.21 30.4499 149.55 0.109863L0.299988 54.6199L3.33002 82.7699Z"
        fill={fill}
      />
    </svg>
  );
};

export const P2_24 = ({ fill }) => {
  return (
    <svg
      width="137"
      height="196"
      viewBox="0 0 137 196"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.34 0.369995L136.79 161.8C98.59 169.4 65.25 182.22 28.82 195.52L0.26001 58.8C10.92 39.6 18.77 21.32 25.34 0.369995Z"
        fill={fill}
      />
    </svg>
  );
};

export const P2_25 = ({ fill }) => {
  return (
    <svg
      width="168"
      height="226"
      viewBox="0 0 168 226"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.4 0.859985L167.9 180.81C149.27 200.1 135.26 213.13 111.8 225.8L0.340027 64.37C2.28003 49.02 1.83998 33.23 13.36 21.5L27.1 20.44L50.4 0.859985Z"
        fill={fill}
      />
    </svg>
  );
};

export const P2_26 = ({ fill }) => {
  return (
    <svg
      width="161"
      height="248"
      viewBox="0 0 161 248"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.5 0.640015L64.43 10.84C85.36 44.91 107.18 78.57 127.27 113.14C143.21 140.55 150.4 174.47 160.75 204.41L155.99 215.52L117.88 247.8L0.400024 67.86C10.23 43.53 21.62 20.98 38.5 0.640015Z"
        fill={fill}
      />
    </svg>
  );
};

export const P2_27 = ({ fill }) => {
  return (
    <svg
      width="126"
      height="211"
      viewBox="0 0 126 211"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M123.02 12.9299C116.9 45.5799 109.58 78.23 104.7 111.09C102.26 127.49 103.34 141.87 109.88 157.21C114.87 168.91 129.12 183.47 124.46 196.56C118.36 202.24 112.16 209.3 103.38 210.12C91.16 205.77 84.94 192.57 77.54 182.81C69.65 172.39 61.35 162.27 53.29 151.98C34.35 127.81 17.02 103.88 0.269989 78.1299L125.15 0.689941L123.02 12.9299Z"
        fill="#878A8E"
      />
    </svg>
  );
};

export const P2_28 = ({ fill }) => {
  return (
    <svg
      width="149"
      height="156"
      viewBox="0 0 149 156"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M146.61 79.62L23.26 155.12C12.54 133.11 6.94 110.57 0.470001 87.1L107.63 0.22998C117.25 15.49 126.96 30.69 136.49 46.01C142.53 55.72 150.63 65.61 148.14 77.67L146.61 79.62Z"
        fill={fill}
      />
    </svg>
  );
};

export const P2_29 = ({ fill }) => {
  return (
    <svg
      width="143"
      height="185"
      viewBox="0 0 143 185"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M142.64 97.23L35.48 184.1C31.49 152.49 19.13 130.34 0.299988 105.07C38.48 71.43 79.99 37.49 115.13 0.75C114.37 36.99 123.93 66.74 142.64 97.23Z"
        fill={fill}
      />
    </svg>
  );
};

export const P2_30 = ({ fill }) => {
  return (
    <svg
      width="149"
      height="173"
      viewBox="0 0 149 173"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.22 81.27C39.55 61.47 60.88 41.67 82.21 21.88C95.93 9.14996 109.98 -6.93004 130.45 3.96996C148.95 13.82 149.82 36.8299 146.51 55.1799C142.21 79.0199 119.09 91.55 101.69 106.82C76.89 128.57 52.1 150.32 27.3 172.07C14.22 157.53 2.66 146.45 0.760003 126.33C-1.06 107.06 6.44999 95.75 18.22 81.27Z"
        fill={fill}
      />
    </svg>
  );
};

export const River = () => {
  return (
    <svg
      width="471"
      height="822"
      viewBox="0 0 471 822"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 18.79C17.4 10.28 36.85 -1.86 54.53 0.619997C59.17 1.27 72.74 3.65002 76.26 6.64002C82.33 11.81 81.57 23.58 84.33 30.75C91.38 49.13 100.11 60.92 102.68 81.05C105.37 102.14 108.02 123.23 110.54 144.35C112.96 164.67 116.17 185.01 117.86 205.4C119.4 223.88 116.85 243.48 120.15 261.8C123.21 278.79 140.28 290.66 151.32 302.97C164.22 317.36 179.04 331.21 190.45 346.7C196.06 354.31 201.62 363.42 208.43 369.91C214.85 376.02 224.83 378.2 231.06 384.5C243.18 396.76 245.99 418.26 251.61 433.77C266.02 473.57 281.34 513.6 303.61 549.62C307.62 556.1 313.27 563.32 315.36 570.55C318.16 580.26 317.11 592.58 318.52 602.69C321.25 622.24 325.37 635.04 335.4 651.73C345.89 669.19 357.72 684.16 374.65 695.94C389.82 706.49 407.73 713.01 422.91 723.58C431.08 729.27 440.41 736.47 445.76 744.68C448.84 749.41 473.39 831.12 469.62 836.17C465.01 842.35 427.37 842.83 419.99 843.99C369.51 851.94 319.03 859.9 268.55 867.85C273.93 839.77 267.06 744.43 255.58 716.69C249.5 702 242.86 693 241.01 676.74C239.16 660.44 231.35 647.67 228.54 631.99C223.06 601.38 226.09 568.25 223.12 537.29C219.95 504.24 210.69 471.96 194.77 442.87C180.09 416.05 161.49 395.63 138.54 376.12C124.94 364.56 117.07 358.87 98.84 360.33C84.02 361.52 64.67 367.2 50.26 362.09C37.26 357.47 30.48 339.36 29.01 326.67C28.15 319.25 26.21 310.1 27.25 302.77C28.18 296.3 33.19 290.55 34.28 284.1C36.77 269.37 29.6 250.42 26.98 236.03C24.15 220.52 22.11 205.04 19.69 189.46C14.47 155.97 -12.07 136.34 -22.5 106.61C-29.42 86.89 -38.09 73.38 -31.84 53.48C-25.11 32.06 -18.96 25.94 2.5 18.79Z"
        fill="#76AED3"
      />
    </svg>
  );
};

export const BordersPhase2 = () => {
  return (
    <svg
      width="978"
      height="868"
      viewBox="0 0 978 868"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_34_68)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M597.914 111.598L254.992 3.98188L352.528 222.303C376.172 227.296 394.726 237.92 405.615 260.206L461.402 163.199C461.819 162.473 462.549 161.982 463.379 161.869L581.451 145.801L587.974 119.687C588.127 119.078 588.487 118.54 588.993 118.168L597.914 111.598ZM600.033 112.149C600.693 111.664 600.521 110.635 599.74 110.39L254.019 1.89463C253.197 1.63662 252.455 2.46988 252.806 3.25665L351.121 223.321C351.253 223.615 351.521 223.829 351.836 223.894C375.709 228.87 394.068 239.528 404.632 262.091C404.98 262.834 406.03 262.893 406.439 262.182L462.876 164.046C463.03 163.777 463.301 163.596 463.608 163.554L582.15 147.421C582.555 147.366 582.886 147.07 582.985 146.673L589.624 120.099C589.68 119.874 589.813 119.674 590.001 119.536L600.033 112.149Z"
          fill="white"
        />
        <path
          d="M9.91093 773.83C9.78093 773.83 9.64093 773.8 9.52093 773.73C9.26093 773.58 9.11093 773.3 9.13093 773.01L16.4809 644.34C16.5009 644.07 16.6509 643.83 16.8809 643.71L269.071 505.48C269.291 505.36 269.541 505.36 269.771 505.45C269.991 505.55 270.151 505.75 270.211 505.99C275.871 531.7 283.331 554.31 295.161 581.58C295.311 581.92 295.191 582.33 294.881 582.53L10.3509 773.7C10.2209 773.79 10.0709 773.83 9.91093 773.83ZM18.0209 644.86L10.7809 771.54L293.491 581.61C281.921 554.87 274.541 532.56 268.931 507.35L18.0209 644.86Z"
          fill="white"
        />
        <path
          d="M86.5109 854.81H4.78094C4.57094 854.81 4.36093 854.72 4.21093 854.56C4.06093 854.41 3.99094 854.19 4.00094 853.98L9.14094 773.01C9.16094 772.77 9.28093 772.55 9.48093 772.41L294.031 581.24C294.381 581.01 294.861 581.1 295.101 581.44L336.551 641.25C336.781 641.58 336.721 642.03 336.411 642.29L87.0109 854.62C86.8709 854.74 86.6909 854.81 86.5109 854.81ZM5.61092 853.25H86.2309L334.881 641.56L294.271 582.96L10.6809 773.49L5.61092 853.25Z"
          fill="white"
        />
        <path
          d="M278.201 854.81H86.5108C86.1808 854.81 85.8909 854.61 85.7809 854.3C85.6709 854 85.7608 853.65 86.0108 853.44L335.411 641.11C335.571 640.97 335.791 640.9 335.991 640.93C336.201 640.95 336.391 641.05 336.521 641.22L382.861 698.52C383.071 698.78 383.091 699.16 382.901 699.44L278.841 854.47C278.701 854.68 278.451 854.81 278.201 854.81ZM88.6209 853.25H277.781L381.291 699.04L335.811 642.81L88.6209 853.25Z"
          fill="white"
        />
        <path
          d="M410.061 854.81H278.201C277.911 854.81 277.651 854.65 277.511 854.4C277.371 854.15 277.391 853.84 277.551 853.6L381.611 698.57C381.751 698.36 381.991 698.23 382.241 698.23C382.511 698.21 382.741 698.35 382.881 698.55C395.181 715.54 405.661 723.9 416.841 725.65C425.471 727.01 434.261 724.28 443.721 717.34C443.991 717.15 444.351 717.14 444.611 717.32C444.881 717.5 445.011 717.84 444.931 718.15L410.811 854.22C410.731 854.57 410.421 854.81 410.061 854.81ZM279.651 853.25H409.451L442.921 719.81C433.751 726.1 425.131 728.51 416.611 727.18C402.091 724.9 390.691 711.83 382.291 700.35L279.651 853.25Z"
          fill="white"
        />
        <path
          d="M528.001 854.81H410.061C409.821 854.81 409.591 854.7 409.451 854.51C409.301 854.32 409.251 854.08 409.311 853.84L443.441 717.77C443.511 717.48 443.751 717.25 444.041 717.2C472.911 711.58 492.421 709.4 518.921 713.05C519.281 713.1 519.571 713.4 519.591 713.77L528.781 853.98C528.801 854.2 528.721 854.41 528.571 854.56C528.421 854.72 528.211 854.81 528.001 854.81ZM411.061 853.25H527.171L518.071 714.5C492.261 711.02 473.031 713.15 444.821 718.62L411.061 853.25Z"
          fill="white"
        />
        <path
          d="M644.231 858.41C644.221 858.41 644.211 858.41 644.211 858.41L527.981 854.81C527.581 854.8 527.251 854.48 527.231 854.08L518.041 713.87C518.011 713.47 518.291 713.12 518.681 713.05C522.531 712.37 526.541 711.77 530.421 711.2C551.071 708.14 572.421 704.99 585.831 687.08C586.001 686.85 586.301 686.74 586.571 686.78C586.861 686.83 587.091 687.03 587.181 687.3L644.971 857.38C645.051 857.62 645.011 857.89 644.861 858.09C644.711 858.29 644.471 858.41 644.231 858.41ZM528.731 853.27L643.141 856.81L586.181 689.17C572.361 706.57 551.161 709.7 530.641 712.74C527.001 713.28 523.251 713.83 519.631 714.46L528.731 853.27Z"
          fill="white"
        />
        <path
          d="M747.701 858.41H644.231C643.901 858.41 643.601 858.2 643.501 857.88L585.711 687.8C585.601 687.49 585.701 687.14 585.961 686.94C589.491 684.15 592.651 680.88 595.711 677.73C597.041 676.36 598.371 674.98 599.741 673.64C613.351 653.91 620.741 637.11 623.011 620.78C623.061 620.44 623.321 620.18 623.651 620.12C624.001 620.05 624.321 620.23 624.471 620.52L748.391 857.26C748.511 857.5 748.511 857.79 748.371 858.03C748.231 858.26 747.971 858.41 747.701 858.41ZM644.781 856.85H746.411L624.191 623.34C621.521 639.19 614.101 655.55 600.971 674.57C600.941 674.61 600.911 674.65 600.881 674.69C599.501 676.04 598.161 677.43 596.831 678.81C593.861 681.88 590.791 685.04 587.351 687.82L644.781 856.85ZM600.341 674.14H600.361H600.341Z"
          fill="white"
        />
        <path
          d="M773.171 859.08C764.901 859.08 756.381 858.86 747.661 858.41C747.381 858.39 747.141 858.24 747.011 857.99L623.091 621.25C623.031 621.14 623.001 621.01 623.001 620.89C623.001 614.76 622.881 608.56 622.781 602.55C622.251 573.93 621.751 546.9 632.201 520.21C632.311 519.94 632.551 519.75 632.841 519.72C633.131 519.68 633.411 519.82 633.571 520.07L848.811 850.59C848.951 850.81 848.971 851.08 848.871 851.32C848.771 851.56 848.551 851.73 848.301 851.78C828.871 855.33 811.671 857.56 797.181 858.41C789.471 858.86 781.451 859.08 773.171 859.08ZM748.181 856.88C765.391 857.75 781.851 857.75 797.091 856.86C811.241 856.04 827.981 853.89 846.871 850.47L633.101 522.19C623.331 548.14 623.821 574.57 624.331 602.52C624.441 608.47 624.551 614.62 624.551 620.69L748.181 856.88ZM624.561 620.89H624.581H624.561Z"
          fill="white"
        />
        <path
          d="M848.161 851.79C847.901 851.79 847.651 851.66 847.511 851.44L632.271 520.92C632.101 520.65 632.101 520.31 632.291 520.05C647.001 499.16 660.891 483.62 684.681 470.76C685.031 470.58 685.451 470.67 685.681 470.98L959.791 845.65C959.961 845.89 959.991 846.2 959.851 846.46C959.721 846.72 959.451 846.89 959.161 846.89C920.361 846.89 882.921 849.25 848.231 851.79C848.201 851.79 848.181 851.79 848.161 851.79ZM633.861 520.51L848.561 850.21C882.691 847.7 919.491 845.39 957.621 845.33L684.821 472.44C661.831 485.01 648.211 500.19 633.861 520.51Z"
          fill="white"
        />
        <path
          d="M806.921 638.8C806.891 638.8 806.851 638.8 806.821 638.79C806.611 638.76 806.421 638.65 806.291 638.48L771.211 590.53C770.981 590.21 771.021 589.77 771.311 589.5L956.311 415.61C956.521 415.41 956.811 415.35 957.091 415.44C957.361 415.53 957.561 415.76 957.611 416.04L973.431 505.68C973.481 505.96 973.371 506.24 973.151 506.42L807.401 638.63C807.261 638.73 807.091 638.8 806.921 638.8ZM772.871 590.16L807.061 636.9L971.801 505.49L956.311 417.72L772.871 590.16Z"
          fill="white"
        />
        <path
          d="M771.841 590.84C771.821 590.84 771.791 590.84 771.771 590.84C771.551 590.82 771.351 590.7 771.211 590.52L684.421 471.89C684.291 471.71 684.241 471.49 684.291 471.27C684.341 471.05 684.471 470.87 684.661 470.76C687.051 469.35 689.451 467.91 691.851 466.46C705.081 458.48 718.761 450.23 734.031 446.83C752.051 442.82 770.401 439.49 787.541 436.49C787.791 436.45 788.051 436.53 788.231 436.71L858.391 508.69C858.541 508.84 858.611 509.04 858.611 509.25C858.601 509.46 858.521 509.65 858.361 509.8L772.381 590.62C772.231 590.77 772.041 590.84 771.841 590.84ZM686.181 471.67L771.951 588.9L856.711 509.23L787.391 438.11C770.381 441.08 752.201 444.38 734.361 448.36C719.331 451.71 705.771 459.89 692.641 467.8C690.481 469.1 688.331 470.39 686.181 471.67Z"
          fill="white"
        />
        <path
          d="M17.2709 645.16C17.1309 645.16 16.9809 645.12 16.8609 645.04C16.6209 644.89 16.4809 644.63 16.5009 644.34L21.0909 543.95C21.1009 543.66 21.2709 543.41 21.5309 543.28L240.151 438.42C240.501 438.25 240.941 438.37 241.151 438.71C254.781 460.7 264.401 479.62 270.241 505.97C270.311 506.31 270.161 506.65 269.851 506.82L17.6409 645.07C17.5209 645.13 17.4009 645.16 17.2709 645.16ZM22.6109 544.5L18.1009 643.04L268.571 505.76C262.821 480.16 253.421 461.59 240.181 440.15L22.6109 544.5Z"
          fill="white"
        />
        <path
          d="M28.2609 433.12C28.0909 433.12 27.9209 433.06 27.7809 432.96C27.5809 432.81 27.4709 432.56 27.4809 432.31L35.0208 284.78C35.0308 284.56 35.1308 284.36 35.3008 284.22C35.4608 284.08 35.6809 284.01 35.9009 284.05L235.131 309.54C235.441 309.58 235.691 309.8 235.781 310.1C235.871 310.4 235.771 310.72 235.531 310.92C214.471 328.32 210.351 350.57 207.451 375.74C207.421 376.04 207.201 376.3 206.911 376.39L28.5009 433.11C28.4209 433.11 28.3409 433.12 28.2609 433.12ZM36.5309 285.69L29.0909 431.26L205.951 375.04C208.831 350.28 212.981 328.31 233.171 310.84L36.5309 285.69Z"
          fill="white"
        />
        <path
          d="M235.031 311.08C235.001 311.08 234.961 311.08 234.931 311.07L35.7008 285.58C35.3008 285.53 35.0108 285.19 35.0208 284.78L38.9708 158.42C38.9808 158.18 39.1008 157.95 39.3008 157.81L111.451 106.48C111.741 106.27 112.131 106.29 112.411 106.52L286.871 255.85C287.031 255.99 287.131 256.19 287.141 256.4C287.151 256.61 287.071 256.82 286.921 256.98C279.061 265.11 271.161 273.47 263.531 281.56C254.431 291.2 245.021 301.17 235.591 310.82C235.441 310.99 235.241 311.08 235.031 311.08ZM36.6008 284.13L234.741 309.48C244.071 299.92 253.381 290.06 262.391 280.51C269.841 272.62 277.541 264.46 285.221 256.51L111.851 108.11L40.5108 158.87L36.6008 284.13Z"
          fill="white"
        />
        <path
          d="M286.361 257.23C286.181 257.23 286.001 257.17 285.851 257.04L111.401 107.72C111.221 107.57 111.121 107.34 111.131 107.11C111.141 106.87 111.251 106.65 111.441 106.51L251.441 0.610049C251.631 0.470049 251.871 0.420044 252.101 0.480044C252.331 0.540044 252.521 0.700047 252.621 0.920047L352.051 223.48C352.151 223.7 352.141 223.96 352.021 224.17C351.901 224.38 351.691 224.53 351.451 224.56C322.141 228.94 306.841 240.27 286.861 257.05C286.711 257.17 286.541 257.23 286.361 257.23ZM113.141 107.16L286.361 255.43C306.071 238.9 321.391 227.67 350.201 223.17L251.581 2.44005L113.141 107.16Z"
          fill="white"
        />
        <path
          d="M402.321 338C402.181 338 402.031 337.96 401.901 337.88C401.651 337.72 401.521 337.44 401.541 337.15C403.181 319.13 403.651 300.97 404.111 283.41C404.281 276.92 404.451 270.41 404.671 263.87C404.671 263.74 404.711 263.62 404.771 263.51L462.431 163.24C462.551 163.03 462.761 162.89 463.001 162.86L582.701 146.57C582.971 146.53 583.211 146.63 583.391 146.82C583.561 147.01 583.631 147.28 583.561 147.53C578.791 166 583.321 186.67 587.701 206.67C589.211 213.55 590.631 220.04 591.681 226.43C591.731 226.75 591.581 227.06 591.311 227.23L402.711 337.9C402.591 337.97 402.461 338 402.321 338ZM406.221 264.12C406.001 270.59 405.831 277.03 405.661 283.46C405.221 300.56 404.761 318.22 403.221 335.79L590.061 226.15C589.021 219.97 587.641 213.66 586.181 206.99C581.851 187.22 577.381 166.78 581.771 148.25L463.581 164.34L406.221 264.12Z"
          fill="white"
        />
        <path
          d="M431.781 405.76C431.531 405.76 431.291 405.64 431.141 405.43C415.991 383.78 408.361 362.59 401.571 337.42C401.481 337.08 401.621 336.72 401.931 336.55L590.531 225.88C590.741 225.76 590.991 225.74 591.221 225.83C591.441 225.92 591.611 226.11 591.671 226.35L608.741 290.9C608.831 291.22 608.691 291.57 608.411 291.75L432.201 405.64C432.071 405.72 431.921 405.76 431.781 405.76ZM403.231 337.59C409.881 362.11 417.361 382.84 431.991 403.92L607.101 290.74L590.441 227.73L403.231 337.59Z"
          fill="white"
        />
        <path
          d="M471.471 467.72C471.391 467.72 471.321 467.71 471.241 467.69C471.031 467.63 470.861 467.48 470.771 467.28C460.441 445.5 447.461 426.82 431.161 405.47C431.031 405.3 430.971 405.08 431.011 404.86C431.051 404.65 431.171 404.46 431.351 404.34L607.561 290.45C607.751 290.33 607.991 290.29 608.211 290.36C608.431 290.43 608.611 290.59 608.701 290.8C613.651 302.24 615.991 311.8 618.331 324.36L623.961 327.42L629.941 327.08C636.651 321.25 640.731 315.85 646.291 307.38C646.471 307.11 646.781 306.98 647.091 307.04C647.401 307.1 647.641 307.35 647.701 307.66L656.981 358.11C657.041 358.43 656.891 358.76 656.611 358.92L471.861 467.62C471.741 467.68 471.611 467.72 471.471 467.72ZM432.901 405.18C448.841 426.08 461.581 444.49 471.801 465.83L655.351 357.84L646.521 309.82C641.351 317.58 637.281 322.77 630.751 328.4C630.621 328.51 630.461 328.58 630.291 328.59L623.831 328.96C623.691 328.98 623.541 328.94 623.411 328.87L617.261 325.53C617.051 325.42 616.911 325.22 616.871 324.99C614.591 312.67 612.331 303.29 607.641 292.24L432.901 405.18Z"
          fill="white"
        />
        <path
          d="M460.841 542.04C460.681 542.04 460.521 541.99 460.391 541.9C460.151 541.73 460.031 541.44 460.071 541.16L470.701 466.84C470.731 466.61 470.871 466.4 471.071 466.28L655.821 357.59C656.041 357.46 656.301 357.45 656.531 357.55C656.761 357.65 656.921 357.85 656.981 358.1L672.791 431.13C672.871 431.47 672.701 431.82 672.391 431.98L461.201 541.95C461.091 542.01 460.961 542.04 460.841 542.04ZM472.191 467.42L461.831 539.87L671.141 430.86L655.681 359.46L472.191 467.42Z"
          fill="white"
        />
        <path
          d="M675.501 445.29C675.371 445.29 675.241 445.26 675.121 445.19C674.921 445.08 674.781 444.89 674.731 444.67L646.181 307.95C646.141 307.77 646.171 307.58 646.261 307.41C656.971 288.14 664.681 270.17 671.281 249.14C671.371 248.86 671.611 248.65 671.911 248.6C672.201 248.56 672.501 248.68 672.671 248.93L784.121 410.35C784.271 410.57 784.301 410.84 784.201 411.08C784.101 411.32 783.891 411.5 783.631 411.55C748.771 418.49 717.841 429.83 685.091 441.83L675.781 445.24C675.681 445.28 675.591 445.29 675.501 445.29ZM647.761 307.92L676.081 443.48L684.551 440.38C716.991 428.49 747.661 417.25 782.161 410.27L672.281 251.12C665.791 271.52 658.191 289.11 647.761 307.92Z"
          fill="white"
        />
        <path
          d="M783.471 411.57C783.221 411.57 782.981 411.45 782.831 411.23L671.381 249.81C671.271 249.65 671.231 249.46 671.251 249.27C671.571 246.76 671.821 244.24 672.071 241.73C673.341 229.06 674.661 215.96 684.491 205.96C684.621 205.83 684.801 205.74 684.981 205.73L698.471 204.69L721.571 185.27C721.741 185.13 721.961 185.06 722.181 185.1C722.401 185.13 722.601 185.26 722.721 185.44L840.211 365.39C840.411 365.69 840.371 366.09 840.121 366.36C819.981 387.22 806.421 399.29 783.831 411.49C783.721 411.54 783.601 411.57 783.471 411.57ZM672.831 249.17L783.711 409.78C805.591 397.87 818.971 385.99 838.581 365.71L721.911 187.02L699.291 206.04C699.171 206.14 699.011 206.21 698.851 206.22L685.401 207.26C676.141 216.84 674.921 229.01 673.631 241.89C673.381 244.31 673.141 246.75 672.831 249.17Z"
          fill="white"
        />
        <path
          d="M839.571 366.59C839.531 366.59 839.491 366.59 839.461 366.58C839.241 366.55 839.041 366.42 838.921 366.24L721.431 186.29C721.291 186.08 721.261 185.81 721.361 185.57C733.011 156.75 744.801 135.96 759.591 118.15C759.801 117.89 760.151 117.8 760.471 117.92L786.401 128.12C786.551 128.18 786.691 128.29 786.781 128.44C793.761 139.8 800.951 151.3 807.911 162.42C821.831 184.68 836.231 207.7 849.631 230.75C861.001 250.3 867.961 273.24 874.691 295.42C877.391 304.31 880.181 313.5 883.171 322.16C883.231 322.34 883.221 322.54 883.151 322.72L878.391 333.84C878.341 333.95 878.271 334.05 878.181 334.13L840.071 366.41C839.931 366.52 839.751 366.59 839.571 366.59ZM722.951 185.78L839.741 364.65L877.031 333.05L881.601 322.38C878.631 313.78 875.871 304.67 873.201 295.87C866.491 273.77 859.551 250.93 848.281 231.53C834.891 208.5 820.501 185.49 806.581 163.25C799.661 152.2 792.521 140.77 785.581 129.48L760.411 119.58C745.971 137.08 734.391 157.54 722.951 185.78Z"
          fill="white"
        />
        <path
          d="M508.981 685.53C508.891 685.53 508.811 685.51 508.731 685.49C478.161 674.95 465.421 660.27 451.331 635.16C451.281 635.07 451.241 634.97 451.231 634.86L448.201 606.71C448.161 606.35 448.371 606.02 448.711 605.9L597.961 551.39C598.231 551.29 598.531 551.34 598.751 551.54C598.961 551.73 599.051 552.02 598.991 552.3C596.071 564.34 597.031 576.69 597.951 588.62C599.331 606.5 600.751 624.99 588.981 642.35C570.091 670.19 535.121 681.2 509.121 685.53C509.071 685.53 509.031 685.53 508.981 685.53ZM452.761 634.53C466.601 659.17 479.141 673.6 509.051 683.95C534.731 679.64 569.151 668.77 587.681 641.46C599.151 624.56 597.811 607.15 596.391 588.73C595.501 577.13 594.571 565.16 597.151 553.33L449.811 607.14L452.761 634.53Z"
          fill="white"
        />
        <path
          d="M857.821 510.02C857.621 510.02 857.421 509.94 857.261 509.79L787.101 437.8C786.911 437.6 786.831 437.31 786.911 437.04C786.991 436.77 787.201 436.56 787.471 436.5C811.461 430.66 828.101 413.84 844.191 397.58C858.421 383.2 873.131 368.33 892.831 361.21C893.061 361.12 893.321 361.16 893.531 361.29C914.241 375.09 931.491 391.29 949.751 408.44C952.271 410.81 954.811 413.19 957.371 415.58C957.521 415.73 957.621 415.93 957.621 416.15C957.621 416.37 957.531 416.57 957.371 416.72L858.351 509.8C858.201 509.96 858.011 510.02 857.821 510.02ZM789.161 437.68L857.841 508.15L955.701 416.16C953.351 413.96 951.011 411.77 948.681 409.59C930.561 392.57 913.441 376.5 892.991 362.81C873.811 369.86 859.321 384.51 845.301 398.67C829.351 414.8 812.871 431.46 789.161 437.68Z"
          fill="white"
        />
        <path
          d="M260.981 422.85C260.771 422.85 260.551 422.76 260.401 422.59C259.311 421.38 258.231 420.19 257.171 419.02C245.511 406.19 235.441 395.11 233.671 376.41C231.791 356.51 239.791 344.95 251.301 330.79C251.321 330.76 251.351 330.74 251.371 330.71L315.361 271.32C316.511 270.25 317.661 269.16 318.821 268.07C331.211 256.34 345.251 243.04 364.501 253.3C385.021 264.23 383.621 290.59 380.961 305.33C377.541 324.29 362.391 336.22 347.731 347.75C343.751 350.88 339.641 354.11 335.891 357.41L261.501 422.66C261.341 422.78 261.161 422.85 260.981 422.85ZM252.471 331.8C241.211 345.65 233.391 356.96 235.221 376.25C236.931 394.43 246.851 405.34 258.321 417.96C259.221 418.95 260.131 419.95 261.051 420.97L334.871 356.22C338.661 352.9 342.791 349.65 346.781 346.51C361.211 335.15 376.131 323.41 379.441 305.03C382.021 290.71 383.421 265.11 363.781 254.65C345.521 244.93 332.501 257.26 319.901 269.18C318.741 270.28 317.581 271.37 316.431 272.44L252.471 331.8Z"
          fill="white"
        />
        <path
          d="M296.151 501.87C296.051 501.87 295.951 501.85 295.861 501.82C295.601 501.71 295.411 501.47 295.381 501.19C291.371 469.46 279.011 447.56 260.351 422.53C260.111 422.21 260.161 421.75 260.461 421.48C269.531 413.49 278.961 405.33 288.091 397.43C317.781 371.75 348.471 345.2 375.251 317.2C375.471 316.97 375.811 316.89 376.111 317.02C376.411 317.14 376.601 317.43 376.591 317.75C375.871 352.14 384.321 381.77 403.981 413.81C404.191 414.14 404.111 414.58 403.811 414.82L296.641 501.7C296.501 501.81 296.331 501.87 296.151 501.87ZM262.031 422.18C280.331 446.81 292.551 468.53 296.751 499.62L402.301 414.06C383.101 382.59 374.641 353.38 375.001 319.72C348.501 347.24 318.321 373.36 289.111 398.62C280.161 406.35 270.921 414.35 262.031 422.18Z"
          fill="white"
        />
        <path
          d="M318.941 569.89C318.871 569.89 318.791 569.88 318.721 569.86C318.511 569.8 318.341 569.65 318.241 569.45C308.611 549.68 303.171 529.75 297.411 508.65C296.741 506.21 296.081 503.76 295.401 501.3C295.321 501 295.421 500.68 295.661 500.49L402.821 413.62C402.991 413.48 403.221 413.41 403.431 413.46C403.651 413.5 403.841 413.62 403.961 413.81C407.201 418.94 410.441 424.07 413.691 429.2C419.991 439.15 426.501 449.43 432.821 459.6C433.661 460.95 434.541 462.3 435.431 463.67C441.011 472.26 446.781 481.15 444.571 491.84C444.551 491.96 444.491 492.07 444.421 492.16L442.891 494.1C442.831 494.17 442.761 494.23 442.691 494.28L319.341 569.78C319.221 569.85 319.081 569.89 318.941 569.89ZM297.041 501.38C297.671 503.68 298.301 505.97 298.921 508.25C304.581 529.01 309.931 548.62 319.271 568.01L441.771 493.03L443.101 491.34C445.081 481.35 439.521 472.8 434.141 464.52C433.251 463.15 432.371 461.79 431.521 460.43C425.201 450.27 418.691 439.99 412.391 430.04C409.301 425.16 406.211 420.28 403.131 415.39L297.041 501.38ZM442.291 493.62H442.311H442.291Z"
          fill="white"
        />
        <path
          d="M422.051 701.89C421.961 701.89 421.871 701.87 421.791 701.84C412.091 698.39 406.191 689.66 400.491 681.23C398.831 678.77 397.261 676.45 395.601 674.27C390.591 667.66 385.321 661.04 380.231 654.65C377.261 650.92 374.291 647.19 371.351 643.44C351.611 618.25 334.251 594.08 318.291 569.54C318.181 569.36 318.141 569.15 318.181 568.94C318.231 568.74 318.351 568.56 318.531 568.45L443.411 491.01C443.671 490.86 444.001 490.86 444.251 491.03C444.511 491.2 444.631 491.5 444.581 491.81L442.461 504.06V504.07C440.461 514.74 438.301 525.58 436.211 536.07C431.891 557.71 427.431 580.09 424.141 602.2C421.571 619.47 423.151 633.56 429.261 647.9C430.711 651.29 432.941 654.92 435.301 658.75C440.911 667.87 447.281 678.22 443.861 687.81C443.821 687.93 443.751 688.03 443.661 688.12C442.881 688.85 442.101 689.6 441.321 690.35C435.781 695.66 430.051 701.15 422.121 701.89C422.101 701.89 422.071 701.89 422.051 701.89ZM320.031 569.36C335.851 593.64 353.041 617.56 372.581 642.49C375.521 646.24 378.481 649.96 381.451 653.69C386.551 660.09 391.821 666.71 396.841 673.34C398.521 675.56 400.101 677.9 401.781 680.37C407.311 688.55 413.031 697 422.151 700.33C429.451 699.57 434.941 694.32 440.241 689.23C440.991 688.52 441.731 687.81 442.461 687.12C445.491 678.28 439.641 668.77 433.981 659.57C431.591 655.68 429.321 652 427.841 648.51C421.611 633.9 419.991 619.54 422.611 601.97C425.901 579.82 430.371 557.43 434.691 535.77C436.781 525.29 438.941 514.45 440.941 503.79L442.771 493.25L320.031 569.36ZM441.701 503.93H441.721H441.701Z"
          fill="white"
        />
        <path
          d="M22.9305 543.974L29.2157 433.024L206.122 376.114C210.206 401.958 220.234 414.702 234.395 432.699C235.978 434.711 237.613 436.789 239.296 438.957L22.9305 543.974Z"
          stroke="white"
          stroke-width="1.7"
        />
        <path
          d="M597.574 550.866L450.221 604.683L461.742 541.192L671.609 431.896L674.651 443.47C632.22 467.291 602.378 500.616 597.574 550.866Z"
          stroke="white"
          stroke-width="1.7"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_34_68"
          x="0"
          y="0.455078"
          width="977.443"
          height="866.625"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_34_68"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_34_68"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
