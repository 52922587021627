import React from "react";
import { Image } from "react-bootstrap";
import {
  favoris,
  //  loaction_icon,
  promesses,
} from "../../assets/images";
import "./style.scss";
import strings from "../../utils/fr.json";

const Footer = (props) => {
  const { footerActive, setFooterActive } = props;
  const icons = [
    // {
    //   title: strings.Header.explore,
    //   icon: <Image src={loaction_icon} className="icon" fluid />,
    // },
    // { title: "Cliquez sur une phase pour commencer votre navigation", icon: <BsHandIndexThumb className="icon" /> }
    {
      title: strings.Promises.promise,
      icon: <Image src={promesses} className="icon" fluid />,
    },
    {
      title: strings.Header.favorite,
      icon: <Image src={favoris} className="icon" fluid />,
    },
  ];

  return (
    <>
      <div className="footer">
        <ul>
          {icons.map((item, index) => {
            return (
              <li
                key={index.toString()}
                className={index === footerActive ? "active" : ""}
                onClick={() => setFooterActive(index)}
              >
                {item.icon}
                <p>{item.title}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};
export default Footer;
