import React from "react";
import { Row, Col, Image, ProgressBar } from "react-bootstrap";
import { domian_pine_hill_logo, stComeLogo } from "../../assets/images";
import { constant } from "../../utils/constant.js";
import { colors } from "../../utils/theme.js";
import strings from "../../utils/fr.json";
import "./style.scss";
const GroundCondition = ({ hide, disponibles, totalLand, phase = "" }) => {
  var checkMap = process.env.REACT_APP_MAP === constant.pinehill;

  return (
    <>
      <ul className={`g-condition desktop d-none d-lg-block ${hide}`}>
        <li className="main">{strings.Terrains.groundCondition}</li>
        <li className="item">
          <div
            className="item-box first"
            style={{
              backgroundColor:
                process.env.REACT_APP_MAP === constant.pinehill
                  ? phase === "phase4"
                    ? colors.green3
                    : colors.green
                  : colors.green,
            }}
          ></div>{" "}
          {strings.Status.forSale}
        </li>
        <li className="item">
          <div className="item-box second"></div> {strings.Status.reserve}
        </li>
        <li className="item">
          <div className="item-box third"></div> {strings.Status.sold}
        </li>
      </ul>
      <div className={`g-condition mobile d-lg-none ${hide}`}>
        <Row>
          <Col xs={4} md={3} className="mx-auto">
            <Image
              src={checkMap ? domian_pine_hill_logo : stComeLogo}
              alt="logo"
              fluid
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="item">
              <span className="item-box first"></span> {strings.Status.forSale}
            </div>
          </Col>
          <Col>
            <div className="item">
              <span className="item-box second"></span> {strings.Status.reserve}
            </div>
          </Col>
          <Col>
            <div className="item">
              <span className="item-box third"></span> {strings.Status.sold}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="item-ground">
              <ProgressBar
                variant="ground-progress"
                now={Math.abs((disponibles * 100) / totalLand - 100).toFixed(0)}
              />
              <span>
                {totalLand > 0
                  ? Math.abs((disponibles * 100) / totalLand - 100).toFixed(0)
                  : 0}
                %
              </span>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default GroundCondition;
