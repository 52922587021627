import React from "react";
import {
  Modal,
  Row,
  Col,
  Container,
  // Image,
  Button,
  Form,
} from "react-bootstrap";
// import { pine_hill_forest } from "../../assets/images";
import { BsArrowLeft } from "react-icons/bs";
import "./index.scss";
import strings from "../../utils/fr.json";

class LandReserch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullscreen: true,
    };
  }

  render() {
    const { show, handleResearch } = this.props;
    return (
      <Modal
        className="land-reaserch"
        show={show}
        fullscreen={this.state.fullscreen}
        onHide={handleResearch}
      >
        <Modal.Header>
          <Container>
            <Row>
              <Col xl={10} md={9} xs={8}>
                <div className="h-100 d-flex flex-wrap align-items-center">
                  <Button
                    variant="white"
                    className="rounded-pill back-btn"
                    onClick={handleResearch}
                  >
                    <BsArrowLeft />
                  </Button>
                  <div className="title d-xl-block d-none">
                    <h2>{strings.LandResearch.perfectLand}</h2>
                  </div>
                </div>
              </Col>
              <Col xl={2} md={3} xs={4}>
                <div className="h-100 d-flex align-items-center justify-content-end">
                  {/* <Image src={pine_hill_forest} alt="logo" fluid /> */}
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col xxl={10}>
                <Form.Group className="form-group b-bottom">
                  <Form.Label>{strings.Terrains.squarefeetNum}</Form.Label>
                  <Row>
                    <Col xs={6}>
                      <Form.Control type="text" placeholder="Minimum" />
                    </Col>
                    <Col xs={6}>
                      <Form.Control type="text" placeholder="Maximum" />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="form-group b-bottom">
                  <Form.Label>{strings.Terrains.pricePerSquare}</Form.Label>
                  <Row>
                    <Col xs={6}>
                      <Form.Control type="text" placeholder="Minimum" />
                    </Col>
                    <Col xs={6}>
                      <Form.Control type="text" placeholder="Maximum" />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>{strings.Terrains.pricePerSquare}</Form.Label>
                  <Form.Select className="form-control">
                    <option>{strings.LandResearch.wooded}</option>
                    <option value={strings.LandResearch.wooded}>
                      {strings.LandResearch.wooded}
                    </option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row>
              <Col xl={5} lg={6}>
                <Button
                  variant="green"
                  size="lg"
                  className="w-100 reserver-btn"
                >
                  {strings.Terrains.book}
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default LandReserch;
