import React, { useEffect, useState } from "react";
import {
  Modal,
  Row,
  Col,
  Container,
  Image,
  Button,
  Tab,
  Nav,
} from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import {
  ref,
  onValue,
  set,
  orderByChild,
  equalTo,
  query,
} from "firebase/database";
import { rdb } from "../../services/firebase";
import { progress, compass_pin } from "../../assets/images";
import { remainingTime } from "../timer";
import { Timer } from "../index";
import {
  BsArrowLeft,
  // BsChat
} from "react-icons/bs";
import { CgSun } from "react-icons/cg";
import { FiSunrise, FiSunset } from "react-icons/fi";
import "./index.scss";
import { colors, phaseLogo } from "../../utils/theme";
import { constant } from "../../utils/constant";
import strings from "../../utils/fr.json";
import { filterString, formatPrice, setTrackingCode } from "../../utils/helper";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import { areaRule } from "../maps/pineMap/areaRule";
import { stcomeAreaSet1, stcomeAreaSet2 } from "../../utils/stComeData";
import {
  areaSet,
  phase2AreaSet,
  phase3AreaSet,
  phase4AreaSet,
  phase4NordAreaSet,
} from "../../utils/data";

const Terrains = (props) => {
  const {
    show,
    handleTerrains,
    disableReserve,
    handleSubmitDetails,
    setPlanVisitModal,
    dateLimit,
    timeLimit,
  } = props;

  const isGuestLogin = localStorage.getItem("@guest");
  const pathName = window.location.pathname;
  const phasecheck = pathName.includes("phase4");
  const [timeRemain, setTimeRemain] = useState(true);
  const [dealDetails, setDealDetails] = useState(null);
  const [disableReserves, setDisableReserve] = useState(disableReserve);

  const { id } = useParams();
  const navigate = useNavigate();
  const { setIdentity, setPathPageView, setTrackPageView } = useTrackingCode();

  const phaseTitle = pathName.includes("phase1")
    ? "PHASE 1"
    : pathName.includes("phase2")
    ? "PHASE 2"
    : pathName.includes("phase3")
    ? "PHASE 3"
    : pathName.includes("phase4")
    ? "PHASE 4"
    : "PHASE 4 NORD";
  const infoList = [
    {
      title: strings.Terrains.batchNumber,
      area: "identification_du_lot_ph",
    },
    { title: strings.Terrains.squarefeetNum, area: "nombre_de_pi2" },
    {
      title: phasecheck
        ? strings.Terrains.fixedPrice // strings.Terrains.totalPriceWithoutInfrastructure
        : strings.Terrains.pricePerSquare,
      area: phasecheck
        ? "tarif_fixe_pour_ph4" // "prix_total_sans_infrastructure"
        : "prix_au_pi2",
    },
    {
      title: strings.Terrains.infrastructureCosts,
      area: "frais_d_infrastructure",
    },
    // { title: strings.Terrains.landDescription, area: "desc" },
    { title: strings.Terrains.groundCondition, area: strings.Status.sold },
    // { title: strings.Terrains.totalAndPrice, area: "amount" },
  ];
  const [deg_value, setDeg_value] = useState(0);
  // deg_value: 0,

  useEffect(() => {
    setTrackingCode(setIdentity, setPathPageView, setTrackPageView);
    if (dateLimit && timeLimit) {
      setTimeRemain(remainingTime(dateLimit, timeLimit));
      setInterval(() => {
        setTimeRemain(remainingTime(dateLimit, timeLimit));
      }, 1000);
    } else {
      setTimeRemain(false);
    }

    const getDealDetail = async () => {
      var dealDetailQuery = await query(
        ref(rdb, "/deals"),
        orderByChild("dealId"),
        equalTo(Number(id))
      );

      onValue(
        dealDetailQuery,
        async (snapshot) => {
          let deal = snapshot.val() || {};
          deal = Object.values(deal) || [];
          const dealValue = deal[0];

          const dataName = pathName.includes("phase1")
            ? filterString(
                dealValue?.properties?.identification_du_lot_ph?.value || ""
              )
                .substr(0, 1)
                .toString() === "C"
              ? stcomeAreaSet1
              : areaSet
            : pathName.includes("phase2")
            ? filterString(
                dealValue?.properties?.identification_du_lot_ph?.value || ""
              )
                .substr(0, 1)
                .toString() === "C"
              ? stcomeAreaSet2
              : phase2AreaSet
            : pathName.includes("phase3")
            ? phase3AreaSet
            : pathName.includes("phase4nord")
            ? phase4NordAreaSet
            : phase4AreaSet;

          const area = dataName.find(
            (d) =>
              d.title ===
              (pathName.includes("phase4nord") || pathName.includes("phase4")
                ? filterString(
                    dealValue?.properties.identification_du_lot_ph?.value || ""
                  )
                    .substr(4, 4)
                    .trim()
                : filterString(
                    dealValue?.properties.identification_du_lot_ph?.value || ""
                  ))
          );

          const checkStatus =
            dealValue?.properties?.statut_des_promesses_d_achat?.value;
          const reservationStatus =
            dealValue?.properties?.reservation_via_la_carte_interactive?.value;
          const dealStageValue = dealValue?.properties.dealstage?.value;
          const checkPinehill =
            process.env.REACT_APP_MAP === constant.pinehill
              ? "pinehill"
              : "stcome";
          const stageValue =
            constant.dealsStage[checkPinehill][
              phaseTitle.toLocaleLowerCase().replace(/ +/g, "")
            ];

          const areaColor = areaRule(
            checkStatus,
            reservationStatus,
            dealStageValue,
            stageValue
          );
          setDisableReserve(checkStatus === strings.Status.complete);
          setDealDetails({ deals: [dealValue], ...area, colors: areaColor });
        },
        { onlyOnce: true }
      );
    };
    getDealDetail();
  }, []);

  const renderInfoList = () => {
    const status =
      dealDetails?.colors === colors.pink
        ? strings.Status.sold
        : dealDetails?.colors === colors.green ||
          dealDetails?.colors === colors.green3
        ? strings.Status.available
        : strings.Status.reserve;
    return infoList.map((item, index) => {
      const deal = dealDetails?.deals[0].properties;

      var area =
        item.area === strings.Status.sold
          ? status
          : item.area === "identification_du_lot_ph"
          ? filterString(deal?.identification_du_lot_ph?.value) ||
            deal?.dealname?.value
          : item.area === "desc"
          ? deal?.description?.value || ""
          : item.area === "amount"
          ? deal?.amount?.value
            ? `${formatPrice(deal?.amount?.value)}`
            : ""
          : item.area === "nombre_de_pi2"
          ? deal?.nombre_de_pi2?.value
            ? `${Math.round(deal?.nombre_de_pi2?.value.replace(",", "."))} Pi²`
            : ""
          : item.area === "frais_d_infrastructure"
          ? deal?.frais_d_infrastructure?.value
            ? `${formatPrice(deal?.frais_d_infrastructure?.value)}`
            : ""
          : item.area === "prix_au_pi2"
          ? deal?.prix_au_pi2?.value
            ? `${formatPrice(deal?.prix_au_pi2?.value)}`
            : ""
          : item.area === "tarif_fixe_pour_ph4"
          ? deal?.tarif_fixe_pour_ph4?.value
            ? `${formatPrice(deal?.tarif_fixe_pour_ph4?.value)}`
            : ""
          : item.area === "prix_total_sans_infrastructure"
          ? deal?.prix_total_sans_infrastructure?.value
            ? `${formatPrice(deal?.prix_total_sans_infrastructure?.value)}`
            : ""
          : "";

      return (
        <div key={index.toString()} className="info-group">
          <h5 className="info-title">{item.title}</h5>
          <span className="info-text">{area}</span>
        </div>
      );
    });
  };

  const renderButton = (text) => {
    if (!disableReserves && isGuestLogin !== "true" && !timeRemain) {
      return (
        <Button
          onClick={handleSubmitDetails}
          variant="green"
          size="lg"
          className="w-100 rounded-pill"
        >
          {text}
        </Button>
      );
    } else if (timeRemain && dateLimit && timeLimit) {
      return (
        <div className="d-flex justify-content-center mt-4 mt-xxl-5">
          <Timer
            style={{
              width: "100%",
              maxWidth: "100%",
              justifyContent: "center",
              paddingTop: "8px",
              paddingBottom: "8px",
            }}
            dateLimit={dateLimit}
            timeLimit={timeLimit}
          />
        </div>
      );
    }
    return null;
  };

  const renderTitle = (style) => {
    const deal = dealDetails?.deals[0].properties;
    return (
      <div className={style}>
        <h2>{`${
          phasecheck
            ? filterString(deal?.identification_du_lot_ph?.value)
                ?.substr(4, 1)
                .toString() === "C"
              ? "Chalet"
              : filterString(deal?.identification_du_lot_ph?.value)
                  ?.substr(4, 1)
                  .toString() === "M"
              ? "Mini Chalet"
              : "Dome"
            : "Terrain"
        } ${filterString(deal?.identification_du_lot_ph?.value) || ""}`}</h2>
        <h6 className="green">{phaseTitle}</h6>
      </div>
    );
  };

  // const rotation = (deg) => {
  //   setDeg_value(deg);
  // };

  const handleClick = (handleSubmitDetails) => {
    // const currentPath = props.location.pathname;
    const userDataString = localStorage.getItem("@userData");
    if (userDataString) {
      // TrackingCodeRenderer(userDataString, currentPath);
      handleSubmitDetails();
    }
  };

  const handleScheduleVisit = () => {
    handleTerrains();
    navigate(-1);
    setTimeout(() => {
      setPlanVisitModal();
    }, 500);
  };

  const progress_bar = () => {
    return (
      <div className="terrain-map">
        <Image className="progress-line w-100" src={progress} fluid />
        <Button
          variant="dark"
          className="rounded-pill btn-circle btn-1"
          // onClick={() => this.rotation("0")}
        >
          <FiSunset />
        </Button>
        <Button
          variant="dark"
          className="rounded-pill btn-circle btn-2"
          // onClick={() => this.rotation("90")}
        >
          <CgSun />
        </Button>
        <Button
          variant="dark"
          className="rounded-pill btn-circle btn-3"
          // onClick={() => this.rotation("180")}
        >
          <FiSunrise />
        </Button>
        <div className="btn-4">
          <Image src={compass_pin} fluid />
          <Button variant="" className="n">
            N
          </Button>
          <Button variant="" className="e">
            E
          </Button>
          <Button variant="" className="s">
            S
          </Button>
          <Button variant="" className="w">
            W
          </Button>
        </div>
        <div
          className="terrain-map-img"
          style={{
            transform: `translate(-50%, -50%) rotate(${deg_value}deg)`,
          }}
        >
          {dealDetails && dealDetails.area ? (
            <dealDetails.area
              fill={dealDetails.colors}
              width={200}
              height={150}
            />
          ) : null}
        </div>
      </div>
    );
  };

  // var checkMap = process.env.REACT_APP_MAP === constant.pinehill;
  var currentPhase = phaseTitle?.toLowerCase();

  return (
    <Modal
      className="information"
      show={show}
      fullscreen={true}
      onHide={handleTerrains}
    >
      <Modal.Body className="d-lg-flex flex-column d-none desktop">
        <div className="info-header">
          <Container>
            <Row>
              <Col xl={10} md={9} sm={8} xs={8}>
                <div className="h-100 d-flex flex-wrap align-items-center">
                  <Button
                    variant="white"
                    className="rounded-pill btn-circle"
                    onClick={() => {
                      navigate(-1);
                      handleTerrains();
                    }}
                  >
                    <BsArrowLeft />
                  </Button>
                  {renderTitle("title")}
                </div>
              </Col>
              <Col xl={2} md={3} sm={4} xs={4}>
                <div className="h-100 d-flex align-items-center justify-content-end">
                  <Image src={phaseLogo(currentPhase)} alt="logo" fluid />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="info-bottom">
          <Container>
            <Row>
              <Col xxl={5} lg={6}>
                <div className="info-left">
                  <h3 className="col-title">{strings.Forms.informations}</h3>
                  {renderInfoList()}
                </div>
              </Col>
              <Col xxl={1} lg={1} className="info-center"></Col>
              <Col xxl={6} lg={5}>
                <div className="info-right">
                  <h3 className="col-title">{strings.Terrains.exposure}</h3>
                  {/* <Image src={info1} alt="logo" fluid /> */}
                  {progress_bar()}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-lg-block d-none">
        <Container>
          <Row>
            <Col xl={5} lg={6}>
              <Row className="gy-3">
                {!disableReserves && isGuestLogin !== "true" && !timeRemain && (
                  <Col sm={6}>
                    <Button
                      variant="green"
                      size="lg"
                      className="w-100 rounded-pill reserver-btn"
                      onClick={() => handleClick(handleSubmitDetails)}
                    >
                      {strings.Terrains.book}
                    </Button>
                  </Col>
                )}

                {timeRemain && dateLimit && timeLimit && (
                  <Col sm={6}>
                    <Timer
                      dateLimit={dateLimit}
                      timeLimit={timeLimit}
                      style={{
                        maxWidth: "100%",
                        justifyContent: "center",
                        paddingTop: "11px",
                        paddingBottom: "11px",
                      }}
                    />
                  </Col>
                )}
                {!disableReserves && isGuestLogin !== "true" && (
                  <Col sm={6}>
                    <Button
                      variant="green"
                      size="lg"
                      // href={
                      //   checkMap
                      //     ? "https://www.eventbrite.com/myevent?eid=408235602677"
                      //     : ""
                      // }
                      // target="_blank"
                      onClick={handleScheduleVisit}
                      className="w-100 rounded-pill plan-visit-btn cursor-pointer"
                    >
                      {strings.Terrains.scheduleAVisit}
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
            {/* <Col className="text-end">
                <Button
                  variant="white"
                  size="lg"
                  className="rounded-pill btn-circle btn-chat ms-auto"
                >
                  <BsChat />
                </Button>
              </Col> */}
          </Row>
        </Container>
      </Modal.Footer>
      <Modal.Body className="d-lg-none mobile">
        <div className="info-header">
          <Row>
            <Col sm={8} xs={7}>
              <div className="h-100 d-flex align-items-center">
                <BsArrowLeft
                  className="orange fs-20 cursor-pointer"
                  onClick={handleTerrains}
                />
              </div>
            </Col>
            <Col sm={4} xs={5}>
              <div className="h-100 d-flex align-items-center justify-content-end">
                <Image src={phaseLogo(currentPhase)} alt="logo" fluid />
              </div>
            </Col>
          </Row>
          {renderTitle("title my-5")}
        </div>
        <div className="info-bottom">
          <Tab.Container id="theme-tabs" defaultActiveKey="information">
            <Nav>
              <Nav.Item>
                <Nav.Link eventKey="information">
                  {strings.Forms.informations}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="exposition-au-soleil">
                  {strings.Terrains.exposure}
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="information">
                {renderInfoList()}
                <div className="imformation-footer">
                  <div className="vstack gap-3">
                    {renderButton(strings.Terrains.book)}
                    {!disableReserves && isGuestLogin !== "true" && (
                      <Button
                        variant="green"
                        size="lg"
                        // href={
                        //   checkMap
                        //     ? "https://www.eventbrite.com/myevent?eid=408235602677"
                        //     : ""
                        // }
                        // target="_blank"
                        onClick={handleScheduleVisit}
                        className="w-100 rounded-pill plan-visit-btn cursor-pointer"
                      >
                        {strings.Terrains.scheduleAVisit}
                      </Button>
                    )}

                    {/* <div>
                        <Button
                          variant="white"
                          size="lg"
                          className="rounded-pill btn-circle ms-3"
                        >
                          <BsChat />
                        </Button>
                      </div> */}
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="exposition-au-soleil">
                {/* <Image src={info1} alt="logo" fluid /> */}
                {progress_bar()}
                <div className="imformation-footer">
                  <div className="vstack gap-3">
                    {renderButton(strings.Terrains.book)}
                    <Button
                      variant="green"
                      size="lg"
                      // href={
                      //   checkMap
                      //     ? "https://www.eventbrite.com/myevent?eid=408235602677"
                      //     : ""
                      // }
                      // target="_blank"
                      onClick={handleScheduleVisit}
                      className="w-100 rounded-pill plan-visit-btn cursor-pointer"
                    >
                      {strings.Terrains.scheduleAVisit}
                    </Button>
                    {/* <div>
                        <Button
                          variant="white"
                          size="lg"
                          className="rounded-pill btn-circle ms-3"
                        >
                          <BsChat />
                        </Button>
                      </div> */}
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </Modal.Body>
    </Modal>
  );
};

// const TrackingCodeRenderer = (
//   propartyName,
//   userDataString,
//   currentPath
// ) => {
//   const { setIdentity, setPathPageView, setTrackPageView } = useTrackingCode();

//   if (userDataString) {
//     const userData = JSON.parse(userDataString);
//     const userEmail = userData.email;
//     setIdentity(userEmail);
//     setPathPageView(`${mapName}${currentPath}/${propartyName}`);
//     setTrackPageView();
//   }
//   return;
// };

export default Terrains;
