import React from "react";
import {
  Modal,
  Row,
  Col,
  Container,
  Image,
  Card,
  Button,
  Nav,
} from "react-bootstrap";
import { RWebShare } from "react-web-share";
import {
  horizon,
  forest,
  prestige,
  domaine_pine_hill_large,
  stComeLogo,
  // promesses,
  // favoris,
} from "../../assets/images/index";
import CloseRounded from "@material-ui/icons/CloseRounded";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import ShareIcon from "@material-ui/icons/Share";
// import { MdOutlineLogout } from "react-icons/md";
// import InfoIcon from "@material-ui/icons/Info";
// import { FiLogOut } from "react-icons/fi";
import "./index.scss";
import { colors } from "../../utils/theme";
import { constant } from "../../utils/constant";
import strings from "../../utils/fr.json";

const Menu = (props) => {
  const { show, handleMenu } = props;
  var checkMap = process.env.REACT_APP_MAP === constant.pinehill;

  const data = [
    { name: strings.Forms.email, icon: EmailIcon },
    { name: strings.Menu.telephone, icon: PhoneIcon },
    { name: strings.Menu.shareMap, icon: ShareIcon },
    // { name: strings.Promises.promise, image: promesses },
    // { name: strings.Header.favorite, image: favoris },
    // { title: strings.Header.logout, icon: MdOutlineLogout },
    // {
    //   name: strings.Menu.needHelp,
    //   icon: InfoIcon,
    // },
  ];
  const images = [{ image: horizon }, { image: forest }, { image: prestige }];

  return (
    <div className="about">
      <Modal show={show} fullscreen={true} onHide={handleMenu}>
        <Modal.Header className="border-0 d-none d-lg-block">
          <Container className="text-end">
            <Button variant="white" className="p-2 rounded-circle">
              <CloseRounded onClick={handleMenu} />
            </Button>
          </Container>
        </Modal.Header>
        <Modal.Body className="d-none d-lg-block">
          <div className="h-100 d-flex align-items-center">
            <Container>
              <div className="about-top-desktop">
                <Row>
                  <Col md={5} xl={4} sm={8} xs={6} className="mx-auto">
                    <Image
                      src={checkMap ? domaine_pine_hill_large : stComeLogo}
                      alt="logo"
                      fluid
                    />
                  </Col>
                </Row>
              </div>
              <div className="about-center-desktop">
                <Row>
                  {data.map((item, index) => {
                    if (item.name === strings.Menu.shareMap) {
                      return (
                        <RWebShare
                          key={index.toString()}
                          data={{
                            title: strings.Menu.shareDreamPlace,
                            text: strings.Menu.placeInvest,
                            url: checkMap
                              ? "https://carte.chaletpinehill.com/areas"
                              : "http://carte.domainestcome.com/areas",
                          }}
                        >
                          <Col xxl={3} lg={6}>
                            <Card className="contact-card">
                              <Card.Body>
                                <item.icon className="contact-icon" />
                                <p>{item.name}</p>
                              </Card.Body>
                            </Card>
                          </Col>
                        </RWebShare>
                      );
                    } else if (item.name === strings.Menu.needHelp) {
                      return (
                        <Col key={index.toString()} xxl={3} lg={6}>
                          <Card className="contact-card">
                            <Card.Body>
                              <item.icon className="contact-icon" />
                              <p>{item.name}</p>
                            </Card.Body>
                          </Card>
                        </Col>
                      );
                    } else {
                      return (
                        <Col xxl={3} lg={6} key={index.toString()}>
                          <a
                            style={{ textDecoration: "none" }}
                            className="menu-tv"
                            href={
                              item.name === strings.Menu.telephone
                                ? "tel:514-548-2027"
                                : item.name === strings.Forms.email
                                ? "mailto:anthony@groupegibault.com"
                                : ""
                            }
                          >
                            <Card className="contact-card">
                              <Card.Body>
                                <item.icon className="contact-icon" />
                                <p>{item.name}</p>
                              </Card.Body>
                            </Card>
                          </a>
                        </Col>
                      );
                    }
                  })}
                </Row>
              </div>
              {process.env.REACT_APP_MAP === constant.pinehill ? (
                <div className="about-bottom-desktop">
                  <Row>
                    <Col xl={6} md={8} className="mx-auto">
                      <Row>
                        {images.map((item, index) => {
                          return (
                            <Col xs={4} key={index.toString()}>
                              <Card className="about-card">
                                <Card.Body>
                                  <Image src={item.image} fluid />
                                </Card.Body>
                              </Card>
                            </Col>
                          );
                        })}
                      </Row>
                    </Col>
                  </Row>
                </div>
              ) : null}
            </Container>
          </div>
        </Modal.Body>
        <Modal.Header className="d-lg-none d-block pb-4">
          <Row>
            <Col xs={5} sm={4} md={3}>
              <Image
                src={checkMap ? domaine_pine_hill_large : stComeLogo}
                alt="logo"
                fluid
              />
            </Col>
            <Col xs={7} sm={8} md={9}>
              <div className="h-100 d-flex align-items-center justify-content-end">
                <Button variant="white" className="p-2 rounded-circle">
                  <CloseRounded onClick={handleMenu} />
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body className="d-lg-none">
          <Nav className="mobile-menu h-100 flex-column flex-wrap">
            {data.map((item, index) => {
              if (item.name === strings.Menu.shareMap) {
                return (
                  <RWebShare
                    key={index.toString()}
                    data={{
                      title: strings.Menu.shareDreamPlace,
                      text: strings.Menu.placeInvest,
                      url: checkMap
                        ? "https://carte.chaletpinehill.com/areas"
                        : "http://carte.domainestcome.com/areas",
                    }}
                  >
                    <Nav.Link
                      key={index.toString()}
                      style={{ color: colors.lightBlack }}
                    >
                      <item.icon className="contact-icon" />
                      <span>{item.name}</span>
                    </Nav.Link>
                  </RWebShare>
                );
              } else {
                return (
                  <Nav.Link
                    href={
                      item.name === strings.Menu.telephone
                        ? "tel:514-548-2027"
                        : item.name === strings.Forms.email
                        ? "mailto:anthony@groupegibault.com"
                        : ""
                    }
                    key={index.toString()}
                    style={{ color: colors.lightBlack }}
                  >
                    <item.icon className="contact-icon" />
                    <span>{item.name}</span>
                  </Nav.Link>
                );
              }
            })}
            {/* <Nav.Link
                className="mt-auto"
                style={{ color: colors.lightBlack }}
              >
                <FiLogOut className="contact-icon" />
                <span>{strings.Header.logout}</span>
              </Nav.Link> */}
          </Nav>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Menu;
