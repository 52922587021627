import React from "react";
import "./style.scss";

const Spinner = ({ loading }) => {
  if (loading) {
    return (
      <>
        <div className="spinner-filter"></div>
        <div className="spin-loader">
          <span></span>
        </div>
      </>
    );
  }
  return null;
};

export default Spinner;
