import React from "react";

export const EventDialog = ({ planVisitModal, setPlanVisitModal }) => {
  return (
    <div
      className="event-modal"
      style={{ display: planVisitModal ? "block" : "none" }}
      onClick={() => setPlanVisitModal(false)}
    >
      <div className="event-dialog">
        <div className="event-content">
          <div id="eventbrite-widget-container-408235602677"></div>
        </div>
      </div>
    </div>
  );
};
