import React from "react";
import {
  Modal,
  Row,
  Col,
  Container,
  Image,
  Button,
  Form,
} from "react-bootstrap";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { ref, set } from "firebase/database";
import CountriesData from "../../utils/countryCodes.json";
import { rdb, auth, tables } from "../../services/firebase";
import { domaine_st_come, domian_pine_hill_logo } from "../../assets/images";
import { constant } from "../../utils/constant";
import { BsArrowLeftShort } from "react-icons/bs";
import "./style.scss";
import strings from "../../utils/fr.json";
import { Spinner } from "../index";
import { CREATE_CONTACT, headersKey } from "../../utils/config";

// unused
class RegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullscreen: true,
      offers: [
        {
          title: strings.Forms.offers,
          value: "",
          disabled: true,
        }, //quels_offres_vous_interessent_
        { title: strings.Forms.landOnly, value: strings.Forms.landOnly },
        { title: strings.Forms.turnkey, value: strings.Forms.turnkey },
        {
          title: strings.Forms.selfLand,
          value: strings.Forms.selfLand,
        },
        {
          title: strings.Forms.rentalManage,
          value: strings.Forms.rentalManage,
        },
      ],
      offer: "",
      offerError: "",
      isFormProcess: false,
      isEmpty: "",
      isInvalid: "",
      experience: [
        { title: strings.Forms.experience, value: "", disabled: true }, //experience_immobiliere
        { title: strings.Forms.investment, value: strings.Forms.investment },
        { title: strings.Forms.doors, value: strings.Forms.doors },
        { title: strings.Forms.doorsMore, value: strings.Forms.doorsMore },
      ],
      experienceValue: "",
      experienceError: "",
      inputsData: [
        {
          placeholder: strings.Forms.firstName,
          name: "firstname",
          value: "",
          required: true,
          emptyMsg: strings.Errors.firstNameEmpty,
          hubspot: true,
        }, //firstname
        {
          placeholder: strings.Forms.lastName,
          name: "lastname",
          value: "",
          required: true,
          emptyMsg: strings.Errors.lastNameEmpty,
          hubspot: true,
        }, //lastname
        {
          placeholder: strings.Forms.companyName,
          name: "company",
          value: "",
          hubspot: true,
        }, //company
        {
          placeholder: strings.Forms.email,
          name: "email",
          value: "",
          required: true,
          regex: /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
          errorMsg: strings.Errors.invalidEmail,
          emptyMsg: strings.Errors.emailEmpty,
          hubspot: true,
        }, //email
        {
          placeholder: strings.Forms.telephone,
          name: "phone",
          value: "",
          type: "tel",
          required: true,
          emptyMsg: strings.Errors.mobileEmpty,
          hubspot: true,
          // phoneInpout: true,
        }, //phone
        {
          placeholder: strings.Forms.mobile,
          name: "mobilephone",
          value: "",
          type: "tel",
          // phoneInpout: true,
        }, //mobilephone
        {
          placeholder: strings.Forms.addressPostale,
          name: "address",
          value: "",
          hubspot: true,
        }, //address
        {
          placeholder: strings.Forms.city,
          name: "city",
          value: "",
          required: true,
          emptyMsg: strings.Errors.cityEmptyError,
          hubspot: true,
        }, //city
        {
          placeholder: strings.Forms.state,
          name: "state",
          value: "",
          required: true,
          emptyMsg: strings.Errors.stateEmptyError,
          hubspot: true,
        }, //state
        {
          placeholder: strings.Forms.country,
          name: "country",
          value: "",
          required: true,
          emptyMsg: strings.Errors.countryEmptyError,
          hubspot: true,
        }, //country
        {
          placeholder: strings.Forms.zip,
          name: "zip",
          value: "",
          required: true,
          emptyMsg: strings.Errors.zipEmptyError,
          hubspot: true,
        }, //zip
        {
          placeholder: strings.Forms.profession,
          name: "profession",
          value: "",
          required: true,
          emptyMsg: strings.Errors.professionEmptyError,
        },
      ],
    };
  }

  handleRegister = async () => {
    const {
      isFormProcess,
      isEmpty,
      isInvalid,
      inputsData,
      offer,
      experienceValue,
    } = this.state;
    const { handleLoader } = this.props;
    if (!isFormProcess) {
      this.setState({ isFormProcess: true });
    }
    if (isEmpty) {
      this.setState({ isEmpty: "" });
    }
    if (isInvalid) {
      this.setState({ isInvalid: "" });
    }
    let formData = {};
    let isFormDataValid = false;

    for (let index = 0; index < inputsData.length; index++) {
      let item = inputsData[index];
      let itemValue = inputsData[index].value;
      let inputValue = itemValue?.toString()?.trim();
      if (inputValue) {
        if (item.regex) {
          if (inputValue.match(item.regex)) {
            formData = { ...formData, ...{ [item.name]: inputValue } };
            isFormDataValid = true;
          } else {
            this._handleformError("isInvalid", "isEmpty", index);
            isFormDataValid = false;
            break;
          }
        } else {
          formData = { ...formData, ...{ [item.name]: inputValue } };
          isFormDataValid = true;
        }
      } else {
        if (item.required) {
          this._handleformError("isEmpty", "isInvalid", index);
          isFormDataValid = false;
          break;
        } else {
          formData = { ...formData, ...{ [item.name]: inputValue } };
        }
      }
    }

    if (isFormDataValid) {
      if (!offer) {
        this.setState({
          offerError: strings.Errors.offerSelectError,
          isFormDataValid: false,
        });
      } else if (!experienceValue) {
        this.setState({
          experienceError: strings.Errors.experienceSelectError,
          isFormDataValid: false,
        });
      } else {
        var contactData = await this.createUserInHubspot();
        if (contactData.status === "error") {
          alert(contactData.message);
          handleLoader(false);
        } else {
          formData = { ...formData, offer: offer, experience: experienceValue };
          handleLoader(true);
          await this.register_users(formData);
        }
      }
    }
  };

  createUserInHubspot = async () => {
    const { inputsData } = this.state;
    var checkMap = process.env.REACT_APP_MAP === constant.pinehill;

    var propertiesData = inputsData
      .filter((d) => d.hubspot)
      .map((d) => {
        return { property: d.name, value: d.value };
      });
    var contactData = await fetch(CREATE_CONTACT, {
      method: "post",
      headers: headersKey,
      body: JSON.stringify({
        properties: [
          ...propertiesData,
          {
            property: "origine_du_lead",
            value: checkMap
              ? "Carte interactive" //Carte interactive - PH
              : "Carte interactive - SC",
          },
        ],
      }),
    });
    return contactData.json();
  };

  register_users = async (obj) => {
    const { handleLoader } = this.props;
    try {
      const res = await createUserWithEmailAndPassword(
        auth,
        obj.email,
        obj.phone.replace(/[^0-9]/gi, "")
      );
      const user = res.user;
      localStorage.setItem("@userId", user.uid);
      localStorage.setItem("@login", true);
      await set(ref(rdb, tables.users + user.uid), {
        uid: user.uid,
        ...obj,
      });
      handleLoader(false);
      this._clearForm();
      this.props.toggleRegisterForm();
    } catch (err) {
      handleLoader(false);
      console.error(err);
      if (err.code === "auth/email-already-in-use") {
        alert(strings.Errors.emailAlreadyInUse);
      }
      if (err.code === "auth/invalid-email") {
        alert(strings.Errors.invalidEmail);
      }
      return "error";
    }
  };

  _clearForm = () => {
    let inputsData = this.state.inputsData;
    for (let index = 0; index < inputsData.length; index++) {
      inputsData[index] = { ...inputsData[index], value: "" };
    }
    this.setState({
      isFormProcess: false,
      inputsData: inputsData,
      isEmpty: "",
      isInvalid: "",
    });
  };

  _handleformError = (type, clearType, index) => {
    this.setState({
      [type]: index,
      [clearType]: "",
    });
  };

  handleInput = (text, item, index) => {
    this.dataChecker(text.target.value, item);
    let data = this.state.inputsData;
    data[index].value = text.target.value;
    this.setState({ inputData: data });
  };

  dataChecker = (text, item) => {
    if (this.state.isFormProcess) {
      if (text && text.match(item.regex)) {
        this.setState({ isEmpty: "", isInvalid: "" });
      }
    }
  };

  renderError = (item, index) => {
    const { isEmpty, isInvalid, isFormProcess } = this.state;

    if (isFormProcess && isInvalid === index) {
      return <span className="error-message">{item.errorMsg}</span>;
    }
    if (isFormProcess && isEmpty === index) {
      return <span className="error-message">{item.emptyMsg}</span>;
    }
    return null;
  };

  renderDropdown = (stateArray, key, valueStore, type = "") => {
    const { offerError, experienceError } = this.state;
    return (
      <Form.Group className="form-group">
        <Form.Select
          className="form-control px-4"
          value={valueStore}
          name={key}
          onChange={(value) => {
            this.setState({
              [key]: value.target.value,
              offerError: "",
              experienceError: "",
            });
          }}
        >
          {stateArray.map((item, index) => {
            return (
              <option
                disabled={item.disabled}
                selected={item.disabled}
                value={item.value}
                key={index.toString()}
              >
                {item.title}
              </option>
            );
          })}
        </Form.Select>
        {experienceError && type === "" && (
          <span className="error-message">{experienceError}</span>
        )}
        {offerError && type === "offer" && (
          <span className="error-message">{offerError}</span>
        )}
      </Form.Group>
    );
  };

  render() {
    const { show, toggleRegisterForm, handleRegisterForm, loading } =
      this.props;
    const { offers, experience, inputsData, experienceValue, offer } =
      this.state;
    var checkMap = process.env.REACT_APP_MAP === constant.pinehill;
    return (
      <>
        <Spinner loading={loading} />
        <Modal
          className="register-form"
          show={show}
          fullscreen={this.state.fullscreen}
          onHide={toggleRegisterForm}
        >
          <Modal.Header>
            <Container className="py-sm-5 py-3">
              <Row>
                <Col sm={4} xs={6} className="d-flex align-items-center">
                  <Button
                    variant="link"
                    className="back-btn"
                    onClick={handleRegisterForm}
                  >
                    <BsArrowLeftShort />
                  </Button>
                </Col>
                <Col sm={4} xs={6}>
                  <Image
                    src={checkMap ? domian_pine_hill_logo : domaine_st_come}
                    alt="logo"
                    fluid
                  />
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <h6 className="form-title">{strings.Forms.signup}</h6>
              <Row>
                {inputsData.map((item, index) => {
                  return (
                    <Col
                      xl={3}
                      lg={4}
                      sm={6}
                      xs={10}
                      className="mx-sm-0 mx-auto"
                      key={index.toString()}
                    >
                      {item.phoneInpout ? (
                        <Form.Group className="form-group">
                          <PhoneInput
                            className="custom-form-control form-control"
                            placeholder={item.placeholder}
                            value={item.value}
                            onChange={(text) => {
                              this.dataChecker(text, item);
                              let data = this.state.inputsData;
                              data[index].value = text;
                              this.setState({ inputData: data });
                            }}
                            international={true}
                            onCountryChange={(value) => {
                              let data = this.state.inputsData;
                              data[9].value = CountriesData.filter(
                                (d) => d.code === value
                              )?.[0]?.name;
                              this.setState({
                                inputsData: data,
                              });
                            }}
                          />
                          {this.renderError(item, index)}
                        </Form.Group>
                      ) : (
                        <Form.Group
                          key={index.toString()}
                          className="form-group"
                        >
                          <Form.Control
                            // type={item.type ? item.type : "text"}
                            // maxLength={item.type === "tel" ? 10 : null}
                            placeholder={item.placeholder}
                            value={item.value}
                            onChange={(txt) =>
                              this.handleInput(txt, item, index)
                            }
                          />
                          {this.renderError(item, index)}
                        </Form.Group>
                      )}
                    </Col>
                  );
                })}
                <Col xl={3} lg={4} sm={6} xs={10} className="mx-sm-0 mx-auto">
                  {this.renderDropdown(offers, "offer", offer, "offer")}
                </Col>
                <Col xl={3} lg={4} sm={6} xs={10} className="mx-sm-0 mx-auto">
                  {this.renderDropdown(
                    experience,
                    "experienceValue",
                    experienceValue
                  )}
                </Col>
              </Row>
              <Button
                className="button-second"
                variant="green"
                onClick={() => this.handleRegister()}
              >
                {strings.Forms.signup}
              </Button>
              <p
                className="content"
                dangerouslySetInnerHTML={{
                  __html: strings.Forms.agreeTerms,
                }}
              />
              <p className="button-third">
                {strings.Forms.loginCaps}{" "}
                <span
                  onClick={() => {
                    this._clearForm();
                    handleRegisterForm();
                  }}
                >
                  {strings.Forms.goToLogin}
                </span>
              </p>
            </Container>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default RegisterForm;
