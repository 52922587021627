import React from "react";
import { Image } from "react-bootstrap";
import "./index.scss";
import { loader, loader_stcome } from "../../assets/images";
import { constant } from "../../utils/constant";
class Loader extends React.Component {
  render() {
    var checkMap = process.env.REACT_APP_MAP === constant.pinehill;
    return (
      <div className="backdrop">
        <Image
          className="center"
          src={checkMap ? loader : loader_stcome}
          fluid
        />
      </div>
    );
  }
}

export default Loader;
