export const C01 = ({ width, height }) => {
  return (
    <svg
      width={width || "33"}
      height={height || "33"}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0166016 15.8108L12.1938 0.546875L32.3625 16.8571L20.0153 32.121L0.0166016 15.8108Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C02 = ({ width, height }) => {
  return (
    <svg
      width={width || "29"}
      height={height || "22"}
      viewBox="0 0 29 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.14737 0.0250619L0.350586 19.4307L26.229 21.8268L28.0258 2.42118L2.14737 0.0250619Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C03 = ({ width, height }) => {
  return (
    <svg
      width={width || "33"}
      height={height || "32"}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.222656 15.7643L12.3997 0.487305L32.7646 16.7975L20.5745 31.8783L0.222656 15.7643Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C04 = ({ width, height }) => {
  return (
    <svg
      width={width || "31"}
      height={height || "33"}
      viewBox="0 0 31 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.135742 11.3764L16.433 0.625L30.7027 22.4156L14.4187 32.9708L0.135742 11.3764Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C05 = ({ width, height }) => {
  return (
    <svg
      width={width || "24"}
      height={height || "30"}
      viewBox="0 0 24 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.95578 29.0749L0.476562 3.37348L19.6904 0.862305L23.1827 26.5505L3.95578 29.0749Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C06 = ({ width, height }) => {
  return (
    <svg
      width={width || "34"}
      height={height || "32"}
      viewBox="0 0 34 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.725586 15.6404L12.9027 0.55957L33.2676 16.6735L21.0905 31.9506L0.725586 15.6404Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C07 = ({ width, height }) => {
  return (
    <svg
      width={width || "30"}
      height={height || "24"}
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.68177 0.00585938L29.1608 4.55753L25.9955 23.5622L0.320312 19.2068L3.68177 0.00585938Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C08 = ({ width, height }) => {
  return (
    <svg
      width={width || "31"}
      height={height || "34"}
      viewBox="0 0 31 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.253 0.786133L30.9033 11.0014L17.1436 33.145L0.689453 22.9168L14.253 0.786133Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C09 = ({ width, height }) => {
  return (
    <svg
      width={width || "29"}
      height={height || "25"}
      viewBox="0 0 29 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.24922 0.467773L28.9244 4.82322L25.563 24.037L0.0839844 19.6816L3.24922 0.467773Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C10 = ({ width, height }) => {
  return (
    <svg
      width={width || "30"}
      height={height || "24"}
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.1392 0.397461L29.6182 4.75306L26.2567 23.9669L0.764648 19.6114L4.1392 0.397461Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C11 = ({ width, height }) => {
  return (
    <svg
      width={width || "31"}
      height={height || "25"}
      viewBox="0 0 31 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.989258 19.755L4.35072 0.541016L30.0259 5.09268L26.6645 24.1104L0.989258 19.755Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C12 = ({ width, height }) => {
  return (
    <svg
      width={width || "30"}
      height={height || "25"}
      viewBox="0 0 30 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.326172 5.61866L25.8183 0.75293L29.5198 19.7838L4.01462 24.8456L0.326172 5.61866Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C13 = ({ width, height }) => {
  return (
    <svg
      width={width || "29"}
      height={height || "25"}
      viewBox="0 0 29 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.103516 20.0681L3.46498 0.867188L28.9571 5.22279L25.7787 24.4235L0.103516 20.0681Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C14 = ({ width, height }) => {
  return (
    <svg
      width={width || "28"}
      height={height || "23"}
      viewBox="0 0 28 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.23559 0.808594L27.9108 3.39833L26.1189 22.8084L0.234375 20.2186L2.23559 0.808594Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C15 = ({ width, height }) => {
  return (
    <svg
      width={width || "29"}
      height={height || "23"}
      viewBox="0 0 29 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.905273 19.8027L3.08954 0.588867L28.974 3.38792L26.7766 22.7849L0.905273 19.8027Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C16 = ({ width, height }) => {
  return (
    <svg
      width={width || "30"}
      height={height || "32"}
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.714844 8.7506L18.1762 0.157227L29.7123 23.3211L12.2511 31.9145L0.714844 8.7506Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C17 = ({ width, height }) => {
  return (
    <svg
      width={width || "33"}
      height={height || "35"}
      viewBox="0 0 33 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.575195 13.0347L16.6237 0.949219L32.2015 22.1774L16.6237 34.2499L0.575195 13.0347Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C18 = ({ width, height }) => {
  return (
    <svg
      width={width || "28"}
      height={height || "32"}
      viewBox="0 0 28 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0361328 7.3441L18.2821 0.516602L27.2548 24.8446L9.00871 31.6721L0.0361328 7.3441Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C19 = ({ width, height }) => {
  return (
    <svg
      width={width || "24"}
      height={height || "30"}
      viewBox="0 0 24 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.298828 4.05924L19.5128 0.946289L23.5936 26.6477L4.17033 29.7476L0.298828 4.05924Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C20 = ({ width, height }) => {
  return (
    <svg
      width={width || "32"}
      height={height || "33"}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.233398 12.3304L15.7458 0.584961L31.5851 21.0022L16.0858 32.9438L0.233398 12.3304Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C21 = ({ width, height }) => {
  return (
    <svg
      width={width || "30"}
      height={height || "33"}
      viewBox="0 0 30 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0185547 9.68287L17.1006 0.500977L29.4083 23.2595L12.1434 32.4414L0.0185547 9.68287Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C22 = ({ width, height }) => {
  return (
    <svg
      width={width || "30"}
      height={height || "33"}
      viewBox="0 0 30 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.569336 9.93189L17.6383 0.75L29.9591 23.7047L12.681 32.8866L0.569336 9.93189Z"
        fill="#985735"
      />
    </svg>
  );
};
// export const C23 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "19"}
//       height={height || "23"}
//       viewBox="0 0 19 23"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M0.629883 2.88L15.4598 0.810059L18.1399 20.45L3.29993 22.52L0.629883 2.88Z"
//         fill="#9A5735"
//       />
//     </svg>
//   );
// };
export const C24 = ({ width, height }) => {
  return (
    <svg
      width={width || "24"}
      height={height || "29"}
      viewBox="0 0 24 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.620117 2.83067L20.0434 0.319336L23.5225 26.0077L4.30865 28.5321L0.620117 2.83067Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C25 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "28"}
      viewBox="0 0 22 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.679688 1.46289L20.0897 0.128906L21.8163 26.0133L2.40612 27.3605L0.679688 1.46289Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C26 = ({ width, height }) => {
  return (
    <svg
      width={width || "28"}
      height={height || "32"}
      viewBox="0 0 28 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2627 31.8362L0.0429688 25.3357L8.89787 0.850586L27.1307 7.54733L18.2627 31.8362Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C27 = ({ width, height }) => {
  return (
    <svg
      width={width || "28"}
      height={height || "32"}
      viewBox="0 0 28 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.06233 0.227539L27.4783 6.92428L18.4274 31.213L0.194336 24.5163L9.06233 0.227539Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C28 = ({ width, height }) => {
  return (
    <svg
      width={width || "28"}
      height={height || "32"}
      viewBox="0 0 28 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.13737 0.527344L27.3573 7.22424L18.4893 31.5262L0.269531 24.8293L9.13737 0.527344Z"
        fill="#985735"
      />
    </svg>
  );
};
// export const C29 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "20"}
//       height={height || "24"}
//       viewBox="0 0 20 24"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M5.73987 0.490234L19.9698 4.57031L14.3899 23.7502L0.159912 19.5203L5.73987 0.490234Z"
//         fill="#9A5735"
//       />
//     </svg>
//   );
// };
export const C30 = ({ width, height }) => {
  return (
    <svg
      width={width || "33"}
      height={height || "32"}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.634766 14.6491L22.4254 0.758789L32.9938 17.252L11.0069 31.1295L0.634766 14.6491Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C31 = ({ width, height }) => {
  return (
    <svg
      width={width || "34"}
      height={height || "32"}
      viewBox="0 0 34 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6829 31.3465L33.2964 15.8863L21.5509 0.177734L0.9375 15.8343L12.6829 31.3465Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C32 = ({ width, height }) => {
  return (
    <svg
      width={width || "32"}
      height={height || "29"}
      viewBox="0 0 32 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.152344 10.2171L24.4803 0.838867L31.3209 19.0982L7.18916 28.4631L0.152344 10.2171Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C33 = ({ width, height }) => {
  return (
    <svg
      width={width || "34"}
      height={height || "33"}
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1069 0.869141L33.4717 16.9833L21.2947 32.2601L0.929688 16.1462L13.1069 0.869141Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C34 = ({ width, height }) => {
  return (
    <svg
      width={width || "23"}
      height={height || "29"}
      viewBox="0 0 23 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.166016 3.04076L19.5892 0.921875L22.4797 26.6102L3.06964 28.7291L0.166016 3.04076Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C35 = ({ width, height }) => {
  return (
    <svg
      width={width || "33"}
      height={height || "29"}
      viewBox="0 0 33 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.80462 0.0339684L0.540039 17.6689L24.0493 28.6865L32.3139 11.0515L8.80462 0.0339684Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C36 = ({ width, height }) => {
  return (
    <svg
      width={width || "23"}
      height={height || "29"}
      viewBox="0 0 23 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.28418 2.50951L19.4982 0.390625L22.4018 26.2752L3.17488 28.3941L0.28418 2.50951Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C37 = ({ width, height }) => {
  return (
    <svg
      width={width || "29"}
      height={height || "32"}
      viewBox="0 0 29 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2852 0.395508L28.322 7.28847L19.0616 31.5773L0.841797 24.4882L10.2852 0.395508Z"
        fill="#985735"
      />
    </svg>
  );
};
// export const C38 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "23"}
//       height={height || "25"}
//       viewBox="0 0 23 25"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M8.60986 0.0800781L22.2399 5.80017L14.7198 24.0801L0.929932 18.3601L8.60986 0.0800781Z"
//         fill="#9A5735"
//       />
//     </svg>
//   );
// };
export const C39 = ({ width, height }) => {
  return (
    <svg
      width={width || "31"}
      height={height || "34"}
      viewBox="0 0 31 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7756 0.868164L30.6221 10.5078L17.6473 33.0308L0.800781 23.4043L13.7756 0.868164Z"
        fill="#985735"
      />
    </svg>
  );
};
// export const C40 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "21"}
//       height={height || "16"}
//       viewBox="0 0 21 16"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M0.650024 0.420166L20.4301 0.450073L20.41 15.4401L0.469971 15.2601L0.650024 0.420166Z"
//         fill="#9A5735"
//       />
//     </svg>
//   );
// };
// export const C41 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "22"}
//       height={height || "24"}
//       viewBox="0 0 22 24"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M13.8971 0.192439L0.0959473 5.56494L7.27505 24.0069L21.0762 18.6344L13.8971 0.192439Z"
//         fill="#9A5735"
//       />
//     </svg>
//   );
// };
export const C42 = ({ width, height }) => {
  return (
    <svg
      width={width || "34"}
      height={height || "32"}
      viewBox="0 0 34 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.856445 16.9484L11.8695 0.69043L33.4115 15.2351L22.3986 31.2967L0.856445 16.9484Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C43 = ({ width, height }) => {
  return (
    <svg
      width={width || "23"}
      height={height || "29"}
      viewBox="0 0 23 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.509766 2.40215L19.9329 0.479492L22.6274 26.3639L3.21717 28.2996L0.509766 2.40215Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C44 = ({ width, height }) => {
  return (
    <svg
      width={width || "31"}
      height={height || "34"}
      viewBox="0 0 31 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7205 0.868164L30.5539 10.5078L17.5789 33.0308L0.732422 23.4043L13.7205 0.868164Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C45 = ({ width, height }) => {
  return (
    <svg
      width={width || "31"}
      height={height || "33"}
      viewBox="0 0 31 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.125 10.0165L16.9977 0.246094L30.1035 22.6123L13.4139 32.3958L0.125 10.0165Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C46 = ({ width, height }) => {
  return (
    <svg
      width={width || "31"}
      height={height || "33"}
      viewBox="0 0 31 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.53418 10.3944L17.4199 0.624023L30.5126 22.9899L13.8361 32.7734L0.53418 10.3944Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C47 = ({ width, height }) => {
  return (
    <svg
      width={width || "31"}
      height={height || "34"}
      viewBox="0 0 31 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0654297 10.6612L16.9512 0.890625L30.0439 23.2567L13.3674 33.0402L0.0654297 10.6612Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C48 = ({ width, height }) => {
  return (
    <svg
      width={width || "30"}
      height={height || "33"}
      viewBox="0 0 30 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0283203 10.1753L16.9009 0.391602L30.0067 22.7709L13.121 32.5413L0.0283203 10.1753Z"
        fill="#985735"
      />
    </svg>
  );
};
// export const C49 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "26"}
//       height={height || "23"}
//       viewBox="0 0 26 23"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M0.659912 9.85999L18.0699 0.290039L25.09 13.34L7.68994 22.91L0.659912 9.85999Z"
//         fill="#9A5735"
//       />
//     </svg>
//   );
// };
export const C50 = ({ width, height }) => {
  return (
    <svg
      width={width || "34"}
      height={height || "32"}
      viewBox="0 0 34 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.742188 16.0091L21.9442 0.941406L33.088 16.8462L11.886 31.9008L0.742188 16.0091Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C51 = ({ width, height }) => {
  return (
    <svg
      width={width || "29"}
      height={height || "23"}
      viewBox="0 0 29 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0234375 3.67528L25.7117 0L28.4192 19.2138L2.73092 22.9024L0.0234375 3.67528Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C52 = ({ width, height }) => {
  return (
    <svg
      width={width || "31"}
      height={height || "26"}
      viewBox="0 0 31 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.475586 6.73774L25.7715 0.904297L30.0616 19.9221L4.75255 25.7686L0.475586 6.73774Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C53 = ({ width, height }) => {
  return (
    <svg
      width={width || "32"}
      height={height || "27"}
      viewBox="0 0 32 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.93969 0.77832L31.0262 8.07683L25.5067 26.689L0.616211 19.5869L5.93969 0.77832Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C54 = ({ width, height }) => {
  return (
    <svg
      width={width || "27"}
      height={height || "20"}
      viewBox="0 0 27 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.470703 0.251835L26.5515 0.0947266L26.7084 19.5049L0.627652 19.6617L0.470703 0.251835Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C55 = ({ width, height }) => {
  return (
    <svg
      width={width || "31"}
      height={height || "26"}
      viewBox="0 0 31 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.583008 6.31906L25.8921 0.0800781L30.3652 19.1106L5.2656 25.1404L0.583008 6.31906Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C56 = ({ width, height }) => {
  return (
    <svg
      width={width || "33"}
      height={height || "33"}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.368164 20.2432L17.4633 0.663086L32.1385 13.4288L15.0565 33.0089L0.368164 20.2432Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C57 = ({ width, height }) => {
  return (
    <svg
      width={width || "33"}
      height={height || "31"}
      viewBox="0 0 33 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.422852 14.2776L22.4095 0.400391L32.7686 16.8805L10.7949 30.7711L0.422852 14.2776Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C58 = ({ width, height }) => {
  return (
    <svg
      width={width || "34"}
      height={height || "33"}
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9558 0.863281L33.3207 17.1735L21.1305 32.2543L0.765625 16.1401L12.9558 0.863281Z"
        fill="#985735"
      />
    </svg>
  );
};
// export const C59 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "22"}
//       height={height || "18"}
//       viewBox="0 0 22 18"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M0.119873 3.0802L19.7599 0.260254L21.8399 14.9602L2.18988 17.7703L0.119873 3.0802Z"
//         fill="#9A5735"
//       />
//     </svg>
//   );
// };
export const C60 = ({ width, height }) => {
  return (
    <svg
      width={width || "33"}
      height={height || "30"}
      viewBox="0 0 33 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.91545 0.355469L32.0401 11.9571L23.1852 29.3921L0.0605469 17.7905L8.91545 0.355469Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C61 = ({ width, height }) => {
  return (
    <svg
      width={width || "30"}
      height={height || "33"}
      viewBox="0 0 30 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7889 0.624023L29.4202 8.69402L18.7865 32.4073L0.958984 24.3373L11.7889 0.624023Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C62 = ({ width, height }) => {
  return (
    <svg
      width={width || "29"}
      height={height || "33"}
      viewBox="0 0 29 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8636 0.321289L28.495 8.39145L17.8743 32.1047L0.046875 24.0346L10.8636 0.321289Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C63 = ({ width, height }) => {
  return (
    <svg
      width={width || "33"}
      height={height || "33"}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.948242 20.814L16.8661 0.25293L32.1431 12.2339L16.2253 32.7949L0.948242 20.814Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C64 = ({ width, height }) => {
  return (
    <svg
      width={width || "31"}
      height={height || "34"}
      viewBox="0 0 31 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8497 0.826172L30.5001 10.8452L17.1327 33.185L0.482422 23.1529L13.8497 0.826172Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C65 = ({ width, height }) => {
  return (
    <svg
      width={width || "37"}
      height={height || "29"}
      viewBox="0 0 37 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.9611 4.09631L33.8184 29.0003L0.414429 25.131L3.42376 0.0123062L36.9611 4.09631Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C66 = ({ width, height }) => {
  return (
    <svg
      width={width || "42"}
      height={height || "43"}
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.1601 0.616379L41.3548 17.9084L18.4388 42.519L-0.00787354 25.2057L23.1601 0.616379Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C67 = ({ width, height }) => {
  return (
    <svg
      width={width || "30"}
      height={height || "37"}
      viewBox="0 0 30 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.78345 0.0139046L29.7061 2.99791L26.0514 36.4259L0.912781 33.5766L4.78345 0.0139046Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C68 = ({ width, height }) => {
  return (
    <svg
      width={width || "40"}
      height={height || "34"}
      viewBox="0 0 40 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.459 0.10627L39.767 24.4023L7.30704 33.1849L0.795044 8.73827L33.459 0.10627Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C69 = ({ width, height }) => {
  return (
    <svg
      width={width || "40"}
      height={height || "34"}
      viewBox="0 0 40 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.112 0.213326L39.42 24.5093L6.96 33.292L0.447998 8.84533L33.112 0.213326Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C70 = ({ width, height }) => {
  return (
    <svg
      width={width || "36"}
      height={height || "29"}
      viewBox="0 0 36 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.9748 3.73376L33.6268 28.7244L0.11615 25.9218L2.32415 0.719093L35.9748 3.73376Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C71 = ({ width, height }) => {
  return (
    <svg
      width={width || "42"}
      height={height || "38"}
      viewBox="0 0 42 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.7904 15.2472L30.5851 37.7086L0.342407 23.0046L11.4944 0.295223L41.7904 15.2472Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C72 = ({ width, height }) => {
  return (
    <svg
      width={width || "43"}
      height={height || "38"}
      viewBox="0 0 43 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.1835 15.4663L30.9781 37.9277L0.735474 23.2237L11.8875 0.514339L42.1835 15.4663Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C73 = ({ width, height }) => {
  return (
    <svg
      width={width || "39"}
      height={height || "31"}
      viewBox="0 0 39 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.1728 0.282562L38.0848 25.0772L4.92348 30.6572L0.823486 5.6919L34.1728 0.282562Z"
        fill="#985735"
      />
    </svg>
  );
};

export const M01 = ({ width, height }) => {
  return (
    <svg
      width={width || "23"}
      height={height || "22"}
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.58968 21.2353L0.951172 10.824L15.0902 0.661133L22.5194 11.2556L8.58968 21.2353Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M02 = ({ width, height }) => {
  return (
    <svg
      width={width || "21"}
      height={height || "18"}
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2102 17.8633L0.416016 13.6517L3.59442 0.807617L20.3755 4.92772L17.2102 17.8633Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M03 = ({ width, height }) => {
  return (
    <svg
      width={width || "17"}
      height={height || "20"}
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.43888 0.456055L16.1915 2.44418L13.6017 19.4998L0.666016 17.5117L3.43888 0.456055Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M04 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "17"}
//       height={height || "17"}
//       viewBox="0 0 17 17"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M0.169922 6.27002L8.12994 0.439941L16.0599 11.1L8.09991 16.9301L0.169922 6.27002Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const M05 = ({ width, height }) => {
  return (
    <svg
      width={width || "21"}
      height={height || "19"}
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7114 18.3077L0.44043 12.3957L4.97901 0.257812L21.0015 5.84282L16.7114 18.3077Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M06 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "23"}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.175781 9.16708L10.1947 0.756836L21.1554 14.1112L11.3326 22.3251L0.175781 9.16708Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M07 = ({ width, height }) => {
  return (
    <svg
      width={width || "16"}
      height={height || "20"}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.83644 0.821289L15.7722 2.65246L13.1955 19.8521L0.259766 17.8771L2.83644 0.821289Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M08 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "16"}
//       height={height || "18"}
//       viewBox="0 0 16 18"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M9.17004 17.0302L0.780029 11.9202L7.55002 0.680176L15.8 5.62012L9.17004 17.0302Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const M09 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "22"}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.365234 8.89644L9.99179 0.09375L21.7373 12.6634L12.3069 21.4659L0.365234 8.89644Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M10 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "15"}
//       height={height || "11"}
//       viewBox="0 0 15 11"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M1.14987 10.6998L0.859863 0.809937L14.0599 0.379883L14.3399 10.2699L1.14987 10.6998Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const M11 = ({ width, height }) => {
  return (
    <svg
      width={width || "19"}
      height={height || "22"}
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.473633 5.07261L12.6376 0.769531L18.4973 17.0535L6.13711 21.3568L0.473633 5.07261Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M12 = ({ width, height }) => {
  return (
    <svg
      width={width || "19"}
      height={height || "22"}
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.387695 4.89223L12.7479 0.981445L18.2021 17.4617L5.85495 21.3725L0.387695 4.89223Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M13 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "15"}
//       height={height || "17"}
//       viewBox="0 0 15 17"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M9.76976 0.194928L0.470215 3.92578L5.40372 16.2231L14.7033 12.4922L9.76976 0.194928Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const M14 = ({ width, height }) => {
  return (
    <svg
      width={width || "16"}
      height={height || "19"}
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.648438 1.35895L13.4795 0.0117188L15.533 17.0805L2.58427 18.6239L0.648438 1.35895Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M15 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "16"}
//       height={height || "13"}
//       viewBox="0 0 16 13"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M13.6099 0.350098L15.6899 10.1001L2.66986 12.7701L0.609863 3.02014L13.6099 0.350098Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
// export const M16 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "12"}
//       height={height || "16"}
//       viewBox="0 0 12 16"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M1.96997 0.620117L11.86 2.13013L9.89001 15.3301L0 13.8102L1.96997 0.620117Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const M17 = ({ width, height }) => {
  return (
    <svg
      width={width || "16"}
      height={height || "20"}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.22019 0.349609L15.9598 2.33773L13.3831 19.3936L0.643555 17.6017L3.22019 0.349609Z"
        fill="#857700"
      />
    </svg>
    // <svg
    //   width={width || "22"}
    //   height={height || "22"}
    //   viewBox="0 0 22 22"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M13.5083 21.9338L-0.00292969 11.1301L8.05414 0.954102L21.5654 11.7578L13.5083 21.9338Z"
    //     fill="#857700"
    //   />
    // </svg>
  );
};
export const M18 = ({ width, height }) => {
  return (
    <svg
      width={width || "17"}
      height={height || "20"}
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.1607 0.242188L16.0963 2.21722L13.5197 19.286L0.583984 17.4941L3.1607 0.242188Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M19 = ({ width, height }) => {
  return (
    <svg
      width={width || "16"}
      height={height || "20"}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.67527 0.799805L15.611 2.77483L13.0343 19.8305L0.0986328 17.8425L2.67527 0.799805Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M20 = ({ width, height }) => {
  return (
    <svg
      width={width || "16"}
      height={height || "20"}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.1402 0.929688L15.8797 2.90472L13.303 19.9605L0.563477 17.9724L3.1402 0.929688Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M21 = ({ width, height }) => {
  return (
    <svg
      width={width || "16"}
      height={height || "20"}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.22019 0.349609L15.9598 2.33773L13.3831 19.3936L0.643555 17.6017L3.22019 0.349609Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M22 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "19"}
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.508789 6.05845L16.7928 0.211914L21.0829 12.3758L4.79885 18.2225L0.508789 6.05845Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M23 = ({ width, height }) => {
  return (
    <svg
      width={width || "23"}
      height={height || "20"}
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.96875 8.34809L16.4681 0.526367L22.3408 12.1019L6.82846 19.9235L0.96875 8.34809Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M24 = ({ width, height }) => {
  return (
    <svg
      width={width || "23"}
      height={height || "21"}
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.523438 9.54844L15.0417 0.157227L22.0917 10.961L7.56026 20.3391L0.523438 9.54844Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M25 = ({ width, height }) => {
  return (
    <svg
      width={width || "19"}
      height={height || "22"}
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.65976 0.667969L18.8107 5.38968L12.5064 21.4644L0.355469 16.7296L6.65976 0.667969Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M26 = ({ width, height }) => {
  return (
    <svg
      width={width || "19"}
      height={height || "22"}
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.53866 0.267578L18.6896 5.18551L12.3854 21.2604L0.234375 16.3293L6.53866 0.267578Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M27 = ({ width, height }) => {
  return (
    <svg
      width={width || "23"}
      height={height || "21"}
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.948 20.4738L0.867188 11.8281L7.35469 0.658203L22.6316 9.12065L15.948 20.4738Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M28 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "23"}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.477539 9.36021L10.1041 0.753906L21.6403 13.9119L11.8306 22.3221L0.477539 9.36021Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M29 = ({ width, height }) => {
  return (
    <svg
      width={width || "20"}
      height={height || "22"}
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5009 16.6348L7.53304 21.5266L0.888672 5.66104L12.8564 0.546875L19.5009 16.6348Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M30 = ({ width, height }) => {
  return (
    <svg
      width={width || "19"}
      height={height || "22"}
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3467 0.37793L18.9911 16.2697L7.02328 21.3445L0.378906 5.25659L12.3467 0.37793Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M31 = ({ width, height }) => {
  return (
    <svg
      width={width || "20"}
      height={height || "22"}
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0206 0.972909L0.250977 6.24512L7.28235 21.9419L19.052 16.6697L12.0206 0.972909Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M32 = ({ width, height }) => {
  return (
    <svg
      width={width || "16"}
      height={height || "20"}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.85392 -0.00488281L15.5934 1.97015L13.0168 19.026L0.0810547 17.0509L2.85392 -0.00488281Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M33 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "23"}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8105 0.770508L21.575 14.3209L11.3599 22.3387L0.608398 8.78814L10.8105 0.770508Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M34 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "17"}
//       height={height || "18"}
//       viewBox="0 0 17 18"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M8.56995 0.390137L16.3599 6.32007L8.27997 17.0302L0.459961 11.0001L8.56995 0.390137Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const M35 = ({ width, height }) => {
  return (
    <svg
      width={width || "16"}
      height={height || "20"}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.626953 2.47022L13.5757 0.926758L15.5115 18.1786L2.56271 19.7221L0.626953 2.47022Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M36 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "13"}
//       height={height || "16"}
//       viewBox="0 0 13 16"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M0.77002 1.8999L10.67 0.859863L12.14 13.9099L2.25 15.0898L0.77002 1.8999Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
// export const M37 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "16"}
//       height={height || "17"}
//       viewBox="0 0 16 17"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M0.349854 5.61011L8.4599 0.0800781L15.9399 11.0302L7.67987 16.5701L0.349854 5.61011Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const M38 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "23"}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4689 0.604492L21.6258 13.7625L11.6199 22.1727L0.65918 9.01457L10.4689 0.604492Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M39 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "22"}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.69591 0.889648L21.5995 11.314L13.7387 21.6861L0.03125 11.2748L7.69591 0.889648Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M40 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "17"}
//       height={height || "17"}
//       viewBox="0 0 17 17"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M7.49559 0.22328L0.291504 6.95532L9.26978 16.5632L16.4739 9.83111L7.49559 0.22328Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const M41 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "20"}
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.61988 0.957031L21.3023 8.04622L15.992 20.0008L0.30957 12.9118L5.61988 0.957031Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M42 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "23"}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.986328 8.00447L11.5808 0.575195L21.5605 14.8974L10.7567 22.3396L0.986328 8.00447Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M43 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "17"}
//       height={height || "18"}
//       viewBox="0 0 17 18"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M0.659912 6.07996L8.91992 0.550049L16.3899 11.36L8.13992 17.04L0.659912 6.07996Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const M44 = ({ width, height }) => {
  return (
    <svg
      width={width || "21"}
      height={height || "22"}
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0479 0.106445L20.8183 14.2323L10.0277 21.6747L0.244141 7.33933L11.0479 0.106445Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M45 = ({ width, height }) => {
  return (
    <svg
      width={width || "18"}
      height={height || "21"}
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.105469 4.49288L12.4657 0.77832L17.3444 17.2586L4.98417 20.96L0.105469 4.49288Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M46 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "19"}
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.573242 6.58768L16.6612 0.544922L21.3436 12.5128L5.25575 18.7648L0.573242 6.58768Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M47 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "17"}
      viewBox="0 0 22 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.912109 4.31456L17.7848 0.0244141L21.0939 12.5809L4.23433 16.858L0.912109 4.31456Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M48 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "18"}
//       height={height || "17"}
//       viewBox="0 0 18 17"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M0.609863 8.04004L11.5599 0.560059L17.0999 8.82007L6.13989 16.29L0.609863 8.04004Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const M49 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "22"}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.253906 13.6225L11.2539 0.307617L21.2335 8.36452L10.2337 21.8758L0.253906 13.6225Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M50 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "23"}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3156 22.6285L0.139648 14.3752L11.1395 0.864258L21.1324 9.11723L10.3156 22.6285Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M51 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "23"}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5723 0.46582L21.7613 8.71879L10.7483 22.034L0.768555 13.9768L11.5723 0.46582Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M52 = ({ width, height }) => {
  return (
    <svg
      width={width || "20"}
      height={height || "17"}
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.166992 3.78348L17.0396 0.291016L19.7602 13.0436L2.69134 16.5361L0.166992 3.78348Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M53 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "12"}
//       height={height || "15"}
//       viewBox="0 0 12 15"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M0.810059 1.34009L10.7101 0.610107L11.7401 13.8L1.99005 14.53L0.810059 1.34009Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const M54 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "23"}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0967 0.889978L0.0878906 9.10547L11.0502 22.4606L21.059 14.2452L10.0967 0.889978Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M55 = ({ width, height }) => {
  return (
    <svg
      width={width || "21"}
      height={height || "18"}
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.770508 4.45113L17.6432 0.958984L20.3637 13.6985L3.29486 17.2037L0.770508 4.45113Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M56 = ({ width, height }) => {
  return (
    <svg
      width={width || "21"}
      height={height || "19"}
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.01838 0.594727L0.0683594 12.9418L16.7317 18.0689L20.4725 5.72182L4.01838 0.594727Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M57 = ({ width, height }) => {
  return (
    <svg
      width={width || "23"}
      height={height || "23"}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5485 0.742188L22.1489 9.38793L10.5473 22.3104L0.959961 13.4686L12.5485 0.742188Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M58 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "14"}
//       height={height || "16"}
//       viewBox="0 0 14 16"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M10.1399 0.610107L13.1199 13.5L3.36989 15.74L0.389893 2.84009L10.1399 0.610107Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
// export const M59 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "15"}
//       height={height || "13"}
//       viewBox="0 0 15 13"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M1.49988 0.47998L14.5399 2.30005L13.1699 12.1899L0.129883 10.3701L1.49988 0.47998Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
// export const M60 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "15"}
//       height={height || "13"}
//       viewBox="0 0 15 13"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M1.97989 0.580078L15.0099 2.3999L13.6499 12.29L0.609863 10.47L1.97989 0.580078Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const M61 = ({ width, height }) => {
  return (
    <svg
      width={width || "18"}
      height={height || "21"}
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.753906 4.19479L13.3103 0.493164L17.9928 17.1696L5.6326 20.6749L0.753906 4.19479Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M62 = ({ width, height }) => {
  return (
    <svg
      width={width || "23"}
      height={height || "21"}
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.65918 10.1615L14.7851 0.181641L22.4236 10.7893L8.08837 20.7558L0.65918 10.1615Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M63 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "22"}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2631 0.31543L21.4069 13.6696L11.401 21.8836L0.244141 8.52944L10.2631 0.31543Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M64 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "16"}
//       height={height || "13"}
//       viewBox="0 0 16 13"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M2.35983 0.159912L15.3998 2.28003L13.8899 12.02L0.849854 9.8999L2.35983 0.159912Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
// export const M65 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "15"}
//       height={height || "13"}
//       viewBox="0 0 15 13"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M1.38995 0.939941L0.179932 10.6799L13.2199 12.3501L14.4299 2.45996L1.38995 0.939941Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const M66 = ({ width, height }) => {
  return (
    <svg
      width={width || "20"}
      height={height || "17"}
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.350586 3.91735L17.2101 0.412109L19.7476 12.9683L2.87494 16.4736L0.350586 3.91735Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M67 = ({ width, height }) => {
  return (
    <svg
      width={width || "23"}
      height={height || "20"}
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.87351 0.43457L22.1505 8.29541L16.4477 19.8579L0.974609 11.9839L6.87351 0.43457Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M68 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "18"}
//       height={height || "17"}
//       viewBox="0 0 18 17"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M17.1199 8.66992L6.16989 16.1499L0.629883 7.88989L11.4399 0.419922L17.1199 8.66992Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
// export const M69 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "11"}
//       height={height || "14"}
//       viewBox="0 0 11 14"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M0.480072 0.51001L10.3701 0.669922L10.2001 13.8701L0.310059 13.7L0.480072 0.51001Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const M70 = ({ width, height }) => {
  return (
    <svg
      width={width || "20"}
      height={height || "17"}
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.233398 3.63707L17.2891 0.131836L19.8266 12.8844L2.95393 16.3766L0.233398 3.63707Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M71 = ({ width, height }) => {
  return (
    <svg
      width={width || "20"}
      height={height || "18"}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.130859 4.28485L17.0036 0.779297L19.5279 13.5319L2.6683 17.0374L0.130859 4.28485Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M72 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "23"}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4479 0.667969L21.984 13.63L12.1743 22.2362L0.821289 9.27444L10.4479 0.667969Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M73 = ({ width, height }) => {
  return (
    <svg
      width={width || "23"}
      height={height || "22"}
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.3116 0.03125L22.2211 11.6196L13.5886 21.2201L0.666016 9.82784L9.3116 0.03125Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M77 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "15"}
//       height={height || "11"}
//       viewBox="0 0 15 11"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M1.09995 0.179932L14.2999 0.209961L14.2799 10.25L0.939941 10.0801L1.09995 0.179932Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const D01 = ({ width, height }) => {
  return (
    <svg
      width={width || "30"}
      height={height || "30"}
      viewBox="0 0 30 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.6727 27.4696L0.115234 23.4934L3.85598 0.0546875L29.4135 4.01784L25.6727 27.4696Z"
        fill="#4D5943"
      />
      <path
        d="M12.2394 22.0149C16.9781 22.0149 20.8196 18.1733 20.8196 13.4346C20.8196 8.6959 16.9781 4.85449 12.2394 4.85449C7.50067 4.85449 3.65918 8.6959 3.65918 13.4346C3.65918 18.1733 7.50067 22.0149 12.2394 22.0149Z"
        fill="white"
      />
    </svg>
  );
};
export const D02 = ({ width, height }) => {
  return (
    <svg
      width={width || "30"}
      height={height || "29"}
      viewBox="0 0 30 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.0692 28.0114L0.511719 24.0875L4.21322 0.635742L29.7707 4.55962L26.0692 28.0114Z"
        fill="#4D5943"
      />
      <path
        d="M11.9161 22.1126C16.6549 22.1126 20.4963 18.271 20.4963 13.5323C20.4963 8.79355 16.6549 4.95215 11.9161 4.95215C7.17742 4.95215 3.33594 8.79355 3.33594 13.5323C3.33594 18.271 7.17742 22.1126 11.9161 22.1126Z"
        fill="white"
      />
    </svg>
  );
};
export const D03 = ({ width, height }) => {
  return (
    <svg
      width={width || "30"}
      height={height || "29"}
      viewBox="0 0 30 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.619141 24.1903L4.24221 0.725586L29.8128 4.55781L26.1897 28.0227L0.619141 24.1903Z"
        fill="#4D5943"
      />
      <path
        d="M11.9728 22.3598C16.7115 22.3598 20.553 18.5182 20.553 13.7795C20.553 9.04078 16.7115 5.19922 11.9728 5.19922C7.2341 5.19922 3.39258 9.04078 3.39258 13.7795C3.39258 18.5182 7.2341 22.3598 11.9728 22.3598Z"
        fill="white"
      />
    </svg>
  );
};
export const D04 = ({ width, height }) => {
  return (
    <svg
      width={width || "30"}
      height={height || "28"}
      viewBox="0 0 30 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.3967 27.3608L0.760742 23.9731L3.96522 0.456055L29.6012 3.84378L26.3967 27.3608Z"
        fill="#4D5943"
      />
      <path
        d="M11.9445 22.1418C16.6832 22.1418 20.5247 18.3003 20.5247 13.5616C20.5247 8.82285 16.6832 4.98145 11.9445 4.98145C7.20574 4.98145 3.36426 8.82285 3.36426 13.5616C3.36426 18.3003 7.20574 22.1418 11.9445 22.1418Z"
        fill="white"
      />
    </svg>
  );
};
export const D05 = ({ width, height }) => {
  return (
    <svg
      width={width || "35"}
      height={height || "36"}
      viewBox="0 0 35 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.8798 15.4317L18.7004 35.6134L0.245117 20.6897L16.4115 0.507812L34.8798 15.4317Z"
        fill="#4D5943"
      />
      <path
        d="M15.4952 29.1653C20.234 29.1653 24.0755 25.3237 24.0755 20.585C24.0755 15.8463 20.234 12.0049 15.4952 12.0049C10.7565 12.0049 6.91504 15.8463 6.91504 20.585C6.91504 25.3237 10.7565 29.1653 15.4952 29.1653Z"
        fill="white"
      />
    </svg>
  );
};
export const D06 = ({ width, height }) => {
  return (
    <svg
      width={width || "26"}
      height={height || "28"}
      viewBox="0 0 26 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.2215 27.3771L0.495117 26.3439L1.52842 0.511719L25.2417 1.5449L24.2215 27.3771Z"
        fill="#4D5943"
      />
      <path
        d="M12.9738 26.0295C17.7125 26.0295 21.554 22.188 21.554 17.4493C21.554 12.7105 17.7125 8.86914 12.9738 8.86914C8.23504 8.86914 4.39355 12.7105 4.39355 17.4493C4.39355 22.188 8.23504 26.0295 12.9738 26.0295Z"
        fill="white"
      />
    </svg>
  );
};
export const D07 = ({ width, height }) => {
  return (
    <svg
      width={width || "34"}
      height={height || "35"}
      viewBox="0 0 34 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.7515 11.2516L21.7968 34.1801L0.791016 23.1278L12.7457 0.199219L33.7515 11.2516Z"
        fill="#4D5943"
      />
      <path
        d="M15.6632 29.0002C20.4019 29.0002 24.2434 25.1588 24.2434 20.4201C24.2434 15.6814 20.4019 11.8398 15.6632 11.8398C10.9245 11.8398 7.08301 15.6814 7.08301 20.4201C7.08301 25.1588 10.9245 29.0002 15.6632 29.0002Z"
        fill="white"
      />
    </svg>
  );
};
export const D08 = ({ width, height }) => {
  return (
    <svg
      width={width || "36"}
      height={height || "36"}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1223 35.884L0.492188 18.0041L19.8892 0.90918L35.5193 18.7759L16.1223 35.884Z"
        fill="#4D5943"
      />
      <path
        d="M15.1798 29.2522C19.9185 29.2522 23.76 25.4106 23.76 20.6719C23.76 15.9332 19.9185 12.0918 15.1798 12.0918C10.4411 12.0918 6.59961 15.9332 6.59961 20.6719C6.59961 25.4106 10.4411 29.2522 15.1798 29.2522Z"
        fill="white"
      />
    </svg>
  );
};
export const D09 = ({ width, height }) => {
  return (
    <svg
      width={width || "35"}
      height={height || "34"}
      viewBox="0 0 35 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.517578 13.2564L23.0406 0.542969L34.6291 21.2742L12.1061 33.9745L0.517578 13.2564Z"
        fill="#4D5943"
      />
      <path
        d="M14.4991 27.4866C19.2379 27.4866 23.0793 23.645 23.0793 18.9063C23.0793 14.1676 19.2379 10.3262 14.4991 10.3262C9.76043 10.3262 5.91895 14.1676 5.91895 18.9063C5.91895 23.645 9.76043 27.4866 14.4991 27.4866Z"
        fill="white"
      />
    </svg>
  );
};
export const D10 = ({ width, height }) => {
  return (
    <svg
      width={width || "36"}
      height={height || "37"}
      viewBox="0 0 36 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.1092 21.4333L19.309 0.963867L0.579102 15.5475L16.3792 36.017L35.1092 21.4333Z"
        fill="#4D5943"
      />
      <path
        d="M19.7804 29.3987C24.5191 29.3987 28.3606 25.5571 28.3606 20.8184C28.3606 16.0797 24.5191 12.2383 19.7804 12.2383C15.0417 12.2383 11.2002 16.0797 11.2002 20.8184C11.2002 25.5571 15.0417 29.3987 19.7804 29.3987Z"
        fill="white"
      />
    </svg>
  );
};
export const D11 = ({ width, height }) => {
  return (
    <svg
      width={width || "33"}
      height={height || "35"}
      viewBox="0 0 33 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8206 34.4589L33.0095 23.7467L21.4341 0.62207L0.245117 11.3342L11.8206 34.4589Z"
        fill="#4D5943"
      />
      <path
        d="M17.8761 28.8997C22.6148 28.8997 26.4563 25.0581 26.4563 20.3194C26.4563 15.5807 22.6148 11.7393 17.8761 11.7393C13.1374 11.7393 9.2959 15.5807 9.2959 20.3194C9.2959 25.0581 13.1374 28.8997 17.8761 28.8997Z"
        fill="white"
      />
    </svg>
  );
};
export const D12 = ({ width, height }) => {
  return (
    <svg
      width={width || "26"}
      height={height || "28"}
      viewBox="0 0 26 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.5902 26.6921L24.2822 0.873047L0.582031 2.18101L1.88999 28.0001L25.5902 26.6921Z"
        fill="#4D5943"
      />
      <path
        d="M13.0861 26.2874C17.8248 26.2874 21.6663 22.446 21.6663 17.7072C21.6663 12.9685 17.8248 9.12695 13.0861 9.12695C8.34734 9.12695 4.50586 12.9685 4.50586 17.7072C4.50586 22.446 8.34734 26.2874 13.0861 26.2874Z"
        fill="white"
      />
    </svg>
  );
};
export const D13 = ({ width, height }) => {
  return (
    <svg
      width={width || "33"}
      height={height || "33"}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.8249 22.5891L8.87616 32.3595L-0.00488281 10.3466L23.9438 0.576172L32.8249 22.5891Z"
        fill="#4D5943"
      />
      <path
        d="M13.7286 25.6625C18.4674 25.6625 22.3088 21.821 22.3088 17.0822C22.3088 12.3435 18.4674 8.50195 13.7286 8.50195C8.98992 8.50195 5.14844 12.3435 5.14844 17.0822C5.14844 21.821 8.98992 25.6625 13.7286 25.6625Z"
        fill="white"
      />
    </svg>
  );
};
export const D14 = ({ width, height }) => {
  return (
    <svg
      width={width || "27"}
      height={height || "26"}
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.6302 24.6877L0.771889 25.2893L0.327148 1.54985L26.1724 0.948242L26.6302 24.6877Z"
        fill="#4D5943"
      />
      <path
        d="M10.6075 21.7454C15.3463 21.7454 19.1877 17.9038 19.1877 13.1651C19.1877 8.42637 15.3463 4.58496 10.6075 4.58496C5.86883 4.58496 2.02734 8.42637 2.02734 13.1651C2.02734 17.9038 5.86883 21.7454 10.6075 21.7454Z"
        fill="white"
      />
    </svg>
  );
};
export const D15 = ({ width, height }) => {
  return (
    <svg
      width={width || "35"}
      height={height || "35"}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.443359 12.4811L20.6513 0.0292969L34.3064 21.99L14.0854 34.4417L0.443359 12.4811Z"
        fill="#4D5943"
      />
      <path
        d="M15.5236 22.892C20.2623 22.892 24.1038 19.0504 24.1038 14.3117C24.1038 9.57301 20.2623 5.73145 15.5236 5.73145C10.7848 5.73145 6.94336 9.57301 6.94336 14.3117C6.94336 19.0504 10.7848 22.892 15.5236 22.892Z"
        fill="white"
      />
    </svg>
  );
};
export const D16 = ({ width, height }) => {
  return (
    <svg
      width={width || "36"}
      height={height || "36"}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.305 35.2855L0.679688 17.3402L17.2253 0.310547L35.8376 18.2559L19.305 35.2855Z"
        fill="#4D5943"
      />
      <path
        d="M20.5343 28.6282C25.273 28.6282 29.1145 24.7868 29.1145 20.0481C29.1145 15.3093 25.273 11.4678 20.5343 11.4678C15.7956 11.4678 11.9541 15.3093 11.9541 20.0481C11.9541 24.7868 15.7956 28.6282 20.5343 28.6282Z"
        fill="white"
      />
    </svg>
  );
};
export const D17 = ({ width, height }) => {
  return (
    <svg
      width={width || "35"}
      height={height || "32"}
      viewBox="0 0 35 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.7216 31.9374L0.890625 21.9055L10.2033 0.0625L34.0343 10.1077L24.7216 31.9374Z"
        fill="#4D5943"
      />
      <path
        d="M20.7589 25.7786C25.4976 25.7786 29.3391 21.937 29.3391 17.1983C29.3391 12.4596 25.4976 8.61816 20.7589 8.61816C16.0202 8.61816 12.1787 12.4596 12.1787 17.1983C12.1787 21.937 16.0202 25.7786 20.7589 25.7786Z"
        fill="white"
      />
    </svg>
  );
};
export const D18 = ({ width, height }) => {
  return (
    <svg
      width={width || "28"}
      height={height || "26"}
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.9615 -0.00195312L27.688 23.6721L1.90815 25.6602L0.181641 1.98601L25.9615 -0.00195312Z"
        fill="#4D5943"
      />
      <path
        d="M16.9493 21.5012C21.6881 21.5012 25.5295 17.6598 25.5295 12.9211C25.5295 8.18239 21.6881 4.34082 16.9493 4.34082C12.2106 4.34082 8.36914 8.18239 8.36914 12.9211C8.36914 17.6598 12.2106 21.5012 16.9493 21.5012Z"
        fill="white"
      />
    </svg>
  );
};
export const D19 = ({ width, height }) => {
  return (
    <svg
      width={width || "29"}
      height={height || "27"}
      viewBox="0 0 29 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.7223 26.8313L0.0732422 23.5745L3.16 0.03125L28.8091 3.28805L25.7223 26.8313Z"
        fill="#4D5943"
      />
      <path
        d="M11.2697 21.3909C16.0084 21.3909 19.8499 17.5493 19.8499 12.8106C19.8499 8.07187 16.0084 4.23047 11.2697 4.23047C6.53094 4.23047 2.68945 8.07187 2.68945 12.8106C2.68945 17.5493 6.53094 21.3909 11.2697 21.3909Z"
        fill="white"
      />
    </svg>
  );
};
export const D20 = ({ width, height }) => {
  return (
    <svg
      width={width || "36"}
      height={height || "36"}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.8273 15.8692L22.0153 35.1747L0.917969 20.2117L14.73 0.90625L35.8273 15.8692Z"
        fill="#4D5943"
      />
      <path
        d="M15.7755 24.9594C20.5142 24.9594 24.3557 21.1178 24.3557 16.3791C24.3557 11.6404 20.5142 7.79883 15.7755 7.79883C11.0368 7.79883 7.19531 11.6404 7.19531 16.3791C7.19531 21.1178 11.0368 24.9594 15.7755 24.9594Z"
        fill="white"
      />
    </svg>
  );
};
export const D21 = ({ width, height }) => {
  return (
    <svg
      width={width || "36"}
      height={height || "36"}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1057 0.798828L35.0176 20.366L16.9939 35.8129L0.0820312 16.2459L18.1057 0.798828Z"
        fill="#4D5943"
      />
      <path
        d="M15.5958 24.7473C20.3345 24.7473 24.176 20.9058 24.176 16.167C24.176 11.4283 20.3345 7.58691 15.5958 7.58691C10.8571 7.58691 7.01562 11.4283 7.01562 16.167C7.01562 20.9058 10.8571 24.7473 15.5958 24.7473Z"
        fill="white"
      />
    </svg>
  );
};
export const D22 = ({ width, height }) => {
  return (
    <svg
      width={width || "36"}
      height={height || "36"}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0858 0.650391L35.6315 20.5184L17.3201 35.6381L0.774414 15.7572L19.0858 0.650391Z"
        fill="#4D5943"
      />
      <path
        d="M16.3009 24.4952C21.0396 24.4952 24.8811 20.654 24.8811 15.9152C24.8811 11.1765 21.0396 7.33496 16.3009 7.33496C11.5622 7.33496 7.7207 11.1765 7.7207 15.9152C7.7207 20.654 11.5622 24.4952 16.3009 24.4952Z"
        fill="white"
      />
    </svg>
  );
};
export const D23 = ({ width, height }) => {
  return (
    <svg
      width={width || "36"}
      height={height || "36"}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.84375 15.3789L19.1421 0.258789L35.6877 20.1397L17.3894 35.2467L0.84375 15.3789Z"
        fill="#4D5943"
      />
      <path
        d="M16.5529 23.8292C21.2916 23.8292 25.1331 19.9876 25.1331 15.2489C25.1331 10.5102 21.2916 6.66895 16.5529 6.66895C11.8141 6.66895 7.97266 10.5102 7.97266 15.2489C7.97266 19.9876 11.8141 23.8292 16.5529 23.8292Z"
        fill="white"
      />
    </svg>
  );
};
export const D24 = ({ width, height }) => {
  return (
    <svg
      width={width || "35"}
      height={height || "36"}
      viewBox="0 0 35 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.8263 0.924805L34.737 22.0613L15.2877 35.6642L0.376953 14.5405L19.8263 0.924805Z"
        fill="#4D5943"
      />
      <path
        d="M15.6671 25.1362C20.4058 25.1362 24.2473 21.2946 24.2473 16.5559C24.2473 11.8172 20.4058 7.97559 15.6671 7.97559C10.9284 7.97559 7.08691 11.8172 7.08691 16.5559C7.08691 21.2946 10.9284 25.1362 15.6671 25.1362Z"
        fill="white"
      />
    </svg>
  );
};
export const D25 = ({ width, height }) => {
  return (
    <svg
      width={width || "28"}
      height={height || "29"}
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.7371 3.19796L24.4149 28.8341L0.884766 25.682L4.20699 0.0458984L27.7371 3.19796Z"
        fill="#4D5943"
      />
      <path
        d="M14.5792 19.3774C19.3179 19.3774 23.1594 15.5358 23.1594 10.7971C23.1594 6.05836 19.3179 2.2168 14.5792 2.2168C9.84051 2.2168 5.99902 6.05836 5.99902 10.7971C5.99902 15.5358 9.84051 19.3774 14.5792 19.3774Z"
        fill="white"
      />
    </svg>
  );
};
export const D26 = ({ width, height }) => {
  return (
    <svg
      width={width || "27"}
      height={height || "29"}
      viewBox="0 0 27 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.6203 2.69036L24.0959 28.4309L0.487305 26.011L3.01165 0.270508L26.6203 2.69036Z"
        fill="#4D5943"
      />
      <path
        d="M14.1036 19.2621C18.8424 19.2621 22.6838 15.4206 22.6838 10.6818C22.6838 5.94313 18.8424 2.10156 14.1036 2.10156C9.36492 2.10156 5.52344 5.94313 5.52344 10.6818C5.52344 15.4206 9.36492 19.2621 14.1036 19.2621Z"
        fill="white"
      />
    </svg>
  );
};
export const D27 = ({ width, height }) => {
  return (
    <svg
      width={width || "27"}
      height={height || "29"}
      viewBox="0 0 27 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.0987 28.5082L0.463867 26.3761L2.67432 0.609375L26.3222 2.74151L24.0987 28.5082Z"
        fill="#4D5943"
      />
      <path
        d="M13.6886 19.5089C18.4273 19.5089 22.2688 15.6676 22.2688 10.9289C22.2688 6.1902 18.4273 2.34863 13.6886 2.34863C8.94988 2.34863 5.1084 6.1902 5.1084 10.9289C5.1084 15.6676 8.94988 19.5089 13.6886 19.5089Z"
        fill="white"
      />
    </svg>
  );
};
export const D28 = ({ width, height }) => {
  return (
    <svg
      width={width || "29"}
      height={height || "27"}
      viewBox="0 0 29 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.1026 26.6259L0.335938 24.4154L2.46784 0.767578L28.2346 2.99104L26.1026 26.6259Z"
        fill="#4D5943"
      />
      <path
        d="M17.4308 22.3879C22.1695 22.3879 26.011 18.5465 26.011 13.8078C26.011 9.0691 22.1695 5.22754 17.4308 5.22754C12.6921 5.22754 8.85059 9.0691 8.85059 13.8078C8.85059 18.5465 12.6921 22.3879 17.4308 22.3879Z"
        fill="white"
      />
    </svg>
  );
};
export const D29 = ({ width, height }) => {
  return (
    <svg
      width={width || "31"}
      height={height || "29"}
      viewBox="0 0 31 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.1887 4.61009L26.4087 28.0488L0.864258 24.0332L4.64428 0.594727L30.1887 4.61009Z"
        fill="#4D5943"
      />
      <path
        d="M12.7921 22.2532C17.5308 22.2532 21.3723 18.4118 21.3723 13.6731C21.3723 8.93434 17.5308 5.09277 12.7921 5.09277C8.0534 5.09277 4.21191 8.93434 4.21191 13.6731C4.21191 18.4118 8.0534 22.2532 12.7921 22.2532Z"
        fill="white"
      />
    </svg>
  );
};
export const D30 = ({ width, height }) => {
  return (
    <svg
      width={width || "30"}
      height={height || "28"}
      viewBox="0 0 30 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.43786 0.625L29.0607 4.07803L25.8039 27.5949L0.167969 24.1421L3.43786 0.625Z"
        fill="#4D5943"
      />
      <path
        d="M11.5353 22.3383C16.274 22.3383 20.1155 18.4967 20.1155 13.758C20.1155 9.0193 16.274 5.17773 11.5353 5.17773C6.79656 5.17773 2.95508 9.0193 2.95508 13.758C2.95508 18.4967 6.79656 22.3383 11.5353 22.3383Z"
        fill="white"
      />
    </svg>
  );
};
export const D31 = ({ width, height }) => {
  return (
    <svg
      width={width || "36"}
      height={height || "36"}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9463 0.749023L35.2233 21.6109L16.0094 35.5539L0.732422 14.6917L19.9463 0.749023Z"
        fill="#4D5943"
      />
      <path
        d="M16.1007 24.0697C20.8394 24.0697 24.6809 20.2282 24.6809 15.4895C24.6809 10.7507 20.8394 6.90918 16.1007 6.90918C11.362 6.90918 7.52051 10.7507 7.52051 15.4895C7.52051 20.2282 11.362 24.0697 16.1007 24.0697Z"
        fill="white"
      />
    </svg>
  );
};
export const D32 = ({ width, height }) => {
  return (
    <svg
      width={width || "35"}
      height={height || "36"}
      viewBox="0 0 35 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.389648 14.0177L19.7605 0.28418L34.802 21.3162L15.4443 35.0498L0.389648 14.0177Z"
        fill="#4D5943"
      />
      <path
        d="M15.9269 24.1143C20.6656 24.1143 24.5071 20.2731 24.5071 15.5344C24.5071 10.7957 20.6656 6.9541 15.9269 6.9541C11.1882 6.9541 7.34668 10.7957 7.34668 15.5344C7.34668 20.2731 11.1882 24.1143 15.9269 24.1143Z"
        fill="white"
      />
    </svg>
  );
};
export const D33 = ({ width, height }) => {
  return (
    <svg
      width={width || "25"}
      height={height || "27"}
      viewBox="0 0 25 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.1145 0.228516L24.7162 26.0738L0.989782 26.5314L0.375 0.673017L24.1145 0.228516Z"
        fill="#4D5943"
      />
      <path
        d="M12.4474 19.1811C17.1861 19.1811 21.0276 15.3395 21.0276 10.6008C21.0276 5.86207 17.1861 2.02051 12.4474 2.02051C7.70867 2.02051 3.86719 5.86207 3.86719 10.6008C3.86719 15.3395 7.70867 19.1811 12.4474 19.1811Z"
        fill="white"
      />
    </svg>
  );
};
export const D34 = ({ width, height }) => {
  return (
    <svg
      width={width || "29"}
      height={height || "27"}
      viewBox="0 0 29 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.54174 0.808594L28.3085 3.01897L26.1765 26.6669L0.422852 24.4566L2.54174 0.808594Z"
        fill="#4D5943"
      />
      <path
        d="M11.3038 22.1801C16.0426 22.1801 19.884 18.3385 19.884 13.5998C19.884 8.8611 16.0426 5.01953 11.3038 5.01953C6.56512 5.01953 2.72363 8.8611 2.72363 13.5998C2.72363 18.3385 6.56512 22.1801 11.3038 22.1801Z"
        fill="white"
      />
    </svg>
  );
};
export const D35 = ({ width, height }) => {
  return (
    <svg
      width={width || "31"}
      height={height || "30"}
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0996094 23.6205L5.39682 0.469727L30.6273 6.13329L25.3301 29.271L0.0996094 23.6205Z"
        fill="#4D5943"
      />
      <path
        d="M12.1847 23.1098C16.9234 23.1098 20.7649 19.2682 20.7649 14.5295C20.7649 9.79078 16.9234 5.94922 12.1847 5.94922C7.44598 5.94922 3.60449 9.79078 3.60449 14.5295C3.60449 19.2682 7.44598 23.1098 12.1847 23.1098Z"
        fill="white"
      />
    </svg>
  );
};
export const D36 = ({ width, height }) => {
  return (
    <svg
      width={width || "36"}
      height={height || "36"}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6684 0.705078L35.6065 20.2459L17.5959 35.7191L0.670898 16.1783L18.6684 0.705078Z"
        fill="#4D5943"
      />
      <path
        d="M15.6594 24.6017C20.3981 24.6017 24.2396 20.7601 24.2396 16.0214C24.2396 11.2827 20.3981 7.44141 15.6594 7.44141C10.9207 7.44141 7.0791 11.2827 7.0791 16.0214C7.0791 20.7601 10.9207 24.6017 15.6594 24.6017Z"
        fill="white"
      />
    </svg>
  );
};
export const D37 = ({ width, height }) => {
  return (
    <svg
      width={width || "35"}
      height={height || "35"}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.911 0.530273L34.2129 22.7132L13.7957 34.838L0.506836 12.6551L20.911 0.530273Z"
        fill="#4D5943"
      />
      <path
        d="M15.6398 23.9825C20.3785 23.9825 24.22 20.1413 24.22 15.4025C24.22 10.6638 20.3785 6.82227 15.6398 6.82227C10.9011 6.82227 7.05957 10.6638 7.05957 15.4025C7.05957 20.1413 10.9011 23.9825 15.6398 23.9825Z"
        fill="white"
      />
    </svg>
  );
};
export const D38 = ({ width, height }) => {
  return (
    <svg
      width={width || "34"}
      height={height || "35"}
      viewBox="0 0 34 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.102539 12.4637L20.5067 0.338867L33.8086 22.5218L13.3914 34.6466L0.102539 12.4637Z"
        fill="#4D5943"
      />
      <path
        d="M15.3009 23.5932C20.0396 23.5932 23.8811 19.7516 23.8811 15.0129C23.8811 10.2742 20.0396 6.43262 15.3009 6.43262C10.5622 6.43262 6.7207 10.2742 6.7207 15.0129C6.7207 19.7516 10.5622 23.5932 15.3009 23.5932Z"
        fill="white"
      />
    </svg>
  );
};
export const D39 = ({ width, height }) => {
  return (
    <svg
      width={width || "34"}
      height={height || "35"}
      viewBox="0 0 34 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5413 0.00878906L33.8432 22.1917L13.426 34.3165L0.124023 12.1336L20.5413 0.00878906Z"
        fill="#4D5943"
      />
      <path
        d="M15.715 23.2778C20.4537 23.2778 24.2952 19.4362 24.2952 14.6975C24.2952 9.95875 20.4537 6.11719 15.715 6.11719C10.9763 6.11719 7.13477 9.95875 7.13477 14.6975C7.13477 19.4362 10.9763 23.2778 15.715 23.2778Z"
        fill="white"
      />
    </svg>
  );
};
export const D40 = ({ width, height }) => {
  return (
    <svg
      width={width || "34"}
      height={height || "36"}
      viewBox="0 0 34 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.325195 12.8359L13.3394 35.1759L33.8089 23.1428L20.7947 0.802734L0.325195 12.8359Z"
        fill="#4D5943"
      />
      <path
        d="M15.7325 24.4369C20.4713 24.4369 24.3127 20.5954 24.3127 15.8566C24.3127 11.1179 20.4713 7.27637 15.7325 7.27637C10.9938 7.27637 7.15234 11.1179 7.15234 15.8566C7.15234 20.5954 10.9938 24.4369 15.7325 24.4369Z"
        fill="white"
      />
    </svg>
  );
};
export const D41 = ({ width, height }) => {
  return (
    <svg
      width={width || "35"}
      height={height || "36"}
      viewBox="0 0 35 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3354 0.766602L0.878906 12.7998L13.8931 35.1398L34.3626 23.1066L21.3354 0.766602Z"
        fill="#4D5943"
      />
      <path
        d="M16.4171 24.5841C21.1558 24.5841 24.9973 20.7428 24.9973 16.0041C24.9973 11.2654 21.1558 7.42383 16.4171 7.42383C11.6784 7.42383 7.83691 11.2654 7.83691 16.0041C7.83691 20.7428 11.6784 24.5841 16.4171 24.5841Z"
        fill="white"
      />
    </svg>
  );
};
export const D42 = ({ width, height }) => {
  return (
    <svg
      width={width || "35"}
      height={height || "35"}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.844727 12.4423L13.8589 34.7954L34.3285 22.7623L21.3143 0.40918L0.844727 12.4423Z"
        fill="#4D5943"
      />
      <path
        d="M16.0822 24.2016C20.8209 24.2016 24.6624 20.36 24.6624 15.6213C24.6624 10.8826 20.8209 7.04102 16.0822 7.04102C11.3434 7.04102 7.50195 10.8826 7.50195 15.6213C7.50195 20.36 11.3434 24.2016 16.0822 24.2016Z"
        fill="white"
      />
    </svg>
  );
};
export const D43 = ({ width, height }) => {
  return (
    <svg
      width={width || "35"}
      height={height || "35"}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.0301 0.327148L34.332 22.5101L13.9148 34.6349L0.625977 12.4519L21.0301 0.327148Z"
        fill="#4D5943"
      />
      <path
        d="M16.1252 23.5961C20.8639 23.5961 24.7054 19.7545 24.7054 15.0158C24.7054 10.2771 20.8639 6.43555 16.1252 6.43555C11.3864 6.43555 7.54492 10.2771 7.54492 15.0158C7.54492 19.7545 11.3864 23.5961 16.1252 23.5961Z"
        fill="white"
      />
    </svg>
  );
};
export const D44 = ({ width, height }) => {
  return (
    <svg
      width={width || "35"}
      height={height || "36"}
      viewBox="0 0 35 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.320312 12.8846L20.7376 0.759766L34.0264 22.9427L13.6223 35.0675L0.320312 12.8846Z"
        fill="#4D5943"
      />
      <path
        d="M15.8195 24.4477C20.5582 24.4477 24.3997 20.6061 24.3997 15.8674C24.3997 11.1287 20.5582 7.28711 15.8195 7.28711C11.0807 7.28711 7.23926 11.1287 7.23926 15.8674C7.23926 20.6061 11.0807 24.4477 15.8195 24.4477Z"
        fill="white"
      />
    </svg>
  );
};
export const D45 = ({ width, height }) => {
  return (
    <svg
      width={width || "35"}
      height={height || "36"}
      viewBox="0 0 35 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1278 0.953125L34.4297 23.136L14.0125 35.2608L0.723633 13.0779L21.1278 0.953125Z"
        fill="#4D5943"
      />
      <path
        d="M15.8185 24.1567C20.5572 24.1567 24.3987 20.3151 24.3987 15.5764C24.3987 10.8377 20.5572 6.99609 15.8185 6.99609C11.0798 6.99609 7.23828 10.8377 7.23828 15.5764C7.23828 20.3151 11.0798 24.1567 15.8185 24.1567Z"
        fill="white"
      />
    </svg>
  );
};

export const D46 = ({ width, height }) => {
  return (
    <svg
      width={width || "34"}
      height={height || "35"}
      viewBox="0 0 34 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5702 0.417969L33.8721 22.6012L13.4549 34.7129L0.166016 12.5428L20.5702 0.417969Z"
        fill="#4D5943"
      />
      <path
        d="M15.3654 23.557C20.1041 23.557 23.9456 19.7155 23.9456 14.9768C23.9456 10.238 20.1041 6.39648 15.3654 6.39648C10.6266 6.39648 6.78516 10.238 6.78516 14.9768C6.78516 19.7155 10.6266 23.557 15.3654 23.557Z"
        fill="white"
      />
    </svg>
  );
};
// export const D47 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "27"}
//       height={height || "27"}
//       viewBox="0 0 27 27"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M16.5402 0.73999L26.7102 17.7L11.1002 26.96L0.940186 10.01L16.5402 0.73999Z"
//         fill="#4D5943"
//       />
//       <path
//         d="M12.4401 18.4797C16.0631 18.4797 19.0001 15.5427 19.0001 11.9197C19.0001 8.29669 16.0631 5.35986 12.4401 5.35986C8.81714 5.35986 5.88013 8.29669 5.88013 11.9197C5.88013 15.5427 8.81714 18.4797 12.4401 18.4797Z"
//         fill="white"
//       />
//     </svg>
//   );
// };
export const D48 = ({ width, height }) => {
  return (
    <svg
      width={width || "35"}
      height={height || "36"}
      viewBox="0 0 35 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9671 0.730469L34.2559 22.9003L13.8518 35.0251L0.549805 12.8422L20.9671 0.730469Z"
        fill="#4D5943"
      />
      <path
        d="M15.5919 23.934C20.3306 23.934 24.1721 20.0924 24.1721 15.3537C24.1721 10.615 20.3306 6.77344 15.5919 6.77344C10.8532 6.77344 7.01172 10.615 7.01172 15.3537C7.01172 20.0924 10.8532 23.934 15.5919 23.934Z"
        fill="white"
      />
    </svg>
  );
};

export const D49 = ({ width, height }) => {
  return (
    <svg
      width={width || "35"}
      height={height || "36"}
      viewBox="0 0 35 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.860352 12.9764L21.2776 0.851562L34.5664 23.0345L14.1623 35.1462L0.860352 12.9764Z"
        fill="#4D5943"
      />
      <path
        d="M16.4386 24.2765C21.1773 24.2765 25.0188 20.4349 25.0188 15.6962C25.0188 10.9575 21.1773 7.11621 16.4386 7.11621C11.6999 7.11621 7.8584 10.9575 7.8584 15.6962C7.8584 20.4349 11.6999 24.2765 16.4386 24.2765Z"
        fill="white"
      />
    </svg>
  );
};

export const D50 = ({ width, height }) => {
  return (
    <svg
      width={width || "33"}
      height={height || "33"}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.9046 22.9929L8.95584 32.7633L0.0878906 10.7375L24.0235 0.966797L32.9046 22.9929Z"
        fill="#4D5943"
      />
      <path
        d="M13.8077 26.0668C18.5465 26.0668 22.3879 22.2252 22.3879 17.4865C22.3879 12.7478 18.5465 8.90625 13.8077 8.90625C9.06902 8.90625 5.22754 12.7478 5.22754 17.4865C5.22754 22.2252 9.06902 26.0668 13.8077 26.0668Z"
        fill="white"
      />
    </svg>
  );
};

export const D51 = ({ width, height }) => {
  return (
    <svg
      width={width || "27"}
      height={height || "29"}
      viewBox="0 0 27 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.8582 0.44043L26.8011 26.1287L3.19251 28.7185L0.262695 3.03016L23.8582 0.44043Z"
        fill="#4D5943"
      />
      <path
        d="M12.715 20.7268C17.4537 20.7268 21.2952 16.8854 21.2952 12.1467C21.2952 7.40797 17.4537 3.56641 12.715 3.56641C7.97625 3.56641 4.13477 7.40797 4.13477 12.1467C4.13477 16.8854 7.97625 20.7268 12.715 20.7268Z"
        fill="white"
      />
    </svg>
  );
};

export const D52 = ({ width, height }) => {
  return (
    <svg
      width={width || "33"}
      height={height || "31"}
      viewBox="0 0 33 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.1237 23.174L7.23321 30.1847L0.889648 7.30849L25.7801 0.297852L32.1237 23.174Z"
        fill="#4D5943"
      />
      <path
        d="M18.7824 22.6252C23.5211 22.6252 27.3626 18.7837 27.3626 14.045C27.3626 9.30625 23.5211 5.46484 18.7824 5.46484C14.0436 5.46484 10.2021 9.30625 10.2021 14.045C10.2021 18.7837 14.0436 22.6252 18.7824 22.6252Z"
        fill="white"
      />
    </svg>
  );
};

export const D53 = ({ width, height }) => {
  return (
    <svg
      width={width || "27"}
      height={height || "25"}
      viewBox="0 0 27 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.173828 0.443661L26.0321 0.299805L26.0583 24.0393L0.199933 24.1831L0.173828 0.443661Z"
        fill="#4D5943"
      />
      <path
        d="M10.5968 21.3713C15.3355 21.3713 19.177 17.5299 19.177 12.7912C19.177 8.0525 15.3355 4.21094 10.5968 4.21094C5.85809 4.21094 2.0166 8.0525 2.0166 12.7912C2.0166 17.5299 5.85809 21.3713 10.5968 21.3713Z"
        fill="white"
      />
    </svg>
  );
};
export const D54 = ({ width, height }) => {
  return (
    <svg
      width={width || "30"}
      height={height || "29"}
      viewBox="0 0 30 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.25262 0.90625L29.8625 4.52932L26.4356 28.0202L0.838867 24.3971L4.25262 0.90625Z"
        fill="#4D5943"
      />
      <path
        d="M12.7814 23.2201C17.5201 23.2201 21.3616 19.3786 21.3616 14.6399C21.3616 9.90114 17.5201 6.05957 12.7814 6.05957C8.04266 6.05957 4.20117 9.90114 4.20117 14.6399C4.20117 19.3786 8.04266 23.2201 12.7814 23.2201Z"
        fill="white"
      />
    </svg>
  );
};
export const D55 = ({ width, height }) => {
  return (
    <svg
      width={width || "28"}
      height={height || "30"}
      viewBox="0 0 28 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.2178 4.16888L23.8432 29.8047L0.326172 26.6133L3.68763 0.977539L27.2178 4.16888Z"
        fill="#4D5943"
      />
      <path
        d="M13.5626 21.4073C18.3013 21.4073 22.1428 17.5657 22.1428 12.827C22.1428 8.08832 18.3013 4.24707 13.5626 4.24707C8.82391 4.24707 4.98242 8.08832 4.98242 12.827C4.98242 17.5657 8.82391 21.4073 13.5626 21.4073Z"
        fill="white"
      />
    </svg>
  );
};
export const D56 = ({ width, height }) => {
  return (
    <svg
      width={width || "28"}
      height={height || "26"}
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66625 0.251953L27.4853 1.86072L25.9027 25.5477L0.0966797 23.9389L1.66625 0.251953Z"
        fill="#4D5943"
      />
      <path
        d="M11.2404 21.8588C15.9791 21.8588 19.8206 18.0172 19.8206 13.2785C19.8206 8.53981 15.9791 4.69824 11.2404 4.69824C6.50164 4.69824 2.66016 8.53981 2.66016 13.2785C2.66016 18.0172 6.50164 21.8588 11.2404 21.8588Z"
        fill="white"
      />
    </svg>
  );
};
export const D58 = ({ width, height }) => {
  return (
    <svg
      width={width || "30"}
      height={height || "28"}
      viewBox="0 0 30 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.567 27.0859L0.852539 24.287L3.53392 0.691406L29.2352 3.50371L26.567 27.0859Z"
        fill="#4D5943"
      />
      <path
        d="M17.6076 22.2071C22.3463 22.2071 26.1878 18.3659 26.1878 13.6272C26.1878 8.88844 22.3463 5.04688 17.6076 5.04688C12.8689 5.04688 9.02734 8.88844 9.02734 13.6272C9.02734 18.3659 12.8689 22.2071 17.6076 22.2071Z"
        fill="white"
      />
    </svg>
  );
};
