import React from "react";
import { STComeMapMain, PineMapMain } from "../components";
import { constant } from "../utils/constant";

const Map = () => {
  if (process.env.REACT_APP_MAP === constant.pinehill) {
    return <PineMapMain />;
  }
  return <STComeMapMain />;
};

export default Map;
