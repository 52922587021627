export const P3_1 = ({ fill }) => {
  return (
    <svg
      width="66"
      height="77"
      viewBox="0 0 66 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.1702 76.7893L0.760254 65.2794L15.1302 0.0693359L66.0002 10.7293L51.1702 76.7893Z"
        fill="#878A8E"
      />
    </svg>
  );
};

export const P3_2 = ({ fill }) => {
  return (
    <svg
      width="61"
      height="76"
      viewBox="0 0 61 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.76 75.2792L0 65.6191L14.43 0.369141L60.13 10.0692L45.76 75.2792Z"
        fill="#878A8E"
      />
    </svg>
  );
};

export const P3_3 = ({ fill }) => {
  return (
    <svg
      width="61"
      height="75"
      viewBox="0 0 61 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.0002 74.62L0.700195 65.07L15.6702 0L60.4302 9.37L46.0002 74.62Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_4 = ({ fill }) => {
  return (
    <svg
      width="61"
      height="77"
      viewBox="0 0 61 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.7 76.0696L60.67 10.9996L14.86 0.30957L0.75 66.3596L45.7 76.0696Z"
        fill="#878A8E"
      />
    </svg>
  );
};

export const P3_5 = ({ fill }) => {
  return (
    <svg
      width="60"
      height="77"
      viewBox="0 0 60 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.7499 76.36L59.8599 10.3101L15.3299 0.330078L0.859863 66.7501L45.7499 76.36Z"
        fill="#878A8E"
      />
    </svg>
  );
};

export const P3_6 = ({ fill }) => {
  return (
    <svg
      width="59"
      height="76"
      viewBox="0 0 59 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.8601 75.7496C-16.4499 63.3796 -0.399829 77.5097 11.7102 0.0196533L58.3302 9.33966L43.8601 75.7596V75.7496Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_7 = ({ fill }) => {
  return (
    <svg
      width="61"
      height="74"
      viewBox="0 0 61 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M60.0399 10.2399C41.6199 82.2699 62.5299 79.5899 0.169922 66.8699L14.58 0.669922L60.0399 10.2499V10.2399Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_8 = ({ fill }) => {
  return (
    <svg
      width="61"
      height="74"
      viewBox="0 0 61 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1802 6.14938C13.7902 3.52938 16.2702 1.82933 18.8202 1.15933C19.6302 0.939326 20.4802 0.879338 21.3202 0.859338C22.0402 0.849338 22.7502 0.91935 23.4502 1.05935L60.6202 8.67935L47.3202 73.3394L0.700195 64.0194L13.1702 6.15933L13.1802 6.14938Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_9 = ({ fill }) => {
  return (
    <svg
      width="60"
      height="76"
      viewBox="0 0 60 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.330078 65.33L13.6301 0.669922L59.3101 11.15L44.86 75.3099L0.330078 65.33Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_10 = ({ fill }) => {
  return (
    <svg
      width="61"
      height="75"
      viewBox="0 0 61 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.859863 64.3094L15.3099 0.149414L60.7699 9.72937L46.6699 74.9994L0.859863 64.3094Z"
        fill="#878A8E"
      />
    </svg>
  );
};

export const P3_11 = ({ fill }) => {
  return (
    <svg
      width="92"
      height="86"
      viewBox="0 0 92 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7702 0.729614C22.9302 2.29961 33.2902 4.38965 41.7202 6.01965C42.6602 6.19965 43.5702 6.46961 44.4702 6.78961C49.4602 8.52961 55.0802 9.27964 60.2602 8.25964L91.1402 85.0797L0.680176 66.0096L14.7802 0.739624L14.7702 0.729614Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_12 = ({ fill }) => {
  return (
    <svg
      width="99"
      height="105"
      viewBox="0 0 99 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.25 17.2492C8.79 14.8992 16.32 8.38918 19.66 0.15918L98.3 30.4892L82 104.729L31.13 94.0692L0.25 17.2492Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_13 = ({ fill }) => {
  return (
    <svg
      width="92"
      height="75"
      viewBox="0 0 92 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.660156 44.1595L16.5202 0.939575L91.8502 17.4896L79.3002 74.4896L0.660156 44.1595Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_14 = ({ fill }) => {
  return (
    <svg
      width="88"
      height="67"
      viewBox="0 0 88 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.52002 49.9396L11.95 0.229614L87.4 16.9597L75.85 66.4896L0.52002 49.9396Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_15 = ({ fill }) => {
  return (
    <svg
      width="89"
      height="63"
      viewBox="0 0 89 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.950195 46.2296L8.5802 8.83966L6.42017 0.719604L88.9102 1.72961L76.4002 62.9597L0.950195 46.2296Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_16 = ({ fill }) => {
  return (
    <svg
      width="101"
      height="71"
      viewBox="0 0 101 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4202 69.7198L0.430176 24.7198L89.5502 0.129883C89.7402 0.649883 89.9602 1.20986 90.2102 1.78986C91.9202 5.85986 92.3302 10.3199 91.5502 14.6599C90.7702 18.9999 90.2902 23.4599 90.3702 27.4499C90.4202 30.0599 90.4602 30.8199 91.6502 32.4099C95.0302 36.5499 99.0702 42.8599 100.54 48.0799L94.9302 70.7299L12.4302 69.7099L12.4202 69.7198Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_17 = ({ fill }) => {
  return (
    <svg
      width="102"
      height="67"
      viewBox="0 0 102 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.43 66.7196L0.27002 22.1796L78.36 0.519653C79.1 14.4197 82.47 16.5096 88.99 24.6996C90.98 27.1996 92.99 29.6896 95.17 32.0396C97.93 35.0196 100.46 38.6796 101.55 42.1396L12.43 66.7296V66.7196Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_18 = ({ fill }) => {
  return (
    <svg
      width="106"
      height="77"
      viewBox="0 0 106 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.140137 33.3996L95.3601 0.00964355C120.19 40.9796 90.2101 23.4396 96.3701 54.5096L18.2802 76.1696C17.0702 65.2796 5.54015 42.8996 0.150146 33.3996H0.140137Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_25 = ({ fill }) => {
  return (
    <svg
      width="65"
      height="101"
      viewBox="0 0 65 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.41 0.239258L64.74 9.72925L45.74 100.819L0 90.2493L19.41 0.239258Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_26 = ({ fill }) => {
  return (
    <svg
      width="66"
      height="102"
      viewBox="0 0 66 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M65.27 11.3496L45.29 101.26L0.73999 91.8196L19.74 0.729614L65.27 11.3496Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_27 = ({ fill }) => {
  return (
    <svg
      width="98"
      height="61"
      viewBox="0 0 98 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M97.29 17.1396C73.33 78.1297 95.25 61.9396 0.290039 44.2496L9.95007 0.00964355L97.29 17.1296V17.1396Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_28 = ({ fill }) => {
  return (
    <svg
      width="98"
      height="64"
      viewBox="0 0 98 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M97.9402 18.5297L88.2902 63.1396L0.950195 46.0197L11.2702 0.349609L97.9402 18.5297Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_29 = ({ fill }) => {
  return (
    <svg
      width="95"
      height="64"
      viewBox="0 0 95 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.919922 5.14966L88.3099 0.719604C90.4899 7.8496 93.5399 16.8096 94.8599 24.1396C92.7599 33.9697 88.5499 53.9597 85.9399 63.5197L10.6199 47.6996L0.919922 5.13965V5.14966Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_30 = ({ fill }) => {
  return (
    <svg
      width="99"
      height="66"
      viewBox="0 0 99 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.990234 23.1492L82.0002 0.15918L98.3102 60.7192L10.9202 65.1492L0.990234 23.1492Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_31 = ({ fill }) => {
  return (
    <svg
      width="91"
      height="78"
      viewBox="0 0 91 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.900146 36.7292L69.5501 0.699219C73.3801 7.85922 81.1702 22.3592 85.0302 29.5292C86.5402 34.4092 89.8201 48.9792 91.0001 54.1592L9.99011 77.1492L0.890137 36.7292H0.900146Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_32 = ({ fill }) => {
  return (
    <svg
      width="99"
      height="83"
      viewBox="0 0 99 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.790039 46.4592L29.8901 82.7292L98.54 46.6992L85.8101 19.2092C83.1801 17.8092 66.1601 1.14919 59.8201 0.479187C59.0601 0.399187 58.18 0.419188 57.3 0.449188C55.28 0.509188 53.3601 1.30918 51.8301 2.62918C42.7501 10.4292 0.800049 46.4692 0.800049 46.4692L0.790039 46.4592Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_35 = ({ fill }) => {
  return (
    <svg
      width="106"
      height="97"
      viewBox="0 0 106 97"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M105.9 57.1996L76.55 96.9095L0.969971 28.2495L40.88 0.0195312C44.08 3.80953 49.35 5.98954 53.96 7.43954C55.95 8.05954 58.92 8.51953 60.97 8.73953C64.19 12.4195 106.29 53.2196 105.9 57.2096V57.1996Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_36 = ({ fill }) => {
  return (
    <svg
      width="130"
      height="166"
      viewBox="0 0 130 166"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.47998 4.84958L27.8 0.189575L129.84 114.88C129.07 115.65 127.19 117.05 126.63 117.83C122.57 123.59 123.59 126.57 124.88 130.7C125.46 132.55 126.88 137 126.88 137L86.9699 165.23L48.7899 131.77L0.469971 4.82956L0.47998 4.84958Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_37 = ({ fill }) => {
  return (
    <svg
      width="136"
      height="123"
      viewBox="0 0 136 123"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M62.8798 0.979492L135.73 106.049L123.55 121.689C121.96 120.869 120.8 120.059 116.91 119.559C112.43 118.989 108.98 119.489 105.32 121.289C104.16 121.859 102.84 122.879 102.84 122.879L0.799805 8.18948L62.8798 0.979492Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_38 = ({ fill }) => {
  return (
    <svg
      width="102"
      height="94"
      viewBox="0 0 102 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100.69 32.5895C102.24 34.5995 102.06 37.1295 101.34 39.5195C100.7 41.6195 99.4299 43.4696 97.7599 44.8896L41.9199 92.4095C41.0599 93.0895 40.3299 93.4895 39.6299 93.5595C37.9699 93.7295 36.1299 93.7096 34.3799 93.4496C32.9599 93.2396 31.6 92.7395 30.34 92.0295C29.73 91.6795 29.1699 91.2495 28.6799 90.7395L0.169922 61.1195L77.52 0.0195312L100.68 32.5995L100.69 32.5895Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_39 = ({ fill }) => {
  return (
    <svg
      width="91"
      height="101"
      viewBox="0 0 91 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M90.5302 39.0101L13.1801 100.11C13.5301 95.4601 7.92012 83.5401 3.56012 81.6901L15.7401 66.0501L0.850098 44.6801L65.0801 0.0800781L90.5302 39.0201V39.0101Z"
        fill="#878A8E"
      />
    </svg>
  );
};

export const P3_40 = ({ fill }) => {
  return (
    <svg
      width="95"
      height="87"
      viewBox="0 0 95 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M94.0698 42.0792L64.6498 0.40918L0.839844 44.9492L29.8398 86.6792L94.0698 42.0792Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_41 = ({ fill }) => {
  return (
    <svg
      width="94"
      height="88"
      viewBox="0 0 94 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M93.6501 43.4096L65.0701 0.479614L0.880127 45.9796L29.8401 87.9496L93.6501 43.4096Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_42 = ({ fill }) => {
  return (
    <svg
      width="80"
      height="101"
      viewBox="0 0 80 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M79.0702 55.4796L14.8902 100.98L0.200195 0.219604C3.8702 1.8096 66.9202 15.0496 71.5302 18.4196C72.7702 19.3296 73.7002 20.7096 73.6302 22.2396C74.0102 22.0796 70.2702 40.3296 70.2002 40.9396C70.0102 41.7996 69.6802 43.1196 70.1602 43.9396C70.9702 45.0196 78.0902 54.1896 79.0702 55.4696V55.4796Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_43 = ({ fill }) => {
  return (
    <svg
      width="63"
      height="119"
      viewBox="0 0 63 119"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48.1898 10.2198L62.8798 110.98L0.799805 118.19L2.6098 0.549805H4.58984L48.1898 10.2198Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_44 = ({ fill }) => {
  return (
    <svg
      width="53"
      height="127"
      viewBox="0 0 53 127"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.97015 0.949219L52.6101 4.54922L50.8001 122.189L23.4802 126.849L0.160156 87.9392L4.97015 0.949219Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_45 = ({ fill }) => {
  return (
    <svg
      width="167"
      height="221"
      viewBox="0 0 167 221"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0600586 179.76L47.9101 220.59L63.7901 123.73L166.69 28.4996L147.28 19.0696L31.3101 0.349609L0.0600586 179.76Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_46 = ({ fill }) => {
  return (
    <svg
      width="155"
      height="211"
      viewBox="0 0 155 211"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.910156 192.59L48.3202 210.62L59.7002 202.78L62.6002 122.25L154.13 38.9L119.69 0.5L16.7901 95.73L0.910156 192.59Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_48 = ({ fill }) => {
  return (
    <svg
      width="84"
      height="140"
      viewBox="0 0 84 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.3701 139.319L53.9601 118.009L75.1301 85.9692L83.1301 42.9293L46.4301 0.529297V35.1493L32.5501 69.2493L0.0800781 98.4193V106.169L17.0201 125.929L35.3701 139.319Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_49 = ({ fill }) => {
  return (
    <svg
      width="133"
      height="248"
      viewBox="0 0 133 248"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.43 112.53L9.85999 83.5299L0.5 42.2599L63.1 0.879883L78.99 43.8199L91.66 112.1L132.08 134.63L60.37 247.66L36.7 234.94L61.13 197.97L69.13 154.93L32.43 112.53Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_50 = ({ fill }) => {
  return (
    <svg
      width="144"
      height="149"
      viewBox="0 0 144 149"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.200195 139.17L10.3702 113.66L82.0802 0.629883L143.62 37.4099L64.6702 78.5399L20.5402 148.19L0.200195 139.17Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_51 = ({ fill }) => {
  return (
    <svg
      width="124"
      height="125"
      viewBox="0 0 124 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.540039 111.189L44.67 41.5392L123.62 0.40918L45.87 124.209L0.540039 111.189Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_52 = ({ fill }) => {
  return (
    <svg
      width="92"
      height="113"
      viewBox="0 0 92 113"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.870117 57.2092L37.4601 0.369141L91.5401 49.8091L54.1001 112.889L45.3501 105.769L37.2701 101.169L29.2101 96.7592L26.3401 95.0292L19.6201 82.3691L14.8201 73.3491L11.5601 67.7891L7.53012 62.9891L0.870117 57.2092Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_53 = ({ fill }) => {
  return (
    <svg
      width="104"
      height="115"
      viewBox="0 0 104 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.100098 63.8896L13.1501 74.3996L23.0901 81.3096L30.0601 90.3596L33.1801 97.0696L34.7101 101.29L36.0501 103.66L89.1701 114.86L103.58 48.6595L37.5401 0.80957L0.100098 63.8896Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_54 = ({ fill }) => {
  return (
    <svg
      width="139"
      height="106"
      viewBox="0 0 139 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2102 0.449219L0.510254 75.5292L138.9 105.379L130.05 93.3992L124.71 86.2892L118.72 79.5892L90.2903 57.7992L68.3402 44.4092L53.4002 19.6092L45.2402 10.4692L16.2102 0.449219Z"
        fill={fill}
      />
    </svg>
  );
};

// export const P3_68 = ({ fill }) => {
//   return (
//     <svg
//       width="144"
//       height="154"
//       viewBox="0 0 144 154"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M47.52 33.69L105.54 0.369995L143.91 22.48L62.81 153.32L27.58 132.73L0.98999 106.76V97.36L35.55 60.68L47.52 33.69Z"
//         fill={fill}
//       />
//     </svg>
//   );
// };

// export const P3_69 = ({ fill }) => {
//   return (
//     <svg
//       width="144"
//       height="162"
//       viewBox="0 0 144 162"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M81.91 0.47998L143.73 38.68L67.95 161.35L0.809998 131.32L81.91 0.47998Z"
//         fill={fill}
//       />
//     </svg>
//   );
// };

export const BordersPhase3 = () => {
  return (
    <svg
      width="932"
      height="947"
      viewBox="0 0 932 947"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M711.14 400.4L806.36 367.01C831.19 407.98 801.21 390.44 807.37 421.51L729.28 443.169C728.07 432.279 716.54 409.9 711.15 400.4H711.14Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M741.43 487.719L729.27 443.18L807.36 421.52C808.1 435.42 811.47 437.51 817.99 445.7C819.98 448.2 821.99 450.689 824.17 453.039C826.93 456.019 829.46 459.68 830.55 463.14L741.43 487.729V487.719Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M753.42 532.72L741.43 487.72L830.55 463.13C830.74 463.65 830.96 464.21 831.21 464.79C832.92 468.86 833.33 473.32 832.55 477.66C831.77 482 831.29 486.46 831.37 490.45C831.42 493.06 831.46 493.82 832.65 495.41C836.03 499.55 840.07 505.86 841.54 511.08L835.93 533.73L753.43 532.71L753.42 532.72Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M747.95 578.229L755.58 540.84L753.42 532.719L835.91 533.729L823.4 594.96L747.95 578.229Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M736.52 627.94L747.95 578.229L823.4 594.96L811.85 644.49L736.52 627.94Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M720.66 671.159L736.52 627.939L811.85 644.489L799.3 701.489L720.66 671.159Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M701.25 688.249C709.79 685.899 717.32 679.389 720.66 671.159L799.3 701.489L783 775.729L732.13 765.069L701.25 688.249Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M655.77 680.729C663.93 682.299 674.29 684.39 682.72 686.02C683.66 686.2 684.57 686.469 685.47 686.789C690.46 688.529 696.08 689.28 701.26 688.26L732.14 765.08L641.68 746.01L655.78 680.74L655.77 680.729Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M551.33 725.33L564.63 660.67L610.31 671.15L595.86 735.31L551.33 725.33Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M517.18 658.149C517.79 655.529 520.27 653.829 522.82 653.159C523.63 652.939 524.48 652.879 525.32 652.859C526.04 652.849 526.75 652.919 527.45 653.059L564.62 660.679L551.32 725.339L504.7 716.019L517.17 658.159L517.18 658.149Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M536.86 791.75C476.55 779.38 492.6 793.51 504.71 716.02L551.33 725.34L536.86 791.76V791.75Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M595.86 735.309L610.31 671.149L655.77 680.729L641.67 745.999L595.86 735.309Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M581.75 801.36L595.86 735.31L551.33 725.33L536.86 791.75L581.75 801.36Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M626.7 811.07L641.67 746L595.86 735.31L581.75 801.36L626.7 811.07Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M672 820.62L626.7 811.07L641.67 746L686.43 755.37L672 820.62Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M717.76 830.279L672 820.619L686.43 755.369L732.13 765.069L717.76 830.279Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M768.17 841.789L717.76 830.279L732.13 765.069L783 775.729L768.17 841.789Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M487.04 712.24C468.62 784.27 489.53 781.59 427.17 768.87L441.58 702.67L487.04 712.25V712.24Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M549.41 543.239L594.74 552.729L575.74 643.819L530 633.249L549.41 543.239Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M640.27 563.349L620.29 653.26L575.74 643.82L594.74 552.729L640.27 563.349Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M717.29 626.14C693.33 687.13 715.25 670.94 620.29 653.25L629.95 609.01L717.29 626.13V626.14Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M726.94 581.53L717.29 626.14L629.95 609.02L640.27 563.35L726.94 581.53Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M641.92 523.15L729.31 518.719C731.49 525.849 734.54 534.81 735.86 542.14C733.76 551.97 729.55 571.96 726.94 581.52L651.62 565.7L641.92 523.14V523.15Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M631.99 481.149L713 458.159L729.31 518.719L641.92 523.149L631.99 481.149Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M622.9 440.729L691.55 404.699C695.38 411.859 703.17 426.359 707.03 433.529C708.54 438.409 711.82 452.979 713 458.159L631.99 481.149L622.89 440.729H622.9Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M593.79 404.459L622.89 440.729L691.54 404.699L678.81 377.209C676.18 375.809 659.16 359.149 652.82 358.479C652.06 358.399 651.18 358.419 650.3 358.449C648.28 358.509 646.36 359.309 644.83 360.629C635.75 368.429 593.8 404.469 593.8 404.469L593.79 404.459Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M562.9 428.2L533.55 467.91L457.97 399.25L497.88 371.02C501.08 374.81 506.35 376.99 510.96 378.44C512.95 379.06 515.92 379.52 517.97 379.74C521.19 383.42 563.29 424.22 562.9 428.21V428.2Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M371.48 238.85L398.8 234.19L500.84 348.88C500.07 349.65 498.19 351.05 497.63 351.83C493.57 357.59 494.59 360.57 495.88 364.7C496.46 366.55 497.88 371 497.88 371L457.97 399.23L419.79 365.77L371.47 238.83L371.48 238.85Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M460.88 226.979L533.73 332.049L521.55 347.689C519.96 346.869 518.8 346.059 514.91 345.559C510.43 344.989 506.98 345.489 503.32 347.289C502.16 347.859 500.84 348.879 500.84 348.879L398.8 234.189L460.88 226.979V226.979Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M352.97 112.949L400.61 116.549L398.8 234.189L371.48 238.849L348.16 199.939L352.97 112.949Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M446.19 126.22L460.88 226.98L398.8 234.19L400.61 116.55H402.59L446.19 126.22Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M525.07 181.48L460.89 226.98L446.2 126.22C449.87 127.81 512.92 141.05 517.53 144.42C518.77 145.33 519.7 146.71 519.63 148.24C520.01 148.08 516.27 166.33 516.2 166.94C516.01 167.8 515.68 169.12 516.16 169.94C516.97 171.02 524.09 180.19 525.07 181.47V181.48Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M553.65 224.409L525.07 181.479L460.88 226.979L489.84 268.949L553.65 224.409Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M583.07 266.079L553.65 224.409L489.84 268.949L518.84 310.679L583.07 266.079Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M608.53 305.01L531.18 366.11C531.53 361.46 525.92 349.54 521.56 347.69L533.74 332.05L518.85 310.68L583.08 266.08L608.53 305.02V305.01Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M631.69 337.59C633.24 339.6 633.06 342.13 632.34 344.52C631.7 346.62 630.43 348.47 628.76 349.89L572.92 397.41C572.06 398.09 571.33 398.49 570.63 398.56C568.97 398.73 567.13 398.71 565.38 398.45C563.96 398.24 562.6 397.74 561.34 397.03C560.73 396.68 560.17 396.25 559.68 395.74L531.17 366.12L608.52 305.02L631.68 337.6L631.69 337.59Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M338.1 717.89L351.15 728.4L361.09 735.31L368.06 744.36L371.18 751.07L372.71 755.29L374.05 757.66L427.17 768.86L441.58 702.66L375.54 654.81L338.1 717.89Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M284.87 662.209L321.46 605.369L375.54 654.809L338.1 717.889L329.35 710.769L321.27 706.169L313.21 701.759L310.34 700.029L303.62 687.369L298.82 678.349L295.56 672.789L291.53 667.989L284.87 662.209Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M239.54 649.189L283.67 579.539L362.62 538.409L284.87 662.209L239.54 649.189Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M219.2 640.17L229.37 614.66L301.08 501.63L362.62 538.41L283.67 579.54L239.54 649.19L219.2 640.17Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M201.43 479.53L178.86 450.53L169.5 409.26L232.1 367.88L247.99 410.82L260.66 479.1L301.08 501.63L229.37 614.66L205.7 601.94L230.13 564.97L238.13 521.93L201.43 479.53Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M190.37 618.319L208.96 597.009L230.13 564.969L238.13 521.929L201.43 479.529V514.149L187.55 548.249L155.08 577.419V585.169L172.02 604.929L190.37 618.319Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M84.9102 329.59L132.32 347.62L143.7 339.78L146.6 259.25L238.13 175.9L203.69 137.5L100.79 232.73L84.9102 329.59Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M37.0601 288.76L84.9101 329.59L100.79 232.73L203.69 137.5L184.28 128.07L68.3101 109.35L37.0601 288.76Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M251.21 669.449L235.51 744.529L373.9 774.379L365.05 762.399L359.71 755.289L353.72 748.589L325.29 726.799L303.34 713.409L288.4 688.609L280.24 679.469L251.21 669.449Z"
        stroke="white"
        stroke-width="0.72"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M457.47 775.67L386.4 759.46C384.82 759.1 383.46 758.09 382.66 756.69L377.94 748.43C377.78 748.15 377.6 747.88 377.39 747.63L369.78 738.33C369.51 738 369.2 737.7 368.86 737.43L336.52 712.22C336.29 712.04 336.05 711.88 335.8 711.74L322.73 704.39C318.37 701.94 314.82 698.25 312.55 693.79L304.31 677.62C304.01 677.03 303.61 676.5 303.13 676.05L290.5 664.09C289.92 663.54 289.23 663.12 288.48 662.85L236.92 644.3C236.76 644.24 236.59 644.17 236.44 644.1L235.75 643.78C235.28 643.56 234.85 643.28 234.46 642.95L234.13 642.67C233.94 642.51 233.76 642.34 233.59 642.15L233.07 641.58L232.59 640.73L232.33 639.88L232.15 638.94L232.18 638.1C232.18 637.94 232.2 637.79 232.21 637.63L232.35 636.45L232.42 635.53L232.46 635.28C233.22 633.66 233.8 631.95 234.2 630.17C234.78 628.11 235.08 625.94 235.07 623.7C234.99 611.57 225.71 601.61 213.74 600.08C212.48 599.88 211.19 599.78 209.87 599.78C196.1 599.78 184.93 610.95 184.93 624.72C184.93 629.99 186.57 634.87 189.35 638.9L190.78 640.77C193.76 644.31 197.7 647 202.2 648.46L231.07 662.8C231.29 662.91 231.52 663 231.75 663.08L278.12 679.17C278.96 679.46 279.73 679.95 280.35 680.58L287.21 687.57C287.6 687.97 287.94 688.43 288.2 688.93L297.35 706.31C300.17 711.66 304.47 716.08 309.74 719.05L324.13 727.15C324.37 727.29 324.6 727.44 324.82 727.61L354.52 750.48C354.85 750.73 355.14 751.02 355.41 751.33L361.31 758.31C361.43 758.45 361.54 758.6 361.65 758.75L370.96 772.19C371.76 773.35 372.96 774.18 374.34 774.51L447.83 792.56C449.58 792.99 451.44 792.58 452.85 791.44L459.82 785.81C463.58 782.77 462.18 776.74 457.47 775.67Z"
        fill="#D0D5DD"
      />
    </svg>
  );
};
