import { colors } from "../../../utils/theme";
import strings from "../../../utils/fr.json";
// import { constant } from "../../../utils/constant";

// export const areaRule = (checkStatus, dealStageValue, stageValue, phase) => {
export const areaRule = (
  checkStatus,
  reservationStatus,
  dealStageValue,
  stageValue,
  phase
) => {
  // Chnages rule 17 april 2024 based on Réservation via la carte interactive property and Terrains disponibles column
  let color = colors.grey;

  color =
    reservationStatus === "" &&
    dealStageValue === stageValue.landAvailable &&
    checkStatus === strings.Status.available
      ? phase === "phase4"
        ? colors.green3
        : colors.green
      : colors.pink;
  // reservationStatus !== "Nouvelle réservation" &&
  // dealStageValue === stageValue.landAvailable
  //   ? phase === "phase4"
  //     ? colors.green3
  //     : colors.green
  //   : colors.pink;
  return color;
  // Chnages rule 14 dec 2023 based on status not on column
  // let color = colors.grey;
  // color =
  //   checkStatus === strings.Status.notAvailable
  //     ? colors.grey
  //     : checkStatus === strings.Status.available
  //     ? phase === "phase4"
  //       ? colors.green3
  //       : colors.green
  //     : checkStatus === strings.Status.row1 ||
  //       checkStatus === strings.Status.row2
  //     ? colors.yellow
  //     : colors.pink;
  // return color;
  // let color = colors.grey;
  // color =
  //   checkStatus === strings.Status.available
  //     ? phase === "phase4"
  //       ? colors.green3
  //       : colors.green
  //     : checkStatus === strings.Status.notAvailable
  //     ? colors.grey
  //     : checkStatus === strings.Status.complete ||
  //       (dealStageValue !== stageValue.purchaseSend &&
  //         dealStageValue !== stageValue.purchaseSent &&
  //         dealStageValue !== stageValue.landAvailable &&
  //         dealStageValue !== stageValue.newLandAvailable &&
  //         dealStageValue !== stageValue.reserved)
  //     ? colors.pink
  //     : checkStatus === strings.Status.row1 ||
  //       checkStatus === strings.Status.row2 ||
  //       dealStageValue === stageValue.purchaseSend ||
  //       dealStageValue === stageValue.purchaseSent
  //     ? colors.yellow
  //     : dealStageValue !== stageValue.purchaseSend &&
  //       dealStageValue !== stageValue.purchaseSent &&
  //       dealStageValue !== stageValue.landAvailable &&
  //       dealStageValue !== stageValue.newLandAvailable
  //     ? phase === "phase4"
  //       ? colors.green3
  //       : colors.green
  //     : colors.pink;
  // return color;
  // let color = colors.grey;
  // color =
  //   checkStatus === strings.Status.available
  //     ? phase === "phase4"
  //       ?colors.green3
  //       : colors.green
  //     : checkStatus === strings.Status.notAvailable
  //     ? colors.grey
  //     : checkStatus === strings.Status.complete ||
  //       (dealStageValue !== stageValue.purchaseSend &&
  //         dealStageValue !== stageValue.purchaseSent &&
  //         dealStageValue !== stageValue.landAvailable &&
  //         dealStageValue !== stageValue.newLandAvailable)
  //     ? colors.pink
  //     : checkStatus === strings.Status.row1 ||
  //       checkStatus === strings.Status.row2 ||
  //       dealStageValue === stageValue.purchaseSend ||
  //       dealStageValue === stageValue.purchaseSent
  //     ? colors.yellow
  //     : phase === "phase4"
  //     ? colors.green3
  //     : colors.green;
  // return color;
  //=========================
  //  color = checkStatus
  //   ? checkStatus === strings.Status.complete
  //     ? colors.pink
  //     : colors.yellow
  //   : colors.green;
};

// export const areaRule = (
//   phase,
//   alphabetData,
//   landAvailable,
//   purchaseSend,
//   purchaseSent,
//   secondLandAvailable,
//   notForSale
// ) => {
//   if (
//     // phase === constant.phase1 ||
//     phase === constant.phase2
//     // ||
//     // phase === constant.phase3
//   ) {
//     let color = colors.pink;
//     if (alphabetData.length < 1) {
//       // Without title land
//       color = colors.pink;
//     } else if (alphabetData.length === 1) {
//       let dealStageValue = alphabetData[0].properties.dealstage.value;
//       if (
//         dealStageValue === landAvailable ||
//         dealStageValue === secondLandAvailable
//       ) {
//         color = colors.green;
//       } else if (
//         dealStageValue === purchaseSend ||
//         dealStageValue === purchaseSent
//       ) {
//         color = colors.yellow;
//       } else if (notForSale && dealStageValue === notForSale) {
//         color = colors.grey;
//       } else {
//         color = colors.pink;
//       }
//     } else if (alphabetData.length >= 4) {
//       color = colors.pink;
//     } else {
//       let landAvailable1 = landAvailable || secondLandAvailable;
//       let purchaseSend1 = purchaseSend;
//       let purchaseSent1 = purchaseSent;
//       let secondLandAvailable1 = secondLandAvailable;
//       var checkInAccept = alphabetData.some(
//         (d) =>
//           d.properties.dealstage.value !== landAvailable1 &&
//           d.properties.dealstage.value !== purchaseSend1 &&
//           d.properties.dealstage.value !== purchaseSent1 &&
//           d.properties.dealstage.value !== secondLandAvailable1
//       );
//       if (checkInAccept) {
//         color = colors.pink;
//       } else {
//         // deals either 2 or 3 in landAvailable | purchaseSend | purchaseSent
//         var checkLandAvailable = alphabetData.filter(
//           (d) =>
//             d.properties.dealstage.value === landAvailable1 ||
//             d.properties.dealstage.value === secondLandAvailable1
//         );
//         // if land available that means remains is in 2 column either 1 or 2 except available column
//         if (alphabetData.length >= 3) {
//           if (checkLandAvailable.length === 3) {
//             color = colors.green;
//           } else if (checkLandAvailable.length === 0) {
//             color = colors.pink;
//           } else {
//             color = colors.yellow;
//           }
//         } else {
//           if (checkLandAvailable.length === 2) {
//             color = colors.green;
//           } else {
//             color = colors.yellow;
//           }
//         }
//       }
//     }

//     return { color, checkInAccept };
//   } else {
//     let dealStageValue = alphabetData[0]?.properties?.dealstage?.value;

//     var checkStatus =
//       alphabetData[0]?.properties?.statut_des_promesses_d_achat?.value;
//     let color = colors.grey;
//     color =
//       phase === constant.phase4
//         ? notForSale && dealStageValue === notForSale
//           ? colors.grey
//           : checkStatus
//           ? checkStatus === strings.Status.complete ||
//             alphabetData[0]?.properties?.dealstage?.value === purchaseSent
//             ? colors.pink
//             : colors.yellow
//           : phase === constant.phase4
//           ? colors.green
//           : colors.pink
//         : colors.pink;
//     var count = checkStatus
//       ? checkStatus === strings.Status.complete
//         ? 3
//         : checkStatus === strings.Status.row2
//         ? 2
//         : 1
//       : 0;

//     return { color, count };
//   }
// };
