import React from "react";
import {
  Modal,
  Row,
  Col,
  Container,
  Image,
  Button,
  Form,
} from "react-bootstrap";
import {
  domaine_st_come,
  domian_pine_hill_logo,
} from "../../assets/images";
import { constant } from "../../utils/constant.js";
import "./style.scss";
import strings from "../../utils/fr.json";

class ForgetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullscreen: true,
    };
  }

  render() {
    const { show, toggleForgotPass, handleForgotPassword } = this.props;
    var checkMap = process.env.REACT_APP_MAP === constant.pinehill;
    return (
      <Modal
        className="forget-password"
        show={show}
        fullscreen={this.state.fullscreen}
        onHide={toggleForgotPass}
      >
        <Modal.Header>
          <Container>
            {/* <span className="email-warning">
              Sorry, wrong email or password.
            </span> */}
            <span className="success-msg">
              {strings.ForgotPassword.checkEmail}
            </span>
            <Row>
              <Col xl={4} md={5} sm={8} xs={10} className="mx-auto">
                <Image
                  src={checkMap ? domian_pine_hill_logo : domaine_st_come}
                  alt="logo"
                  fluid
                />
              </Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <h6 className="form-title">
              {strings.ForgotPassword.recoverPassword}
            </h6>
            <Row>
              <Col xl={4} md={5} sm={8} xs={10} className="mx-auto">
                <Form.Group className="form-group">
                  <Form.Control type="text" placeholder={strings.Forms.email} />
                </Form.Group>
                <p className="content">
                  {strings.ForgotPassword.emailInstruction}
                </p>
                <Button className="button-second" variant="green">
                  {strings.ForgotPassword.launchEmail}
                </Button>
                <Button
                  onClick={handleForgotPassword}
                  className="button-first"
                  variant="link"
                >
                  {strings.Forms.login}
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ForgetPassword;
