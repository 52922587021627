import React, { useEffect, useState } from "react";
import { Modal, Image, Button, Container } from "react-bootstrap";
import { BsStar, BsFillStarFill } from "react-icons/bs";
import { AiFillInfoCircle } from "react-icons/ai";
import CloseRounded from "@material-ui/icons/CloseRounded";
import { ref, set } from "firebase/database";
import { rdb, tables } from "../../services/firebase";
import { colors, phaseLogo } from "../../utils/theme";
import "./index.scss";
// import { constant } from "../../utils/constant.js";
import strings from "../../utils/fr.json";
import { Spinner, Timer } from "../index";
import { remainingTime } from "../timer";
import axios from "axios";
import {
  ADD_CONTACT_TO_LIST,
  CUSTOM_OBJECT_ASSOCIATE,
  CUSTOM_OBJECT_ASSOCIATE_DELETE,
  GET_CONTACT_BY_EMAIL,
  GET_CUSTOM_OBJECT_BY_DEAL,
  REMOVE_CONTACT_TO_LIST,
  headersKey,
} from "../../utils/config";

const pink = colors.pink;
// const yellow = colors.yellow;
const green = colors.green;
const grey = colors.grey;

const ViewLand = (props) => {
  const {
    activeArea,
    phaseMap,
    isFavourite,
    favouriteDocId,
    show,
    handleLand,
    handleTerrain,
    dateLimit,
    timeLimit,
  } = props;
  const [favouriteLand, setfavouriteLand] = useState(false);
  const [favouriteDocuId, setFavouriteDocuId] = useState("");
  const [isGuestLogin, setisGuestLogin] = useState("");
  const [loading, setLoading] = useState(false);
  const [timeRemain, setIsTimeRemain] = useState(false);

  // var checkMap = process.env.REACT_APP_MAP === constant.pinehill;

  useEffect(() => {
    setisGuestLogin(localStorage.getItem("@guest"));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsTimeRemain(remainingTime(dateLimit, timeLimit));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (show) {
      setfavouriteLand(isFavourite);
      setFavouriteDocuId(favouriteDocId);
    }
  }, [show, isFavourite, favouriteDocId]);

  const favouriteObjectToHubspot = async (dealId, fav) => {
    const email = JSON.parse(localStorage.getItem("@userData")).email;
    const customId = await axios.get(GET_CUSTOM_OBJECT_BY_DEAL(dealId), {
      headers: headersKey,
    });
    const contactId = await axios.get(GET_CONTACT_BY_EMAIL(email), {
      headers: headersKey,
    });
    const contactVid = contactId?.data?.vid;
    const customObjectId = customId?.data?.results[0]?.id;
    if (contactVid && customObjectId) {
      if (!fav) {
        await axios.post(
          ADD_CONTACT_TO_LIST,
          { vids: [contactVid] },
          { headers: headersKey }
        );
        await axios.put(
          CUSTOM_OBJECT_ASSOCIATE(customObjectId, contactVid),
          {},
          {
            headers: headersKey,
          }
        );
      } else {
        await axios.post(
          REMOVE_CONTACT_TO_LIST,
          { vids: [contactVid] },
          { headers: headersKey }
        );
        await axios.delete(
          CUSTOM_OBJECT_ASSOCIATE_DELETE(customObjectId, contactVid),
          { headers: headersKey }
        );
      }
    }
  };

  const handleFavourite = async () => {
    var userID = await localStorage.getItem("@userId");
    if (!favouriteLand) {
      favouriteObjectToHubspot(activeArea.deals[0]?.dealId, favouriteLand);
      setLoading(true);
      var obj = {
        uid: userID,
        dealID: activeArea.deals[0].dealId,
        dealName: activeArea.deals[0]?.properties.dealname?.value,
        phase: phaseMap.phase,
        map: phaseMap.map,
      };
      set(ref(rdb, tables.wishlist + userID + activeArea.deals[0].dealId), obj);
      setLoading(false);
      setFavouriteDocuId(userID + activeArea.deals[0].dealId);
      setfavouriteLand(true);
    } else {
      favouriteObjectToHubspot(activeArea.deals[0]?.dealId, favouriteLand);
      setLoading(true);
      await set(ref(rdb, `${tables.wishlist}${favouriteDocuId}`), null);
      setLoading(false);
      setfavouriteLand(false);
    }
  };

  const phasecheck = activeArea?.phase
    ? activeArea?.phase?.toLowerCase()
    : props?.location?.state?.phase?.toLowerCase();

  return (
    <>
      <Spinner loading={loading} />
      <Modal
        className="view-land"
        size="md"
        show={show}
        // onHide={handleLand}
        centered
      >
        <Modal.Header className="justify-content-space-between">
          <div></div>
          <Image
            src={phaseLogo(phaseMap.phase)}
            alt="logo"
            style={{ width: "10rem" }}
          />
          <Container className="text-end close-button">
            <Button variant="white" className="p-2 rounded-circle">
              <CloseRounded onClick={handleLand} />
            </Button>
          </Container>
        </Modal.Header>
        <Modal.Body>
          <div className="title">
            <h2 className="main-title">{`${
              phasecheck === "phase 4"
                ? activeArea?.title.substr(0, 1).toString() === "C"
                  ? "Chalet"
                  : activeArea?.title.substr(0, 1).toString() === "M"
                  ? "Mini Chalet"
                  : "Dome"
                : "Terrain"
            } ${activeArea?.title}`}</h2>
            {isGuestLogin !== "true" &&
              !(pink === activeArea?.color || grey === activeArea?.color) && (
                // activeArea?.count < 1 &&
                <Button
                  variant="transparent"
                  className="star-button"
                  onClick={handleFavourite}
                >
                  {favouriteLand ? (
                    <BsFillStarFill className="star" />
                  ) : (
                    <BsStar className="star" />
                  )}
                </Button>
              )}
          </div>
          {activeArea?.deals[0]?.properties?.nombre_de_pi2?.value ? (
            <h4 className="sub-title">
              {activeArea?.deals[0]?.properties?.nombre_de_pi2?.value} Pi²
            </h4>
          ) : null}

          {/* {activeArea?.color !== green && (
            <div className="reserved-text">
              <AiFillInfoCircle
                className="icon"
                style={{ color: activeArea?.color }}
              />
              <span style={{ textAlign: "center" }}>
                {pink === activeArea?.color || grey === activeArea?.color
                  ? strings.ViewLand.landSold
                  : activeArea?.count > 0
                  ? `${strings.ViewLand.cometo}${
                      activeArea?.count === 1
                        ? "un"
                        : activeArea?.count === 2
                        ? "deux"
                        : "trois"
                    } (${activeArea?.count}${
                      strings.ViewLand.promiseToPurchase
                    }`
                  : strings.ViewLand.landAvailable}
              </span>
            </div>
          )} */}
          {/* Timer hide start */}
          {/* {timeRemain && phaseMap.phase === constant.phase4 ? ( */}
          {/* {timeRemain && dateLimit && timeLimit ? (
            <div className="d-flex justify-content-center mt-4 mt-xxl-5">
              <Timer dateLimit={dateLimit} timeLimit={timeLimit} />
            </div>
          ) : null} */}
          {/* Timer hide end */}
        </Modal.Body>
        <Modal.Footer>
          <Button
            // variant={"light-orange"}
            size="lg"
            style={{
              // backgroundColor:
              //   pink === activeArea?.color ? grey : activeArea?.color,
              // backgroundColor: timeRemain
              //   ? grey
              //   : pink === activeArea?.color
              //   ? grey
              //   : activeArea?.color,
              backgroundColor:
                pink === activeArea?.color ? grey : activeArea?.color,
              borderWidth: 0,
            }}
            disabled={
              // pink === activeArea?.color || activeArea?.color === grey
              //   ? true
              //   : false
              // timeRemain
              //   ? true
              //   : pink === activeArea?.color || activeArea?.color === grey
              //   ? true
              //   : false
              pink === activeArea?.color || activeArea?.color === grey
                ? true
                : false
            }
            className="view-more-btn"
            onClick={handleTerrain}
          >
            {strings.ViewLand.seeDetails}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewLand;
