import React from "react";
import { Modal, Row, Col, Container, Image, Button } from "react-bootstrap";
import { domaine_pine_hill_large, stComeLogo } from "../../assets/images";
import { constant } from "../../utils/constant.js";
import "./index.scss";
import strings from "../../utils/fr.json";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = { fullscreen: true };
  }

  render() {
    const { show, handleAbout } = this.props;
    var checkMap = process.env.REACT_APP_MAP === constant.pinehill;
    return (
      <Modal
        className="about-details"
        show={show}
        fullscreen={this.state.fullscreen}
        onHide={handleAbout}
      >
        <Modal.Header>
          <Container fluid="xl">
            <Row>
              <Col lg={2} md={3} sm={4} xs={6} className="mx-auto text-center">
                <Image
                  src={checkMap ? domaine_pine_hill_large : stComeLogo}
                  alt="logo"
                  fluid
                />
              </Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body>
          <Container fluid="xl">
            <Row>
              <Col lg={8} className="mx-auto">
                <div className="about-center">
                  <p className="text">{strings.About.thanks}</p>
                  <h3 className="title">
                    {`${strings.About.welcome} ${
                      checkMap ? "Domaine Pine Hill!" : "Domaine St-Come"
                    }`}
                  </h3>
                  <div className="about-divider"></div>
                  <Row>
                    <Col xxl={8} xl={6} md={7} sm={8} className="mx-auto">
                      <Button
                        variant="green"
                        className="about-button w-100"
                        onClick={handleAbout}
                      >
                        {strings.About.backToMap}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }
}

export default About;
