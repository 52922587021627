import {
  pine_hill_forest,
  pine_hill_prestige,
  pine_hill_horizon,
  stComeLogo,
} from "../assets/images";
import { constant } from "./constant";

export const colors = {
  pink: "#E58888",
  yellow: "#E5C59C",
  green: "#8EA286",
  green3: "#4C5943",
  grey: "#D0D5DD",
  lightBlack: "#11151E",
  green1: "#8E9887",
  green2: "#8EA385",
  blue: "#00f9ff",
};

export const phaseLogo = (phase) => {
  var logo =
    process.env.REACT_APP_MAP === constant.pinehill
      ? phase === constant.phase1
        ? pine_hill_forest
        : phase === constant.phase2
        ? pine_hill_prestige
        : phase === constant.phase3
        ? pine_hill_horizon
        : pine_hill_horizon
      : stComeLogo;
  return logo;
};
