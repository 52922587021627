import React, { useEffect, useState } from "react";
import {
  Modal,
  Row,
  Col,
  Container,
  // Image,
  Button,
  Card,
} from "react-bootstrap";
import axios from "axios";
import {
  // BsArrowLeftShort,
  BsFillStarFill,
} from "react-icons/bs";
import {
  ref,
  onValue,
  set,
  orderByChild,
  equalTo,
  query,
} from "firebase/database";
import { dealsRef, rdb, tables } from "../../services/firebase";
// import { FiMenu } from "react-icons/fi";
import { colors } from "../../utils/theme";
// import {
//   domaine_st_come,
//   domian_pine_hill_logo,
//   phase1,
//   phase2,
//   phase3,
//   phase4,
//   land,
// } from "../../assets/images";
import "./style.scss";
import { loadReservationScript } from "../../utils/helper";
import {
  Terrains,
  // SubmitDetails,
  Header,
  Spinner,
  PinePhase1,
  PinePhase2,
  PinePhase3,
  PinePhase4,
  SubmitDetails,
  About,
} from "../index";
import { stComePhase1, stComePhase2 } from "../../assets/images";
import { constant } from "../../utils/constant";
import {
  areaSet,
  phase2AreaSet,
  phase3AreaSet,
  phase4AreaSet,
  phase4NordAreaSet,
} from "../../utils/data";
import { stcomeAreaSet1, stcomeAreaSet2 } from "../../utils/stComeData";
import strings from "../../utils/fr.json";
import { remainingTime } from "../timer";
import { areaRule } from "../maps/pineMap/areaRule";
import {
  CUSTOM_OBJECT_ASSOCIATE_DELETE,
  GET_CONTACT_BY_EMAIL,
  GET_CUSTOM_OBJECT_BY_DEAL,
  REMOVE_CONTACT_TO_LIST,
  headersKey,
} from "../../utils/config";
import { EventDialog } from "../maps/pineMap/eventDialog";

const Favourite = (props) => {
  const {
    show,
    // toggleFavouriteModal,
    closeFavouriteModal,
    location,
  } = props;
  const [isTerrains, setIsTerrains] = useState(false);
  const [selectedItem, setselectedItem] = useState(null);
  const [favouriteLands, setfavouriteLands] = useState([]);
  const [isSubmitDetails, setIsSubmitDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timeRemain, setIsTimeRemain] = useState(false);
  const [totalDealsData, setTotalDeals] = useState([]);
  const [aboutModal, setAboutModal] = useState(false);
  const [planVisitModal, setPlanVisitModal] = useState(false);

  useEffect(() => {
    async function fetchData() {
      var userID = await localStorage.getItem("@userId");
      const starCountRef = dealsRef;
      onValue(starCountRef, async (snapshot) => {
        const newDealsData = snapshot.val();
        var dealsData = await Object.values(newDealsData);
        var findMyWishlist = await query(
          ref(rdb, "/wishlist"),
          orderByChild("uid"),
          equalTo(userID)
        );
        onValue(findMyWishlist, async (snapshot) => {
          const newFavouriteList = snapshot.val();
          var favouriteData = newFavouriteList
            ? Object.values(newFavouriteList)
            : [];
          favouriteData = favouriteData.filter(
            (item) => item.map === process.env.REACT_APP_MAP
          );
          setTotalDeals(dealsData);
          setfavouriteLands(favouriteData);
          setLoading(false);
        });
      });
    }

    return fetchData();
  }, [show]);

  // var checkMap = process.env.REACT_APP_MAP === constant.pinehill;

  useEffect(() => {
    const interval = setInterval(() => {
      setIsTimeRemain(remainingTime());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const removeFavourite = async (item) => {
    // remove favourite
    setLoading(true);
    var favouriteDocuId = `${item.uid}${item.dealID}`;
    await set(ref(rdb, `${tables.wishlist}${favouriteDocuId}`), null);
    setLoading(false);
    favouriteObjectToHubspot(item.dealID);
  };

  const favouriteObjectToHubspot = async (dealId) => {
    const email = JSON.parse(localStorage.getItem("@userData")).email;
    const customId = await axios.get(GET_CUSTOM_OBJECT_BY_DEAL(dealId), {
      headers: headersKey,
    });
    const contactId = await axios.get(GET_CONTACT_BY_EMAIL(email), {
      headers: headersKey,
    });
    const contactVid = contactId?.data?.vid;
    const customObjectId = customId?.data?.results[0]?.id;
    if (contactVid && customObjectId) {
      await axios.post(
        REMOVE_CONTACT_TO_LIST,
        { vids: [contactVid] },
        { headers: headersKey }
      );
      await axios.delete(
        CUSTOM_OBJECT_ASSOCIATE_DELETE(customObjectId, contactVid),
        { headers: headersKey }
      );
    }
  };

  return (
    <>
      <Spinner loading={loading} />
      <Modal
        className="favourite-page"
        show={show}
        fullscreen={true}
        onHide={closeFavouriteModal}
        backdrop="static"
      >
        <Modal.Header>
          <Container>
            <Row>
              <Header
                // handleMenu={() => setIsMenuPage(!isMenuPage)}
                // isMainMap
                isBack
                handleBack={closeFavouriteModal}
                openFavourite
                style={{ position: "unset" }}
                // isMenuPage={isMenuPage}
                // handleLogout={handleLogout}
              />
            </Row>
            {/* <Row>
              <Col xl={5} md={4} sm={4} xs={4}>
                <div className="col-first">
                  <BsArrowLeftShort
                    onClick={toggleFavouriteModal}
                    className="back-btn"
                  />
                </div>
              </Col>
              <Col xl={2} md={4} sm={4} xs={4}>
                <div className="col-second">
                  <Image
                    src={checkMap ? domian_pine_hill_logo : domaine_st_come}
                    alt="logo"
                    fluid
                  />
                </div>
              </Col>
              <Col xl={5} md={4} sm={4} xs={4}>
                <div className="col-third">
                  <FiMenu className="toggle-btn" />
                </div>
              </Col>
            </Row> */}
          </Container>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <div className="top">
              <h5 className="mb-0">{strings.Header.favorite}</h5>
              <p className="mb-0">
                ({`${favouriteLands.length} ${strings.Header.favorite}`})
              </p>
            </div>
            <div className="favourite-list">
              <Row>
                {favouriteLands.map((item, idx) => {
                  var data = item;
                  let mapCheck = data.map === constant.pinehill;
                  var mapData = mapCheck
                    ? data.phase === constant.phase1
                      ? areaSet
                      : data.phase === constant.phase2
                      ? phase2AreaSet
                      : data.phase === constant.phase3
                      ? phase3AreaSet
                      : data.phase === constant.phase4
                      ? [...phase4AreaSet, ...phase4NordAreaSet]
                      : []
                    : data.phase === constant.phase1
                    ? stcomeAreaSet1
                    : stcomeAreaSet2;
                  //alphabetData
                  var dealsCommon = totalDealsData.filter(
                    (d) => data.dealName === d?.properties?.dealname?.value
                  );

                  const checkStatus =
                    dealsCommon[0]?.properties?.statut_des_promesses_d_achat
                      ?.value;

                  const reservationStatus =
                    dealsCommon[0]?.properties
                      ?.reservation_via_la_carte_interactive?.value;

                  let color = colors.grey;

                  const dealStageValue =
                    dealsCommon[0]?.properties.dealstage?.value;
                  const stageValue =
                    constant.dealsStage[data.map][
                      data.phase.replace(/ +/g, "")
                    ];

                  color = areaRule(
                    checkStatus,
                    reservationStatus,
                    dealStageValue,
                    stageValue
                  );

                  var count = checkStatus
                    ? checkStatus === strings.Status.complete
                      ? 3
                      : checkStatus === strings.Status.row2
                      ? 2
                      : 1
                    : 0;

                  // var dealNames = dealsCommon[0]?.properties?.dealname?.value;

                  const identification_du_lot_ph =
                    dealsCommon[0]?.properties?.identification_du_lot_ph?.value;

                  const titleToCompare = identification_du_lot_ph;
                  // mapCheck
                  //   ? data.phase === constant.phase1
                  //     ? dealNames?.substr(4, 1)
                  //     : data.phase === constant.phase2
                  //     ? dealNames?.substr(0, 6).trim()
                  //     : identification_du_lot_ph ||
                  //       dealNames?.substr(0, 5).trim()
                  //   : identification_du_lot_ph || dealNames?.substr(0, 5).trim();
                  const element = mapData.find((element) => {
                    return (
                      element.title ===
                      (titleToCompare?.length > 4 &&
                      data.phase === constant.phase4
                        ? titleToCompare.substr(4)
                        : titleToCompare)
                    );
                  });

                  var activeDataObj = {
                    ...element,
                    colors: color,
                    deals: dealsCommon,
                    count: count, //checkInAccept ? 0 : dealsCommon.length - 1,
                  };

                  var props1 = activeDataObj.deals[0]?.properties;

                  var PhaseImage = mapCheck
                    ? data.phase === constant.phase1
                      ? PinePhase1
                      : data.phase === constant.phase2
                      ? PinePhase2
                      : data.phase === constant.phase3
                      ? PinePhase3
                      : PinePhase4
                    : data.phase === constant.phase1
                    ? stComePhase1
                    : stComePhase2;

                  // var PhaseImage1 = phase1;
                  // var dealName = props1?.dealname?.value?.substr(0, 5).trim();

                  const title =
                    data.phase === "phase 4"
                      ? activeDataObj.title?.substr(0, 1).toString() === "C"
                        ? "Chalet"
                        : activeDataObj?.title?.substr(0, 1).toString() === "M"
                        ? "Mini Chalet"
                        : "Dome"
                      : "Terrain";
                  return (
                    <Col key={idx.toString()} xs={12}>
                      <Card className="favourite-single">
                        <Card.Header>
                          <div className="left">
                            <h5 className="item-name">
                              {`${title} ${element?.title || ""}`}
                              {/* <span>(réservé)</span> */}
                            </h5>
                          </div>
                          <div className="right d-flex align-items-center">
                            <p className="dimension d-sm-block d-none">
                              {`${Math.round(props1?.nombre_de_pi2?.value)}Pi²`}
                            </p>
                            <Button
                              variant="transparent"
                              className="star-button"
                              onClick={() => removeFavourite(item)}
                            >
                              <BsFillStarFill className="star" />
                            </Button>
                          </div>
                        </Card.Header>
                        <Card.Body>
                          <div className="card-center d-sm-none">
                            <Row>
                              <Col
                                xs={6}
                                className="d-flex flex-column align-items-start justify-content-between"
                              >
                                <p className="dimension">{`${Math.round(
                                  props1?.nombre_de_pi2?.value
                                )}Pi²`}</p>
                                {/* <Image
                                  className="phase-count mb-0"
                                  src={PhaseImage1}
                                  fluid
                                /> */}
                                {data.map === constant.pinehill ? (
                                  <PhaseImage
                                    width="90"
                                    height="90"
                                    className="phase-count mb-0"
                                  />
                                ) : (
                                  <img
                                    src={PhaseImage}
                                    fluid
                                    alt=""
                                    style={{ width: 90, height: 90 }}
                                  />
                                )}
                              </Col>
                              <Col xs={6} className="area-image">
                                {activeDataObj.area ? (
                                  <activeDataObj.area
                                    fill={activeDataObj.colors}
                                  />
                                ) : null}
                                {/* <Image src={land} fluid /> */}
                              </Col>
                            </Row>
                          </div>
                          <div className="card-bottom">
                            <Row>
                              <Col sm={6} className="d-sm-block d-none">
                                {/* <Image
                                  className="phase-count"
                                  src={phaseImage}
                                  fluid
                                /> */}
                                {data.map === constant.pinehill ? (
                                  <PhaseImage
                                    width="90"
                                    height="90"
                                    className="phase-count"
                                  />
                                ) : (
                                  <img
                                    src={PhaseImage}
                                    fluid
                                    alt=""
                                    style={{ width: 90, height: 90 }}
                                  />
                                )}
                              </Col>
                              <Col sm={6}>
                                <Button
                                  className="see-land-btn"
                                  variant={timeRemain ? "grey" : "green"}
                                  disabled={timeRemain ? true : false}
                                  onClick={() => {
                                    setselectedItem({
                                      ...data,
                                      area: {
                                        ...activeDataObj,
                                        color: activeDataObj.colors,
                                      },
                                    });
                                    setIsTerrains(!isTerrains);
                                  }}
                                >
                                  {strings.ViewLand.seeLand}
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Container>
        </Modal.Body>
      </Modal>
      {isTerrains ? (
        <Terrains
          show={isTerrains}
          handleTerrains={() => setIsTerrains(!isTerrains)}
          activeArea={
            selectedItem
              ? { ...selectedItem?.area, phase: selectedItem?.phase }
              : null
          }
          disableReserve={
            selectedItem?.area?.deals[0]?.properties
              ?.statut_des_promesses_d_achat?.value === strings.Status.complete
          }
          handleSubmitDetails={() => setIsSubmitDetails(!isSubmitDetails)}
          location={location}
          setPlanVisitModal={() => {
            setPlanVisitModal(true);
            loadReservationScript();
          }}
          dateLimit={location?.state?.dateLimit}
          timeLimit={location?.state?.timeLimit}
        />
      ) : null}
      <EventDialog
        planVisitModal={planVisitModal}
        setPlanVisitModal={(params) => setPlanVisitModal(params)}
      />
      {isSubmitDetails ? (
        <SubmitDetails
          activeArea={selectedItem.area}
          show={isSubmitDetails}
          phaseMap={{ phase: selectedItem.phase, map: selectedItem.map }}
          handleSubmitDetails={() => setIsSubmitDetails(!isSubmitDetails)}
          handleTerrains={() => setIsTerrains(!isTerrains)}
          handleAbout={() => setAboutModal(!aboutModal)}
        />
      ) : null}
      <About show={aboutModal} handleAbout={() => setAboutModal(!aboutModal)} />
    </>
  );
};

export default Favourite;
