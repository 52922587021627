import React, { useState, useEffect } from "react";
import { Image, Nav, Dropdown } from "react-bootstrap";
import { BsList, BsArrowLeftShort } from "react-icons/bs";
import { signOut } from "firebase/auth";
import { auth } from "../../services/firebase";
import {
  MdOutlineLogout,
  MdEmail,
  MdPhone,
  MdShare,
  MdInfo,
  MdVideoLibrary,
} from "react-icons/md";
import * as Icon from "react-bootstrap-icons";
import { GiBeveledStar } from "react-icons/gi";
import { RWebShare } from "react-web-share";
import "./style.scss";
import { useLocation, useNavigate } from "react-router-dom";
import strings from "../../utils/fr.json";
import {
  domian_pine_hill_logo,
  stComeLogo,
  headerCompass,
  promesses,
  favoris,
} from "../../assets/images";
// import { selectPipeline } from "../../utils/helper";
import {
  LandReserch,
  Promises,
  Favourite,
  Spinner,
  Timer,
} from "../../components";
import { constant } from "../../utils/constant";
import { remainingTime } from "../timer";
const Header = (props) => {
  const {
    handleMenu,
    isMainMap,
    isCompass,
    isBack,
    handleBack,
    openPromise,
    openFavourite,
    hide,
    dateLimit,
    timeLimit,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const menu = [
    // { title: strings.Header.explore, image: loaction_icon },
    { title: strings.Promises.promise, image: promesses },
    { title: strings.Header.favorite, image: favoris },
    { title: "" },
    { title: strings.Forms.email, icon: MdEmail },
    { title: strings.Menu.telephone, icon: MdPhone },
    { title: strings.Menu.shareMap, icon: MdShare },
    { title: strings.Menu.needHelp, icon: MdInfo },
    { title: strings.Menu.seeVideos, icon: MdVideoLibrary },
    { title: "" },
    { title: strings.Header.logout, icon: MdOutlineLogout },
  ];
  // const [loading, setLoading] = useState(false);
  var isGuestLogin = localStorage.getItem("@guest");
  var menuData = isGuestLogin === "true" ? menu.splice(4) : menu;
  const [isResearchModal, setIsResearchModal] = useState(false);
  const [isPromiseModal, setIsPromiseModal] = useState(false);
  const [isFavouriteModal, setIsFavouriteModal] = useState(false);
  const [timeRemain, setIsTimeRemain] = useState(false);
  var checkMap = process.env.REACT_APP_MAP === constant.pinehill;

  const toggleResearchModal = () => {
    setIsResearchModal(!isResearchModal);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIsTimeRemain(remainingTime(dateLimit, timeLimit));
    }, 1000);
    return () => clearInterval(interval) 
  }, [dateLimit, timeLimit]);

  const togglePromiseModal = async () => {
    setIsPromiseModal(!isPromiseModal);
  };

  const toggleFavouriteModal = async () => {
    setIsFavouriteModal(true);
  };

  const logout = () => {
    if (isGuestLogin !== "true") {
      signOut(auth);
    }
    localStorage.removeItem("@login");
    localStorage.removeItem("@guest");
    localStorage.removeItem("@userData");
    localStorage.removeItem("@userId");
    if (props.handleLogout) {
      props.handleLogout();
    } else {
      navigate("/", { replace: true, state: { isBack: true } });
    }
  };

  return (
    <>
      {/* <Spinner loading={loading} /> */}
      <Nav className={`header ${hide}`} style={props.style}>
        {isBack ? (
          <div
            className="back"
            onClick={() => {
              if (handleBack) {
                handleBack();
              } else {
                navigate("/", { replace: true, state: { isBack: true } });
              }
            }}
          >
            <BsArrowLeftShort />
          </div>
        ) : (
          <div />
        )}

        <div className="logo d-lg-block d-none">
          <Image src={checkMap ? domian_pine_hill_logo : stComeLogo} fluid />
        </div>
        <div className="header-right d-flex">
          {timeRemain ? (
            <Timer
              className="area-map-timer"
              dateLimit={dateLimit}
              timeLimit={timeLimit}
            />
          ) : null}

          <div className="header-menu">
            {isCompass ? (
              <Dropdown className="header-menu">
                <Dropdown.Toggle
                  className="header-menu-btn"
                  variant="white-black"
                  id="dropdown-basic"
                >
                  <GiBeveledStar />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="headerCompass">
                    <Image src={headerCompass} alt="logo" fluid />
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            ) : null}
          </div>
       
          {isMainMap ? (
            <div className="toggle" onClick={handleMenu}>
              <Icon.List />
            </div>
          ) : (
            <Dropdown className="header-menu">
              <Dropdown.Toggle
                className="header-menu-btn"
                variant="white-black"
                id="dropdown-basic"
              >
                <BsList />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {menuData.map((item, index) => {
                  return (
                    <div key={index.toString()}>
                      {item.title === strings.Menu.shareMap ? (
                        <RWebShare
                          key={index.toString()}
                          data={{
                            title: strings.Menu.shareDreamPlace,
                            text: strings.Menu.placeInvest,
                            url: checkMap
                              ? "https://carte.chaletpinehill.com/areas"
                              : "http://carte.domainestcome.com/areas",
                          }}
                        >
                          <Dropdown.Item key={index.toString()}>
                            <span className="menu-icon-cover">
                              <item.icon size={25} className="menu-icon" />
                            </span>
                            {isGuestLogin === "true" &&
                              menuData.length - 1 === index
                              ? strings.Header.login
                              : item.title}
                          </Dropdown.Item>
                        </RWebShare>
                      ) : item.title === "" ? (
                        <hr key={index.toString()} />
                      ) : (
                        <Dropdown.Item
                          key={index.toString()}
                          onClick={() => {
                            if (item.title === strings.Header.explore) {
                              toggleResearchModal();
                            } else if (item.title === strings.Header.logout) {
                              logout();
                            } else if (isGuestLogin !== "true") {
                              if (item.title === strings.Promises.promise) {
                                if (!openPromise) {
                                  togglePromiseModal();
                                }
                              } else if (
                                item.title === strings.Header.favorite
                              ) {
                                if (!openFavourite) {
                                  toggleFavouriteModal();
                                }
                              }
                            }
                          }}
                          href={
                            item.title === strings.Menu.telephone
                              ? "tel:514-548-2027"
                              : item.title === strings.Forms.email
                                ? "mailto:anthony@groupegibault.com"
                                : item.title === strings.Menu.needHelp
                                  ? "https://chaletpinehill.com/contact/" //"https://www.chaletpinehill.com/contactez-nous/"
                                  : null
                          }
                        >
                          <span className="menu-icon-cover">
                            {item.image ? (
                              <Image
                                src={item.image}
                                className="menu-icon"
                                fluid
                              />
                            ) : (
                              <item.icon size={25} className="menu-icon" />
                            )}
                          </span>
                          <span style={{ width: "80%" }}>
                            {isGuestLogin === "true" &&
                              menuData.length - 1 === index
                              ? strings.Header.login
                              : item.title}
                          </span>
                        </Dropdown.Item>
                      )}
                    </div>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
        {isResearchModal ? (
          <LandReserch
            show={isResearchModal}
            handleResearch={toggleResearchModal}
          />
        ) : null}
        {isPromiseModal ? (
          <Promises
            show={isPromiseModal}
            togglePromiseModal={togglePromiseModal}
            closePromiseModal={() => setIsPromiseModal(false)}
          />
        ) : null}
        {isFavouriteModal ? (
          <Favourite
            show={isFavouriteModal}
            toggleFavouriteModal={toggleFavouriteModal}
            location={location}
            closeFavouriteModal={() => setIsFavouriteModal(false)}
          />
        ) : null}
      </Nav>
    </>
  );
};
export default Header;
